import React,{Component} from 'react';
import './notification.css';
import './Form/form.css';
import {Helmet} from "react-helmet";

import ClientInfoForm from './Form/ClientInfoForm';
import PackageForm from './Form/PackageForm';
import PaymentForm from './Form/PaymentForm';
import SuccessForm from './Form/SuccessForm';


var fieldValues = {
  fname     : null,
  lname    : null,
  dof      : null,
  reze     : null,
  phone   : null,
  email  : null
}

export default class Notification extends Component{
  constructor(props){
    super();
    this.next = this.next.bind(this);
    this.back = this.back.bind(this);

    this.saveValues = this.saveValues.bind(this);

    this.state = {
      step : 1
    };

    this.data = fieldValues;

	}

  next() {
    this.setState({
           step: this.state.step+1
       });
   }

  back(){
     this.setState({
            step: this.state.step-1
        });
   }

  saveValues(fields){
    return function() {
      fieldValues = Object.assign({}, fieldValues, fields)
    }()
  }

  renderForm(){
      switch(this.state.step) {
        case 1:
          return <PackageForm
                      fieldValues={fieldValues}
                      saveValues={this.saveValues}
                      next = {this.next}
                      back={this.back}
                  />;
        case 2:
          return <PaymentForm
                      fieldValues={fieldValues}
                      saveValues={this.saveValues}
                      next = {this.next}
                      back={this.back}
                  />;
        case 3:
          return <SuccessForm />;
        default:
          return <ClientInfoForm
                      fieldValues={fieldValues}
                      saveValues={this.saveValues}
                      next = {this.next}
                  />;
    }
  }

  render(){
    return(
      <div className="page full">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <link rel="canonical" href={this.props.pageCanonicalLink} />
          <meta name="description" content={this.props.pageDescription} />
      </Helmet>
          <div className="title">
            <h1> Être notifié(e) à l'acquisition de la nationnalité française </h1>
          </div>
          <br/>
          {this.renderForm()}

      </div>
    )
  }
}
