import React, {Component} from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import './livret.css';
import {QCMReminder} from '../QCMReminder/qcm-reminder';
import LivretCards from './LivretCards/LivretCards';
import LivretChapitresMenu from './MenuChapitres/Chapitres';
import {Helmet} from "react-helmet";

export default class LivretDuCitoyenSymboles extends Component{
  constructor(props){
    super(props);

    this.state= {
      session : this.props.session
    }
  }
  render(){
    return(
      <div className="livret-main-container">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <link rel="canonical" href={this.props.pageCanonicalLink} />
          <meta name="description" content={this.props.pageDescription} />
      </Helmet>
        <Breadcrumb>
          <BreadcrumbItem><a href="/preparer-entretien">Préparer mon entretien</a></BreadcrumbItem>
          <BreadcrumbItem><a href="/preparer-entretien/culture-generale">Culture générale</a></BreadcrumbItem>
          <BreadcrumbItem active>Les valeurs de la république</BreadcrumbItem>
        </Breadcrumb>
        <div className="top-image livret">
          <div className="top-image-title">
            <h2> Préparer mon entretien : <span>Les valeurs de la république</span></h2>
          </div>
        </div>

        {
          this.props.session?
        <div className="livret-content">
          <LivretChapitresMenu/>

          <div className="livret-section section-1">
            <h2>Les valeurs de la république et son histoire </h2>
            <br/>
            <h3> La démocratie </h3>
            <p> La démocratie est un système de gouvernement où <span className="effect quoting-focus">le pouvoir est détenu par le peuple</span> sans qu’il n’y ait la moindre distinction.
            Les dirigeants de l’état sont élus au suffrage universel : cela signifie que tous les citoyens, hommes et femmes, ont le droit de vote. </p>
            <br/>
            <h4>
               La démocratie et la république sont le fruit d'un long combat
            </h4>
            </div>


            <div className="qcm-reminder">
              <QCMReminder/>
            </div>

          <div className="livret-section section-2">
            <h3> La révolution Française </h3>
            <p><span className="effect quoting-focus">Le 14 Juillet 1789</span> a marqué l'histoire de France par la prise de la prison royale de la Bastille.
            La Révolution Française découle d'une crise agricole qui a donné lieu à une grande crise économique et à une hausse des impôts mise en place par le roi Louis XVI.
            Symbole de liberté, le 14 juillet est devenue la <span className="effect quoting-focus">date de la fête nationale française</span>.
            La République est installée de façon stable en 1875. Dans les années 1880, des lois garantissent la liberté de la presse. L’école primaire devient obligatoire et l’école publique devient gratuite et laïque. </p>
            <br/>
            <h4> C'est à l'époque de la Révolution que <br/> <span className="effect quoting-focus"> les symboles de la France </span> sont mis en place </h4>
            <br/>
            <LivretCards/>
          </div>


          <div className="livret-section section-end">
              <span> </span>
              <span> </span>
              <span> Chapitre suivant : </span>
             <div className="chap-prev"> </div>
             <br/>
             <div className="chap-next">  <a href="/preparer-entretien/culture-generale-part2"> La devise <i className="fas fa-arrow-right"></i> </a></div>
          </div>
        </div>
        :
        <div className="offline-content livret">
          <h2> Veuillez vous connecter </h2>
          <p> Cette section n'est disponible que pour les membres du site </p>
          <a href="/login"> Me connecter </a> ou <a href="/register"> Créer un compte </a>
        </div>
      }
    </div>

  )}
}
