import React, {Component} from 'react';
import './constituerDossier.css';
import ReactGA from 'react-ga';
import CheckListConditions from './CheckListConditions/CheckListConditions';
import NatCases from './NatCases/NatCases';
import {Helmet} from "react-helmet";

const result = {
  1 :  {
    style : "cond-list",
    conditions :
      [
        "Si le conjoint est naturalisé, il doit être français lors de votre mariage et lors de votre demande de naturalisation",
        "4 ans de mariage avec un citoyen français avec un minimum de 3 ans sans interruption sur le territoire français. Dans toutes autres situations un délai de 5 ans de mariage est requis",
        "Déclaration et inscription du mariage sur les registres de l’état civil français si celui-ci est célébré à l’étranger",
        "Détenir une résidence régulière en France",
        "Avoir une vie affective et matérielle avec votre conjoint depuis votre mariage",
        "Disposer d’une connaissance orale suffisante de la langue française à travers l’obtention d’un diplôme ou d’une attestation (niveau B1 du Cadre européen commun de référence pour les langues du Conseil de l’Europe)",
        "Absence de condamnation en France d’une peine d’emprisonnement supérieure ou égale à 6 mois",
        "Absence de condamnation pour un acte qualifié de crime ou délit constituant une atteinte aux intérêts fondamentaux de la nation",
        "Ne pas avoir fait l’objet d’un arrêté d’expulsion non expressément rapporté ou abrogé ou d’une interdiction du territoire français non entièrement exécutée."
      ],
    nb : [
      "Toutes ces conditions doivent être garanties lors du dépôt de votre dossier",
      "Le niveau de langue française exigé va être renforcé prochainement"
    ]
  },
  2 : {
    style : "cond-list",
    conditions :
      [
        "Être âgé(e) d’au moins 65 ans",
        "Séjourner de manière permanente en France depuis au moins 25 ans",
        "Posséder une résidence régulière en France ",
        "Avoir un descendant direct ayant nationalité française",
        "Absence de condamnation en France d’une peine d’emprisonnement supérieure ou égale à 6 mois",
        "Absence de condamnation pour un acte qualifié de crime ou délit constituant une atteinte aux intérêts fondamentaux de la nation",
        "Ne pas avoir fait l’objet d’un arrêté d’expulsion non expressément rapporté ou abrogé ou d’une interdiction du territoire français non entièrement exécutée"
      ],
    nb : [
      "Toutes ces conditions doivent être garanties lors du dépôt de votre dossier"
        ]
  },
  3 : {
    style : "cond-list",
    conditions :
      [
        "Être âgé(e) d’au moins 18 ans",
        "Avoir un frère ou une sœur, né(e) en France, de nationalité française au titre des articles 21-7 ou 21-11 du code civil",
        "Être résident sur le territoire français et cela depuis l’âge de 6 ans",
        "Avoir suivi une scolarité obligatoire en France entre 6 et 16 ans dans des établissements contrôlés par l’État",
        "Posséder une résidence régulière en France",
        "Absence de condamnation en France d’une peine d’emprisonnement supérieure ou égale à 6 mois",
        "Absence de condamnation pour un acte qualifié de crime ou délit constituant une atteinte aux intérêts fondamentaux de la nation",
        "Ne pas avoir fait l’objet d’un arrêté d’expulsion non expressément rapporté ou abrogé ou d’une interdiction du territoire français non entièrement exécutée"
      ],
    nb : [
      "Toutes ces conditions doivent être garanties lors du dépôt de votre dossier"
        ]
  },

  4 : {
    style : "cond-list",
    conditions :
      [
        "Avoir plus de 18 ans, sous réserve des dispositions de l’article 21-22 du code civil",
        "Posséder un titre de séjour en cours de validité, à l’exception des ressortissants suisses ou de l’union européenne",
        "Résider de manière permanente en France depuis au moins 2 ans.",
        "Connaître et adhérer aux principes et aux valeurs de la République par la bonne connaissance de : la langue française, de l’histoire, de la culture, de la société françaises et des droits et devoirs du citoyen français",
        "Etre de bonnes vie et mœurs et avoir un comportement loyal au regard des institutions françaises",
        "Absence de condamnation en France d’une peine d’emprisonnement supérieure ou égale à 6 mois",
        "Absence de condamnation pour un acte qualifié de crime ou délit constituant une atteinte aux intérêts fondamentaux de la nation",
        "Ne pas avoir fait l’objet d’un arrêté d’expulsion non expressément rapporté ou abrogé ou d’une interdiction du territoire français non entièrement exécutée"
      ],
    nb : [
      "Toutes ces conditions doivent être garanties lors du dépôt de votre dossier",
      "Le niveau de langue française exigé va être renforcé prochainement"
        ]
  },
  5 : {
    style : "cond-list",
    conditions :
      [
        "Avoir plus de 18 ans, sous réserve des dispositions de l’article 21-22 du code civil",
        "Posséder un titre de séjour en cours de validité, à l’exception des ressortissants suisses ou de l’union européenne",
        "Connaître et adhérer aux principes et aux valeurs de la République par la bonne connaissance de : la langue française, de l’histoire, de la culture, de la société françaises et des droits et devoirs du citoyen français",
        "Etre de bonnes vie et mœurs et avoir un comportement loyal au regard des institutions françaises",
        "Absence de condamnation en France d’une peine d’emprisonnement supérieure ou égale à 6 mois",
        "Absence de condamnation pour un acte qualifié de crime ou délit constituant une atteinte aux intérêts fondamentaux de la nation",
        "Ne pas avoir fait l’objet d’un arrêté d’expulsion non expressément rapporté ou abrogé ou d’une interdiction du territoire français non entièrement exécutée"
      ],
    nb : [
      "Toutes ces conditions doivent être garanties lors du dépôt de votre dossier",
      "Le niveau de langue française exigé va être renforcé prochainement"
        ]
  },
  6 : {
    style : "cond-list",
    conditions :
      [
        "Avoir plus de 18 ans, sous réserve des dispositions de l’article 21-22 du code civil",
        "Posséder un titre de séjour en cours de validité, à l’exception des ressortissants suisses ou de l’union européenne",
        "Résider de manière permanente en France depuis au moins 5 ans.",
        "Connaître et adhérer aux principes et aux valeurs de la République par la bonne connaissance de : la langue française, de l’histoire, de la culture, de la société françaises et des droits et devoirs du citoyen français",
        "Etre de bonnes vie et mœurs et avoir un comportement loyal au regard des institutions françaises",
        "Absence de condamnation en France d’une peine d’emprisonnement supérieure ou égale à 6 mois",
        "Absence de condamnation pour un acte qualifié de crime ou délit constituant une atteinte aux intérêts fondamentaux de la nation",
        "Ne pas avoir fait l’objet d’un arrêté d’expulsion non expressément rapporté ou abrogé ou d’une interdiction du territoire français non entièrement exécutée"
      ],
    nb : [
      "Toutes ces conditions doivent être garanties lors du dépôt de votre dossier",
      "Le niveau de langue française exigé va être renforcé prochainement"
        ]
  },

  7 : {
    style : "cond-list",
    conditions :
      [
        "L’enfant doit être né en France",
        "L’enfant doit résider en France depuis au moins ses 8 ans",
        "L’enfant réside toujours en France lors du dépôt de votre demande"
      ],
    nb : [
      ]
  },
  8 : {
    style : "cond-list",
    conditions :
      [
        "L’enfant doit être né en France",
        "L’enfant a résidé au moins 5 ans en France de manière permanente depuis ses 11 ans",
        "L’enfant réside toujours en France lors du dépôt de votre demande"
      ],
    nb : [
      ]
  },
  9 : {
    style : "cond-list",
    conditions :
      [
        "L’enfant doit être né en France",
        "L’enfant a résidé au moins 5 ans en France de manière permanente depuis ses 11 ans",
        "L’enfant réside toujours en France à ses 18 ans"
      ],
    nb : [
      ]
  },
  10 : {
    style : "cond-list",
    conditions :
      [
        "L’enfant doit être mineur",
        "L’enfant doit résider en France lors du dépôt de votre demande, sauf si la résidence habituelle du parent français n’est pas en France",
        "Le parent doit être français lors de l’adoption",
        "Si l’adoption est réalisée à l’étranger, l’acte d’adoption doit avoir fait l'objet d'une décision d'exequatur en France"
      ],
    nb : [
      "Une décision d'exequatur est une procédure permettant l’exécution en France d’une décision de justice étrangère"
      ]
  },
  11 : {
    style : "cond-list",
    conditions :
      [
        "L’enfant doit être mineur",
        "L’enfant doit résider en France lors du dépôt de votre demande",
        "L’enfant a été accueilli au moins 3 ans par un citoyen français OU a été pris en charge au moins 3 ans par l'aide sociale à l'enfance OU a disposé d’une formation de la langue française de 5 ans minimum"
      ],
    nb : [
      ]
  },

}


export default class ConditionsNaturalisation extends Component{
  constructor(props){
    super(props);
    this.state= {
      session : this.props.session,
      result_case : 0,
    }
    this.showResult = this.showResult.bind(this);
  }

  showResult(res){
    return(
      <div>
        <CheckListConditions
                conditions={result[res].conditions}
                subtitle= "Conditions à remplir"
                nb= {result[res].nb}
                subtitle2= "À noter"
                listtype = {result[res].style}
                />
      </div>
    )
  }

  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/constituer-dossier/conditions-naturalisation');
  }


  render(){
    return(
      <div className="constituer-main-container conditions">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <link rel="canonical" href={this.props.pageCanonicalLink} />
          <meta name="description" content={this.props.pageDescription} />
      </Helmet>
        <div className="top-image conditions">
            <div className="top-image-title">
              <h1> Conditions à remplir pour être naturalisé(e) </h1>
            </div>
        </div>

      <div className="constituer-content conditions">

        <div className="constituer-content-header conditions">
          <p>Maintenant que vous savez quelle est la procédure adaptée à votre situation, vous devez savoir si vous répondez aux conditions administratives. Avant de constituer le moindre dossier pour votre procédure de naturalisation, il est important de savoir si vous êtes éligible.  </p>

          <p> Si vous désirez que votre dossier soit recevable, il faut de ce fait remplir une liste de conditions définie en fonction de votre situation. </p>
          <br/>
          <p> <em> Ne négligez pas cette étape ! </em></p>
        </div>



        <div className="constituer-section conditions section-1">
          <div className="constituer-section conditions section-1-container">
            <p> Suis-je éligible ? </p>

            <NatCases showResult={this.showResult}/>

          </div>
        </div>
      </div>
    </div>

    )}
}

/*
{this.state.natcase > 0?

<div>
  <div>
    <div className="option_select">
      <CreatableSelect
          defaultValue={this.getNatCaseContent()}
          isMulti
          className="basic-multi-select"
          onChange={this.handleChangeNatCase}
          classNamePrefix="select"
        />
      </div>
  </div>
</div>
:

DIRECT CONNECT TO THIS PAGE

}

*/
