import React, {Component} from 'react';
import {Button, Form, FormGroup, Input, FormText } from 'reactstrap';
import ReactGA from 'react-ga';

var re = new RegExp("20[0-9]{2}X( [0-9]{6}|[0-9]{6})");

export default class ClientInfoForm extends Component{
  constructor(props){
    super(props);
    this.controlFields = this.controlFields.bind(this);

    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.state = {
            fname: null,
            lname: null,
            dof: null,
            reze: null,
            fnameErr : false,
            lnameErr : false,
            dofErr : false
        };
  }

  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/notification/subscribe/client-info');
  }

  controlFields(e){
    e.preventDefault();
    if(this.checkReze(this.state.reze)){
      this.saveAndContinue(e);
    }else{
      if(this.checkOtherFields()){
        this.saveAndContinue(e);
      }
    }
  }

  checkReze(reze){
    return re.test(reze);
  }

  checkOtherFields(){
    var res = true;
    if(this.state.fname == null || this.state.fname === "") {
      this.setState({
        fnameErr : true
      })
      res = false;
    }else{
      this.setState({
        fnameErr : false
      })
    }
    if(this.state.lname == null || this.state.lname === ""){
      this.setState({
        lnameErr : true
      })
      res = false;
    }else{
      this.setState({
        lnameErr : false
      })
    }
    if(this.state.dof == null || this.state.dof === ""){
      this.setState({
        dofErr : true
      })
      res = false;
    }else{
      this.setState({
        dofErr : false
      })
    }
    return res;
  }

  saveAndContinue(e) {
    e.preventDefault();
    this.props.saveValues(this.state);
    this.props.next();
  }

  handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }



  render(){
    return(
      <div className="hp-form">
            <Form>
              <FormGroup>
                <FormText>Nom</FormText>
                <Input className={this.state.lnameErr ? 'field-error' : ''} type="text" name="lname" ref="lname" defaultValue={ this.props.fieldValues.lname } onChange={e => this.handleChange(e)}/>
              </FormGroup>

              <FormGroup>
                <FormText>Prénom</FormText>
                <Input type="text" className={this.state.fnameErr ? 'field-error' : ''}name="fname" ref="fname" defaultValue={ this.props.fieldValues.fname }  onChange={e => this.handleChange(e)}/>
              </FormGroup>

              <FormGroup>
                <FormText>Date de naissance</FormText>
                <Input type="date" className={this.state.dofErr ? 'field-error' : ''} name="dof" ref="dof"  pattern="\d{1,2}/\d{1,2}/\d{4}"  defaultValue={ this.props.fieldValues.dof } onChange={e => this.handleChange(e)} />
              </FormGroup>
              <hr/>
              <p>Ou</p>
              <FormGroup>
                <FormText>N° REZE (2018X xxxxx)</FormText>
                <Input type="text" name="reze" ref="reze" defaultValue={ this.props.fieldValues.reze } onChange={e => this.handleChange(e)} />
              </FormGroup>

            </Form>
            <div className="btn next">
              <Button className="send" onClick={this.controlFields}>Suivant</Button>
            </div>
            <div className="btn back">
              <Button className="send" onClick={this.props.back}>Retour</Button>
            </div>

      </div>
  )}


}
