import React,{Component} from 'react';
import './preparer.css';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {Helmet} from "react-helmet";

//import AdSense from 'react-adsense';

//const adsensecode = "ca-pub-6740656199072217";
import ReactGA from 'react-ga';

export default class PreparerEntretien extends Component{
  constructor(props){
    super(props);
    this.state= {
      session : true
    }
  }

  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/preparer-entretien');
  }

  render(){
    return(
      <div className="preparer-entretien-main-container">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{this.props.pageTitle}</title>
            <link rel="canonical" href={this.props.pageCanonicalLink} />
            <meta name="description" content={this.props.pageDescription} />
        </Helmet>
        <div>
          <Breadcrumb>
            <BreadcrumbItem active>Préparer mon entretien</BreadcrumbItem>
          </Breadcrumb>
        </div>
        <div className="top-image">
          <img src={require('./img/top-img.jpg')} alt="top-img"/>
        </div>
        {
          this.state.session?
          <div className="preparer-content">
            <div className="preparer-content-header">
              <p> Ce qu'il faut savoir pour être serrein le jour de votre entretien </p>
              <p> Préparer votre entretien en révisant les étapes suivantes et en vous entraînant à nos <a href="/quizz"> 200 questions du quizz </a> </p>
            </div>
            <div className="preparer-content-description">
              <div className="preparer-content-page-title">
                <h1> Préparer mon entretien </h1>
              </div>
              <div className="red-line">
              </div>
              <div className="preparer-content-chapitres">
                    <li><a href="/preparer-entretien/deroulement"> Déroulement de l'entretien <i className="fas fa-angle-right"></i></a></li><br/>
                    <li><a href="/preparer-entretien/culture-generale"> Révisez votre culture générale <i className="fas fa-angle-right"/></a></li><br/>
                    <li><a href="/preparer-entretien/charte-des-droits-et-devoirs"> La Charte Des Droits et Devoirs du Citoyen Français <i className="fas fa-angle-right"/></a></li><br/>
                    <li><a href="/preparer-entretien/questions-personnelles"> Les Questions Personnelles à Préparer <i className="fas fa-angle-right"/></a></li><br/>
              </div>
            </div>

          </div>
          :
          <div className="offline-content preparer">
            <h2> Veuillez vous connecter </h2>
            <p> Cette section n'est disponible que pour les membres du site </p>
            <a href="/login"> Me connecter </a> ou <a href="/register"> Créer un compte </a>

          </div>
        }

      </div>
    )
  }
}
