import React,{Component} from 'react';
import { Button, Form, FormGroup, Input, Label, FormFeedback, Alert } from 'reactstrap';


import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default class ChangePwdForm extends Component{
  constructor(props){
    super(props);
    this.state={
      oldPwd : "",
      newPwd : "",
      newPwdConfirmed : "",
      oldPwdErr : false,
      checkOldPwd : false,
      newPwdErr : false,
      newPwdConfirmedErr : false,
      pwdChanged : false,
    }
    this.controlFields = this.controlFields.bind(this);
    this.updateDataPwd = this.updateDataPwd.bind(this);
    this.checkOldPwd = this.checkOldPwd.bind(this);
  }

  handleChange = (e) => {
      this.setState({
            [e.target.name]: e.target.value
        });
    }

  controlFields(){
    var res = true;
    if(this.state.oldPwd == null || this.state.oldPwd === ""){
      this.setState({
        oldPwdErr : true
      });
      res = false;
    }
    else{
      this.setState({
        oldPwdErr : false
      });
      this.checkOldPwd();

      if(this.state.checkOldPwd){
        this.setState({
          oldPwdErr : false
        });

        if(this.state.newPwd !== this.state.newPwdConfirmed){
          this.setState({
            newPwdConfirmedErr : true
          });
          res = false;
        }else{
          this.setState({
            newPwdConfirmedErr : false
          });
        }

        if(this.state.newPwd == null || this.state.newPwd === ""){
          this.setState({
            newPwdErr : true
          })
          res = false;
        }else{
          this.setState({
            newPwdErr : false
          })
        }

      }else{
        this.setState({
          oldPwdErr : true
        });
        res = false;
      }


    }

    if(res){
      this.updateDataPwd()
    }
  }

  updateDataPwd(){
    let c_jwt  = cookies.get("jwt").split("=")[0];
    var self = this;
    axios({
              method: 'POST',
              url : "https://www.natification.fr/serv/api/client/setrenew.php",
              headers: {
                'Content-Type': 'application/json'
              },
              data: {
                'jwt': c_jwt,
                'newpwd' : this.state.newPwd,
              }
            })
      .then(function (response) {
        self.setState({
          pwdChanged : true,
        });
      })
      .catch(function (error) {
        self.setState({
          pwdChangedError : true,
        });
      });
  }

  checkOldPwd(){
    let c_jwt  = cookies.get("jwt").split("=")[0];
    var self = this;
    axios({
              method: 'POST',
              url : "https://www.natification.fr/serv/api/client/check_pwd.php",
              headers: {
                'Content-Type': 'application/json'
              },
              data: {
                'jwt': c_jwt,
                'email' : this.props.email,
                'pwd' : this.state.oldPwd,
              }
            })
      .then(function (response) {
        self.setState({
          checkOldPwd : true,
        });
      })
      .catch(function (error) {
        self.setState({
          checkOldPwd : false,
        });
      });
  }


  render(){
    return(
        <div className="changePwdForm">
        {
          this.state.pwdChanged?
          <Alert color="success">Mot de passe modifié !</Alert>
          :""

        }
        {
          this.state.pwdChangedError?
          <Alert color="danger"> Une erreur est survenue. Merci de bien vouloir réessayer.</Alert>
          :""

        }
        {this.state.pwdChanged?
          <Button color="secondary" onClick={this.props.toggleModificationPwd}> Retour </Button>
          :
          <Form>
            <Label for="lname-new">Ancien mot de passe</Label>
            <FormGroup>
              <Input type="hidden" autoComplete="username" className="my-infos-content-field" onChange={this.handleChange} value={this.props.email} name="email" id="email" />
            </FormGroup>

            <FormGroup>
              {this.state.oldPwdErr?
                <div className="formGroup-Err">
                  <Input invalid type="password" autoComplete="current-password" className="my-infos-content-field" value={this.state.oldPwd} onChange={this.handleChange} name="oldPwd" id="lname-new" required/>
                  <FormFeedback>Mot de passe erroné</FormFeedback>
                </div>
              :
              <Input type="password" autoComplete="current-password" className="my-infos-content-field" value={this.state.oldPwd} onChange={this.handleChange} name="oldPwd" id="lname-new" required/>
              }
            </FormGroup>

            <Label for="fname-new">Nouveau mot de passe</Label>
            <FormGroup>
            {
              this.state.newPwdErr?
              <Input invalid type="password" autoComplete="new-password" className="my-infos-content-field" value={this.state.newPwd} onChange={this.handleChange} name="newPwd" id="fname-new" required/>
              :
              <Input type="password" autoComplete="new-password" className="my-infos-content-field" value={this.state.newPwd} onChange={this.handleChange} name="newPwd" id="fname-new" required/>
            }
            </FormGroup>

            <Label for="email-new">Confirmez nouveau mot de passe</Label>
            <FormGroup>
              {this.state.newPwdConfirmedErr?
                <div className="formGroup-Err">
                  <Input invalid type="password" autoComplete="new-password" className="my-infos-content-field" value={this.state.newPwdConfirmed} onChange={this.handleChange} name="newPwdConfirmed" required/>
                  <FormFeedback>Les nouveaux mots de passes ne correspondent pas</FormFeedback>
                </div>
              :
              <Input type="password" autoComplete="new-password" className="my-infos-content-field" value={this.state.newPwdConfirmed} onChange={this.handleChange} name="newPwdConfirmed" required/>
              }
            </FormGroup>

              <Button color="success" onClick={this.controlFields}> Valider et mettre à jour </Button><br/>
              <Button color="secondary" onClick={this.props.toggleModificationPwd}> Annuler </Button>
          </Form>
        }

        </div>
    )}
}
