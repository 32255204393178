import React from 'react';
import './qcm-reminder.scss';

export const QCMReminder = (props)=>{
  return <div className="block-qcm-pres">
  <section className="portfolio-experiment">
    N'oubliez pas de vous entraîner et <br/>
      <a href="https://natification.fr/quizz">
        <span className="text">Testez vos connaissances</span>
        <span className="line -right"></span>
        <span className="line -top"></span>
        <span className="line -left"></span>
        <span className="line -bottom"></span>
      </a>
    </section>
  </div>

  };


/*
<div className="block-qcm-reminder">
  <div className="qcm-reminder-text">
    <p> N'oubliez pas de vous entraîner </p>
  </div>
  <div className="qcm-reminder-img">
    <img src={require('./img/qcm-reminder.jpg')} alt="qcm-img" />
  </div>

</div>
<div className="qcm-link">
    <button onClick="/preparer-entretien/quizz">FAIRE LE QCM</button>
</div>


*/
