import React,{Component} from 'react';
import './notification.css';
import './Form/form.css';
import {Helmet} from "react-helmet";

import SuccessForm from './Form/SuccessForm';

export default class NotificationSuccess extends Component{
  constructor(props){
    super();
	}


  renderForm(){
      return <SuccessForm />;
  }

  render(){
    return(
      <div className="page full">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <link rel="canonical" href={this.props.pageCanonicalLink} />
          <meta name="description" content={this.props.pageDescription} />
      </Helmet>
          <div className="title">
            <h3> Service de notification à l'acquisition de la nationnalité française </h3>
          </div>
          <div className="form-title">
            <p> Si vous avez souscrit à la notification par email, vérifiez bien que le mail de confirmation n'est pas dans vos spam </p>
          </div>
          {this.renderForm()}

      </div>
    )
  }
}
