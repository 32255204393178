import React, {Component} from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import './livret.css';
import {QCMReminder} from '../QCMReminder/qcm-reminder';
import LivretChapitresMenu from './MenuChapitres/Chapitres';
import {Helmet} from "react-helmet";

export default class LivretDuCitoyenDemocratie extends Component{
  constructor(props){
    super(props);

    this.state= {
      session : this.props.session
    }
  }
  render(){
    return(
      <div className="livret-main-container">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <link rel="canonical" href={this.props.pageCanonicalLink} />
          <meta name="description" content={this.props.pageDescription} />
      </Helmet>
        <Breadcrumb>
          <BreadcrumbItem><a href="/preparer-entretien">Préparer mon entretien</a></BreadcrumbItem>
          <BreadcrumbItem><a href="/preparer-entretien/culture-generale">Culture générale</a></BreadcrumbItem>
          <BreadcrumbItem active>Le système démocratique</BreadcrumbItem>
        </Breadcrumb>
        <div className="top-image livret">
          <div className="top-image-title">
            <h2> Préparer mon entretien : <span>Le système démocratique</span></h2>
          </div>
        </div>

        {
          this.props.session?
        <div className="livret-content">
          <LivretChapitresMenu/>


          <div className="livret-content-header">
            <p>Être citoyen c'est avoir des droits garantis par la loi.

            Être citoyen c'est également devoir respecter la loi afin d'assurer une société organisée.

            Tous les citoyens de le République ont les mêmes droits et devoirs.

            Ils sont également soumis au respect des lois et des règlements.</p>
            <br/>
            <p> Vous pouvez consulter toutes les lois et les règles en vigueur directement sur internet. Le gouvernement a mis en place 2 sites: </p>
             <a href="https://www.legifrance.gouv.fr" target="_blank" rel="noopener noreferrer"> legifrance.gouv.fr </a> <br/>
             <a href="https://www.vie-publique.fr" target="_blank" rel="noopener noreferrer"> vie-publique.fr </a> <br/>
          </div>

          <div className="livret-section section-1">
            <h2> La République Française : <br/>un système de Gouvernement Démocratique </h2>
            <h3> L'organisation politique </h3>
            <p> Les partis politiques se forment et exercent leur activité librement tout en respectant la loi.
            L'organisation de l'Etat est définie par un texte: <a href="http://www.assemblee-nationale.fr/connaissance/constitution.asp" target="_blank" rel="noopener noreferrer"> la Constitution </a>.<br/>
            La Vème République, mise en place en 1958, est notre constitution actuelle. <span className="effect quoting-focus" >Elle est connue en partie pour la séparation des pouvoirs exécutif, législatif et judiciaire </span>
            </p>
          </div>

          <div className="livret-section section-2">
            <h3> La séparation des pouvoirs </h3>


            <div className="blocks-section-pouvoirs">

              <div className="block-section-pouvoir exec">
                <img src={require("./img/exec.png")} alt="img-1"/>
                <div className="block-section-pouvoir-title">
                  <h4> Le pouvoir exécutif </h4>
                </div>
                <div className="block-section-pouvoir-text">
                  <p>Il est détenu par le chef de l’État et par les membres du Gouvernement qui exécutent les lois votées au Parlement.
                  <br/>Le Président de la République est élu au <span className="effect quoting-focus" >suffrage universel pour cinq ans</span>. Il nomme le Gouvernement, dirigé par le Premier ministre, issu de la majorité aux élections législatives.
                  </p>
                </div>
              </div>

              <div className="block-section-pouvoir leg">
              <img src={require("./img/legis.png")} alt="img-1"/>

                <div className="block-section-pouvoir-title">
                  <h4> Le pouvoir législatif </h4>
                </div>
                <div className="block-section-pouvoir-text">
                  <p>Il est exercé par le Parlement qui est composé <span className="effect quoting-focus" >de l’Assemblée Nationale et du Sénat </span>.
                  <br/>Il propose, vote la loi et contrôle le gouvernement.
                  <br/>Les députés à l’Assemblée Nationale sont élus <span className="effect quoting-focus" > tous les cinq ans </span> aux élections législatives.
                  </p>
                </div>
              </div>

              <div className="block-section-pouvoir jud">
              <img src={require("./img/judiciaire.png")} alt="img-1"/>

                <div className="block-section-pouvoir-title">
                  <h4> Le pouvoir judiciaire </h4>
                </div>
                <div className="block-section-pouvoir-text">
                  <p> Il est exercé par les magistrats.<br/>
                   Il contrôle et sanctionne les litiges entre de particuliers, des entreprises ou des entités publiques. <span className="effect quoting-focus" >Il interprète la loi et évalue</span> en toute impartialité les affaires soumises à son office. </p>
                </div>
              </div>

            </div>

          </div>

          <div className="livret-section section-3">
            <h3> Droits et devoirs du citoyen </h3>
            <p> En tant que citoyen de la république,<span className="effect quoting-focus" > tout français possède des droits mais aussi des devoirs</span> dont voici quelques exemples :</p>
            <div className="livret-section section3-droits-devoirs">

              <div>
              <h4> Droits </h4>

              <p> Le droit de vote<br/>
              Le droit d'expression<br/>
              Le droit de propriété<br/>
              Le droit de disposer de son corps<br/>
              Le droit de s'exprimer<br/>
              Le droit au travail<br/>
              Le droit à la protection sociale<br/>
              ...</p>
              </div>

              <div>
              <h4> Devoirs </h4>

              <p>Le devoir de respecter la loi<br/>
              Le devoir de défense nationale<br/>
              Le devoir de respecter de droit des autres<br/>
              Le devoir de payer ses impôts<br/>
              Le devoir de solidarité<br/>
              ...</p>
              </div>

            </div>

          </div>

          <div className="qcm-reminder">
            <QCMReminder/>
          </div>

          <div className="livret-section section-4">
            <h3> Le système éléctoral </h3>
            <img src={require('./img/Elections.png')} alt="elect"/>
          </div>

          <div className="livret-section section-end">
              <span> Chapitre précédent :</span>
              <span> </span>
              <span> Chapitre suivant : </span>
             <div className="chap-prev"> <a href="/preparer-entretien/culture-generale-part2"><i className="fas fa-arrow-left"></i> La devise </a></div>
             <br/>
             <div className="chap-next"> <a href="/preparer-entretien/culture-generale-part4"> Organisation du territoire <i className="fas fa-arrow-right"></i> </a></div>
          </div>

        </div>
        :
        <div className="offline-content livret">
          <h2> Veuillez vous connecter </h2>
          <p> Cette section n'est disponible que pour les membres du site </p>
          <a href="/login"> Me connecter </a> ou <a href="/register"> Créer un compte </a>
        </div>
        }
      </div>


  )}
}
