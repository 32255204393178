import React, {Component} from 'react';
import './historyTL.scss';
import HistoryTLComponent from './HistoryTLComponent';

import { VerticalTimeline }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

export default class HistoryTL extends Component{

  render(){
    return(
      <div className="historytimeline">

        <div className="timeline-wrapper">
        <VerticalTimeline>
          <HistoryTLComponent
            date="7 millions d'années avant J-C "
            title=""
            subtitle=""
            content="Apparition des premiers hominidés en Afrique"
            imgsrc=""
            position="left"
            iconColor="rgb(148, 182, 85)"
          />

          <HistoryTLComponent
            date="3 millions d'années avant J-C  "
            title=""
            subtitle=""
            content="Apparition des premiers ancêtres de l'homme en Afrique"
            imgsrc=""
            position="right"
            iconColor="rgb(85, 182, 168)"

          />

          <HistoryTLComponent
            date="18 000 et 15 000 avant J-C"
            title="Grotte de Lascaux"
            subtitle=""
            content="Les premiers hommes peignent les parois de la grotte en France"
            imgsrc="lascaux.jpg"
            position="left"
            iconColor="rgb(85, 182, 168)"

          />

          <HistoryTLComponent
            date="3000 ans avant J-C "
            title=""
            subtitle=""
            content="Découverte de l'écriture en Mésopotamie signant la fin de la Préhistoire"
            imgsrc=""
            position="right"
            iconColor="rgb(249, 121, 183)"
          />

          <HistoryTLComponent
            date="2000 ans avant J-C "
            title=""
            subtitle=""
            content="Début de l’âge du bronze en Europe"
            imgsrc=""
            position="left"
            iconColor="rgb(78, 238, 74)"
          />

          <HistoryTLComponent
            date="1200 ans avant J-C "
            title=""
            subtitle=""
            content="Début de l’âge de fer et invention de l’alphabet par les Phéniciens"
            imgsrc=""
            position="right"
            iconColor="rgb(201, 176, 104)"
          />

          <HistoryTLComponent
            date="776 ans avant J-C"
            title=""
            subtitle=""
            content="Les premiers jeux olympiques en Grèce"
            imgsrc=""
            position="left"
            iconColor="rgb(212, 196, 75)"
          />

          <HistoryTLComponent
            date="600 ans avant J-C "
            title=""
            subtitle=""
            content="Création de la ville de Marseille"
            imgsrc=""
            position="right"
            iconColor="rgb(161, 149, 195)"
          />

          <HistoryTLComponent
            date="50 ans avant J-C "
            title="Installation des Romains en France"
            subtitle=""
            content="Les romains envahissent la France et la culture latine se met progressivement en place"
            imgsrc="romains.jpg"
            position="left"
            iconColor="rgb(86, 185, 114)"
          />

          <HistoryTLComponent
            date="An 0"
            title=""
            subtitle=""
            content="Naissance de Jésus Christ, crussifié à l'âge de 33 ans"
            imgsrc=""
            position="right"
            iconColor="rgb(106, 80, 241)"
          />

          <HistoryTLComponent
            date="An 476"
            title=""
            subtitle=""
            content="Effondrement de l'Empire Romain"
            imgsrc=""
            position="left"
            iconColor="rgb(165, 185, 86)"
          />

          <HistoryTLComponent
            date="481-511"
            title="Règne de Clovis (466-511)"
            subtitle=""
            content="Premier roi des Francs qui adopte la religion chrétienne"
            imgsrc="clovis.jpg"
            position="right"
            iconColor="rgb(170, 132, 245)"
          />

          <HistoryTLComponent
            date="800"
            title=""
            subtitle=""
            content="Couronnement de Charlemagne en tant que Empereur d’Occident"
            imgsrc=""
            position="left"
            iconColor="rgb(226, 214, 137)"
          />

          <HistoryTLComponent
            date="1140"
            title=""
            subtitle=""
            content="Début du style Gothique"
            imgsrc=""
            position="right"
            iconColor="rgb(138, 221, 108)"
          />

          <HistoryTLComponent
            date="1337 "
            title=""
            subtitle=""
            content="Début de la guerre de 100 ans confrontant la France et l’Angleterre. Cette guerre a durée 116 ans !"
            imgsrc=""
            position="left"
            iconColor="rgb(233, 65, 105)"
          />

          <HistoryTLComponent
            date="1431 "
            title="Mort de Jeanne D’arc (1412-1431)"
            subtitle=""
            content="Jeune paysanne et qui a menée les troupes françaises lors de la guerre de 100 ans"
            imgsrc="jeanne.jpg"
            position="right"
            iconColor="rgb(180, 129, 189)"
          />

          <HistoryTLComponent
            date="1492"
            title=""
            subtitle=""
            content="Découverte de l’Amérique par Christophe Colomb (navigateur espagnol)"
            imgsrc=""
            position="left"
            iconColor="rgb(12, 89, 223)"
          />

          <HistoryTLComponent
          date="1515 – 1547"
          title=""
          subtitle=""
          content="Règne du roi François 1er "
          imgsrc=""
          position="right"
          iconColor="rgb(84, 175, 109)"
          />

          <HistoryTLComponent
            date="1562 "
            title=""
            subtitle=""
            content="Guerre des religions"
            imgsrc=""
            position="left"
            iconColor="rgb(223, 224, 34)"
          />

          <HistoryTLComponent
            date="1589 – 1610"
            title="Règne de Henri IV (1553-1610) et signature de l’Édit de Nantes"
            subtitle=""
            content="Henri IV instaure la paix des religions et mettant fin à la guerre et en autorisant aux protestants d’exercer librement leur religion."
            imgsrc="henri4.jpg"
            position="right"
            iconColor="rgb(78, 222, 147)"
          />

          <HistoryTLComponent
            date="15 Janvier 1622"
            title="Naissance de Molière (1622 – 1673)"
            subtitle=""
            content="L’un des plus célèbres comédiens et auteurs du théâtre Français.  Connu pour des œuvres telles que l’Avare, Le Bourgeois Gentilhomme ou encore le malade imaginaire"
            imgsrc="moliere.jpg"
            position="left"
            iconColor="rgb(136, 192, 237)"
          />

          <HistoryTLComponent
            date="1661 – 1715 "
            title="Règne de Louis XIV"
            subtitle=""
            content="« le Roi Soleil » est un roi absolu à la tête d’un Etat puissant. Il est également connu pour la création du célèbre château de Versailles."
            imgsrc="louis14.jpg"
            position="right"
            iconColor="rgb(161, 21, 209)"
          />

          <HistoryTLComponent
            date="1715 à 1789"
            title="Siècle des Lumières "
            subtitle=""
            content="Mouvement philosophique qui a pour objectif de combattre l’obscurantisme à travers la diffusion des connaissances. Parmi ces philosophes, il y avait Voltaire, Diderot ou encore Rousseau"
            imgsrc="lumieres.jpg"
            position="left"
            iconColor="rgb(157, 176, 59)"
          />

          <HistoryTLComponent
            date="14 juillet 1789"
            title="Prise de la Bastille"
            subtitle=""
            content="Evénement marquant de la Révolution Française,  la prison de la Bastille est prise par le peuple suite à la volonté du roi Louis XVI d’augmenter les impôts alors que la France connaît une crise agricole et économique"
            imgsrc="bastille.jpg"
            position="right"
            iconColor="rgb(8, 111, 142)"
          />

          <HistoryTLComponent
            date="26 Août 1789"
            title=""
            subtitle=""
            content="Publication de la Déclaration des Droits de l’Homme et du Citoyen"
            imgsrc=""
            position="left"
            iconColor="rgb(84, 43, 182)"
          />

          <HistoryTLComponent
            date="22 septembre 1792 "
            title=""
            subtitle=""
            content="Mise en place de la Première République "
            imgsrc=""
            position="right"
            iconColor="rgb(184, 236, 244)"
          />

          <HistoryTLComponent
            date="26 février 1802"
            title="Naissance de Victor Hugo (1802-1885)"
            subtitle=""
            content="Célèbre écrivain français engagé contre les inégalités sociales et la peine de mort. Parmi ces nombreux ouvrages il y a « Notre-Dame de Paris » ou encore « Le Dernier Jour d’un Condamné »"
            imgsrc="victorhugo.jpg"
            position="left"
            iconColor="rgb(78, 207, 48)"
          />

          <HistoryTLComponent
            date="2 décembre 1804"
            title="Napoléon 1er (1769-1821) est sacré Empereur"
            subtitle=""
            content="Il a dirigé la France pendant près de 15 ans. Il est à l’origine du Code Civil."
            imgsrc="napoleon.jpg"
            position="right"
            iconColor="rgb(149, 145, 41)"
          />

          <HistoryTLComponent
            date="1830"
            title=""
            subtitle=""
            content="Début de développement de l’Empire Colonial principalement aux Antilles, en Afrique et en Asie"
            imgsrc=""
            position="left"
            iconColor="rgb(171, 28, 167)"
          />

          <HistoryTLComponent
            date="25 Février 1848 "
            title=""
            subtitle=""
            content="Mise en place de la Deuxième République"
            imgsrc=""
            position="right"
            iconColor="rgb(8, 29, 207)"
          />

          <HistoryTLComponent
            date="27 Avril 1848 "
            title="Abolition de l’esclavage"
            subtitle=""
            content=""
            imgsrc="esclavage.jpg"
            position="left"
            iconColor="rgb(67, 130, 117)"
          />

          <HistoryTLComponent
            date="30 Janvier 1875"
            title=""
            subtitle=""
            content="Mise en place de la Troisième République"
            imgsrc=""
            position="right"
            iconColor="rgb(166, 202, 247)"
          />

          <HistoryTLComponent
            date="1881 – 1882"
            title=""
            subtitle=""
            content="Jules Ferry met en place l’école laïque, gratuite et obligatoire"
            imgsrc=""
            position="left"
            iconColor="rgb(241, 223, 57)"
          />

          <HistoryTLComponent
            date="1889"
            title="Construction de la Tour Eiffel"
            subtitle=""
            content="Exposition universelle à Paris et construction de la Tour Eiffel"
            imgsrc="toureiffel.jpg"
            position="right"
            iconColor="rgb(121, 147, 79)"
          />

          <HistoryTLComponent
            date="9 Décembre 1905 "
            title=""
            subtitle=""
            content="Abrogation de la loi pour la séparation de l'Église et de l'État"
            imgsrc=""
            position="left"
            iconColor="rgb(87, 111, 212)"
          />

          <HistoryTLComponent
            date="28 Juin 1914 "
            title="Début de la 1ère Guerre Mondiale"
            subtitle=""
            content="Début de la Première Guerre Mondiale suite à l’assassinat de l'héritier de l'Empire d'Autriche-Hongrie"
            imgsrc="GM1-1.jpg"
            position="right"
            iconColor="rgb(51, 224, 226)"
          />

          <HistoryTLComponent
            date="11 Novembre 1918 "
            title="Fin de la Guerre"
            subtitle=""
            content="Signature de l'Armistice marquant la fin de la Première Guerre Mondiale après  plus de 9 millions de morts"
            imgsrc="GM1-2.jpg"
            position="left"
            iconColor="rgb(27, 50, 246)"
          />

          <HistoryTLComponent
            date="1er Septembre 1939 "
            title="Début de la Seconde Guerre Mondiale"
            subtitle=""
            content="20 ans seulement après la fin de la 1ère Guerre Mondiale, c'est le début de la Seconde Guerre Mondiale suite à l ‘entrée des allemands en Pologne. Hitler et son régime désire persécuter les opposants politiques, des Juifs, des Tziganes, des homosexuels et des handicapés "
            imgsrc="GM2-1.jpg"
            position="right"
            iconColor="rgb(173, 154, 222)"
          />

          <HistoryTLComponent
            date="18 Juin 1940 "
            title=""
            subtitle=""
            content="Appel à la résistance du général Charles de Gaulle (1890-1970) depuis Londres"
            imgsrc=""
            position="left"
            iconColor="rgb(230, 166, 244)"
          />

          <HistoryTLComponent
            date="1940-1944 "
            title="Régime de Vichy"
            subtitle=""
            content="Mis en place par le Maréchal Pétain ce régime politique a gouverné la France lors de l’occupation des nazis. Ce régime a collaboré avec l’Allemagne et donc au génocide "
            imgsrc="vichy.jpg"
            position="right"
            iconColor="rgb(11, 9, 161)"
          />

          <HistoryTLComponent
            date="21 Avril 1944 "
            title="Le droit de vote accordé aux femmes"
            subtitle=""
            content="Les femmes deviennent électrices et éligibles, comme les hommes. Un an plus tard, le 29 avril 1945, elles votaient pour la première fois."
            imgsrc=""
            position="left"
            iconColor="rgb(177, 195, 47)"
          />

          <HistoryTLComponent
            date="8 mai 1945 "
            title="Fin de la Seconde Guerre Mondiale"
            subtitle=""
            content="Fin de la Seconde Guerre Mondiale après plus de 50 millions de morts"
            imgsrc="GM2-2.jpg"
            position="right"
            iconColor="rgb(152, 128, 48)"
          />

          <HistoryTLComponent
            date="13 Octobre 1946 "
            title=""
            subtitle=""
            content="Mise ne place de la Quatrième République"
            imgsrc=""
            position="left"
            iconColor="rgb(55, 194, 97)"
          />

          <HistoryTLComponent
            date="1950 – 1962"
            title="Décolonisation française"
            subtitle=""
            content="Les pays colonisés revendiquent leur indépendance"
            imgsrc="decolo.jpg"
            position="left"
            iconColor="rgb(171, 21, 166)"
          />

          <HistoryTLComponent
            date="25 Mai 1957 "
            title="Traité de Rome"
            subtitle=""
            content="Signature du Traité de Rome et création de la Communauté Économique Européenne"
            imgsrc="traiterome.jpg"
            position="right"
            iconColor="rgb(148, 59, 105)"
          />

          <HistoryTLComponent
            date="4 octobre 1958 "
            title=""
            subtitle=""
            content="Mise en place de la Cinquième République"
            imgsrc=""
            position="left"
            iconColor="rgb(164, 24, 157)"
          />

          <HistoryTLComponent
            date="mai 1968"
            title="Mouvements de Mai 68"
            subtitle=""
            content="Mouvement de contestation politique sociale et culturelle mis en place par les étudiants, les salariés et les ouvriers "
            imgsrc="mai68.jpg"
            position="left"
            iconColor="rgb(123, 133, 226)"
          />

          <HistoryTLComponent
            date="5 Juillet 1974 "
            title=""
            subtitle=""
            content="La majorité est fixée à 18 ans"
            imgsrc=""
            position="right"
            iconColor="rgb(37, 35, 163)"
          />

          <HistoryTLComponent
            date="17 Janvier 1975 "
            title=""
            subtitle=""
            content="Simone Veil met en place la loi sur l’interruption volontaire de grossesse, autorisant de ce fait l’avortement "
            imgsrc=""
            position="left"
            iconColor="rgb(150, 187, 87)"
          />

          <HistoryTLComponent
            date="18 Septembre 1981 "
            title=""
            subtitle=""
            content="Abolition de la peine de mort"
            imgsrc=""
            position="right"
            iconColor="rgb(64, 199, 110)"
          />

          <HistoryTLComponent
            date="2019-2020"
            title="Aujourd'hui"
            subtitle=""
            content="Vous préparez votre entretien de naturalisation pour faire partie de cette histoire"
            imgsrc=""
            position="left"
            iconColor="rgb(64, 199, 110)"
          />




        </VerticalTimeline>
        </div>
      </div>
  )}
}
