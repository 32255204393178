import React, {Component} from 'react';
import { Button } from 'reactstrap';
import ReactGA from 'react-ga';

import axios from 'axios';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

export default class SuccessForm extends Component{
  constructor(props){
    super(props);
    this.update_wait = this.update_wait.bind(this);
    this.update_wait();
  }

  async update_wait(){
    let c_cust  = cookies.get("_cust");
    if(typeof c_cust === 'undefined'){
      return 0;
    }
    var self = this;
    axios({
              method: 'POST',
              url : "https://www.natification.fr/serv/api/customer/wait.php",
              headers: {
                'Content-Type': 'application/json'
              },
              data: {
                'did': c_cust,
              }
            })
      .then(function (response) {
        self.setState({
          waiting : true,
        })
      })
      .catch(function (error) {
        console.log(error);
        self.setState({
          waiting : false,
        })
      });
  }

  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/notification/subscribe/success');
  }

  render(){
    return(
      <div className="hp-form">
              <div className="content-center">
                <img alt="check" src={require("./img/check.svg")} />

                <hr/>
                <p>Votre demande a bien été enregistrée</p><br/>
                <p>La facture vous sera adressée par email</p>


                <div className="btn next">
                  <Button className="send" href="/">Retour à l'accueil</Button>
                </div>
              </div>
      </div>
  )}
}
