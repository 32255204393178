import React, {Component} from 'react';
import './myAccount.css';
import axios from 'axios';
import { Button, Form, FormGroup, Input, Label, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import ReactGA from 'react-ga';
import ChangePwdForm from './ChangePwdForm';
import ScoresHistory from './ScoresHistory';
/*import ServicesSouscrits from './ServicesSouscrits';*/

import classnames from 'classnames';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default class MyAccount extends Component{
  constructor(props){
    super(props);
    this.state= {
      session : this.props.session,
      myfname : "",
      mylname : "",
      myemail : "",
      modifying: false,
      modifyingPwd : false,
      activeTab: '1'

    }
    this.updateData = this.updateData.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleModification = this.toggleModification.bind(this);
    this.toggleModificationPwd = this.toggleModificationPwd.bind(this);
    this.toggle = this.toggle.bind(this);

  }

  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/mon-compte');
  }


  componentWillReceiveProps(){
      this.retreiveData();
  }

  toggle(tab) {
     if (this.state.activeTab !== tab) {
       this.setState({
         activeTab: tab
       });
     }
   }

  retreiveData(){
    let c_jwt  = cookies.get("jwt").split("=")[0];
    var self = this;
    axios({
              method: 'POST',
              url : "https://www.natification.fr/serv/api/client/get_user_info.php",
              headers: {
                'Content-Type': 'application/json'
              },
              data: {
                'jwt': c_jwt
              }
            })
      .then(function (response) {
        self.setState({
          myfname : response.data.data.firstname,
          mylname: response.data.data.lastname,
          myemail : response.data.data.email
        })
      })
      .catch(function (error) {
        self.setState({
          error : true,
        });
      });
  }

  handleChange = (e) => {
      this.setState({
            [e.target.name]: e.target.value
        });
    }

  updateData(){
    let c_jwt  = cookies.get("jwt").split("=")[0];
    var self = this;
    axios({
              method: 'POST',
              url : "https://www.natification.fr/serv/api/client/update_user.php",
              headers: {
                'Content-Type': 'application/json'
              },
              data: {
                'jwt': c_jwt,
                'firstname': this.state.myfname,
                'lastname' : this.state.mylname,
                'email' : this.state.myemail
              }
            })
      .then(function (response) {
        self.state.mlname = "";
        self.state.myfname = "";
        self.state.myemail = "";
        self.componentWillReceiveProps();
        self.toggleModification();
      })
      .catch(function (error) {
        self.setState({
          error : true,
        });
      });
  }

  toggleModification(){
    this.setState({
      modifying : !this.state.modifying,
    })
  }

  toggleModificationPwd(){
    this.setState({
      modifyingPwd : !this.state.modifyingPwd,
    })
  }

  render(){
    return(
      <div className="my-account-main-container">
      {
        this.props.session?
        <div className="my-account-content">
          <h1> Mon compte </h1>

          <Nav tabs>

          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              Historique Quizz
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              Mes informations
            </NavLink>
          </NavItem>
          {/*<NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === '3' })}
              onClick={() => { this.toggle('3'); }}
            >
              Mes services
            </NavLink>
          </NavItem>*/}
        </Nav>

        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <div className="my-infos">
              <div className="scores-history">
                <h2> Mes Scores </h2>
                <hr/>
                <ScoresHistory />
              </div>
             </div>
          </TabPane>

           <TabPane tabId="2">
             {this.state.modifyingPwd?
               <div className="my-infos pwd">
                 <ChangePwdForm
                         email={this.state.myemail}
                         toggleModificationPwd ={this.toggleModificationPwd}
                 />
               </div>
               :
                 <div className="my-infos">
                 <div className="infosblock">

                 <h2> Informations personnelles </h2>
                 <hr/>
                   <div className={this.state.modifying? "my-infos-content":"my-infos-content active"}>
                     <div className="my-info-values">
                       <Label>Nom</Label>
                       <p>  {this.state.mylname} </p><br/>
                       <Label>Prénom</Label>
                       <p> {this.state.myfname} </p><br/>
                       <Label>email</Label>
                       <p>{this.state.myemail} </p>
                     </div>
                     <div className="multiple-buttons">
                       <Button color="primary"  onClick={this.toggleModification}> <i className="far fa-edit"></i> Modifier </Button>
                       <Button color="primary"  onClick={this.toggleModificationPwd}> <i className="fas fa-key"></i> Changer mon mot de passe</Button>
                     </div>
                   </div>
                   <div className={this.state.modifying? "my-infos-content active":"my-infos-content"}>
                   <Form>
                     <Label for="lname-new">Nom</Label>
                     <FormGroup>
                       <Input type="text" className="my-infos-content-field" value={this.state.mylname} name="mylname" id="lname-new" onChange={this.handleChange} required/>
                     </FormGroup>

                     <Label for="fname-new">Prénom</Label>
                     <FormGroup>
                       <Input type="text" className="my-infos-content-field" value={this.state.myfname} name="myfname" id="fname-new" onChange={this.handleChange} required/>
                     </FormGroup>

                     <Label for="email-new">e-mail</Label>
                     <FormGroup>
                       <Input type="email" className="my-infos-content-field" value={this.state.myemail} name="myemail" id="email-new" onChange={this.handleChange} required/>
                     </FormGroup>

                     <div className="multiple-buttons">
                       <Button color="success" onClick={this.updateData}> Valider et mettre à jour </Button>
                       <Button outline color="secondary" onClick={this.toggleModification}>  Annuler </Button>
                     </div>
                   </Form>

                   </div>
                 </div>
                 <br/>


               </div>
             }
           </TabPane>


           {/*<TabPane tabId="3">
             <div className="my-infos">
               <div className="scores-history">
                 <h2> Mes Services </h2>
                 <hr/>
                 <ServicesSouscrits />
               </div>
              </div>
           </TabPane>*/}

        </TabContent>


        </div>
      :
      <div className="offline-content preparer">
        <h2> Veuillez vous connecter </h2>
        <p> Cette section n'est disponible que pour les membres du site </p>
        <a href="/login"> Me connecter </a> ou <a href="/register"> Créer un compte </a>
      </div>
      }
      </div>
    )}
}
