import React, {Component} from 'react';
import './constituerDossier.css';
import ReactGA from 'react-ga';
import CreatableSelect from 'react-select/creatable';
import {Helmet} from "react-helmet";


const regions = [
  {label :"Auvergne-Rhône-Alpes", value:"1" },
  {label :"Bourgogne-Franche-Comté", value :"2"},
  {label :"Bretagne", value:"3" },
  {label :"Centre-Val de Loire", value:"4" },
  {label :"Corse", value:"5" },
  {label :"Grand Est", value:"6" },
  {label :"Hauts-de-France", value:"7" },
  {label :"Île-de-France", value:"8" },
  {label :"Normandie", value:"9" },
  {label :"Nouvelle Aquitaine", value:"10" },
  {label :"Occitanie", value:"11" },
  {label :"Pays de Loire", value:"12" },
  {label :"Provence-Alpes-Côte d'Azur (Paca)", value:"13" },
  {label :"Outre-mer", value:"14" }
];

const step2_regions = {
  1 : [ { label : "dans l’Allier, dans le Cantal, dans la Haute-Loire, dans le Puy-de-Dôme" , value : "1-1" } , { label : "dans l’Ain, l’Ardèche, la Loire, dans le Rhône" , value : "1-2" }, { label : "dans la Drôme, l’Isère, la Savoie, dans la Haute-Savoie" , value : "1-3" } ],
  2 : [ { label : "dans la Côte-d’Or, la Nièvre, la Saône-et-Loire, l’Yonne" , value : "2-1" } , { label : "dans le Doubs, le Jura, la Haute-Saône, le Territoire de Belfort" , value : "2-2" } ],
  3 : [ { label : "dans les Côtes-d’Armor, le Finistère, l’Ille-et-Vilaine, le Morbihan" , value : "3-1" } ],
  4 : [ { label : "dans le Cher, l’Eure-et-Loir, le Loir-et-Cher, le Loiret" , value : "4-1" } ],
  5 : [ { label : "en Corse-du-Sud, en Haute-Corse" , value : "5-1" } ],
  6 : [ { label : "dans le Bas-Rhin, dans le Haut-Rhin" , value : "6-1" } , { label : "dans les Ardennes, l’Aube, la Marne, la Haute-Marne" , value : "6-2" }, { label : "Meurthe-et-Moselle, en Meuse, en Moselle, dans les Vosges" , value : "6-3" } ],
  7 : [ { label : "dans le Nord, dans le Pas-de-Calais" , value : "7-1" } , { label : "dans la Somme, dans l’Aisne, dans l’Oise" , value : "7-2" } ],
  8 : [ { label : "à Paris" , value : "8-1" } , { label : "en Seine-et-Marne" , value : "8-2" }, { label : "dans les Yvelines" , value : "8-3" }, { label : "dans l’Essonne" , value : "8-4" }, { label : "en Seine-Saint-Denis" , value : "8-5" },{ label : "dans le Val-d’Oise" , value : "8-6" }, { label : "dans les Hauts-de-Seine" , value : "8-7"}, { label : "dans le Val-de-Marne" , value : "8-8" } ],
  9 : [ { label : "dans l’Eure, dans la Seine-Maritime" , value : "9-1" } , { label : "dans le Calvados, la Manche, l’Orne" , value : "9-2" } ],
  10 : [ { label : "en Dordogne, en Gironde, dans les Landes, dans le Lot-et-Garonne, en Pyrénées-Atlantiques" , value : "10-1" },{ label : "dans la Corrèze, la Creuse, la Haute-Vienne" , value : "10-2" } ,  { label : "en Charente, en Charente-Maritime, dans les Deux-Sèvres, dans la Vienne" , value : "10-3" } ],
  11 : [ { label : "dans l’Aude, le Gard, l’Hérault, la Lozère, les Pyrénées-Orientales" , value : "11-1" } , { label : "dans l’Ariège, dans l’Aveyron, en Haute-Garonne, dans le Gers, dans le Lot, en Hautes-Pyrénées, dans le Tarn, dans le Tarn-et-Garonne" , value : "11-2" } ],
  12 : [ { label : "dans la Loire-Atlantique, dans le Maine-et-Loire, la Mayenne, la Sarthe, dans la Vendée" , value : "12-1" } ],
  13 : [ { label : "dans les Alpes-Maritimes, dans le Var" , value : "13-1" } , { label : "dans les Alpes-de-Haute-Provence, dans les Hautes-Alpes, dans les Bouches-du-Rhône, dans le Vaucluse" , value : "13-2" } ],
  14 : [ { label : "Guadeloupe" , value : "14-1" } , { label : "Guyane" , value : "14-2" }, { label : "Martinique" , value : "14-3" }, { label : "Mayotte" , value : "14-4" }, { label : "Réunion" , value : "14-5" } ]
}

const results = {
  "1-1" : {pref : "la préfecture Puy-de-Dôme, à Clermont-Ferrand.",site_pref :"http://www.puy-de-dome.gouv.fr/", site_nat : "http://www.puy-de-dome.gouv.fr/naturalisations-a5060.html"},
  "1-2" : {pref : "la préfecture Rhône, à Lyon.",site_pref :"http://www.rhone.gouv.fr/", site_nat : "http://www.rhone.gouv.fr/Demarches-administratives/Toutes-les-demarches-pour-les-particuliers#!/particuliers/page/N111"},
  "1-3" : {pref : "la préfecture l’Isère, à Grenoble.",site_pref :"http://www.isere.gouv.fr/", site_nat : "http://www.isere.gouv.fr/Demarches-administratives/Naturalisation"},
  "2-1" : {pref : "la préfecture Côte d’Or, à Dijon.",site_pref :"http://www.cote-dor.gouv.fr/", site_nat : "http://www.cote-dor.gouv.fr/naturalisations-a5112.html#!/Particuliers/page/L835"},
  "2-2" : {pref : "la préfecture du Doubs, à Besançon.",site_pref :"http://www.doubs.gouv.fr/", site_nat : "http://www.doubs.gouv.fr/Demarches-administratives/Naturalisations"},
  "3-1" : {pref : "la préfecture de l’Ille-et-Vilaine, à Rennes.",site_pref :"http://www.ille-et-vilaine.gouv.fr/", site_nat : "http://www.ille-et-vilaine.gouv.fr/Demarches-administratives/Naturalisation/Plateforme-regionale-de-naturalisation-de-Bretagne"},
  "4-1" : {pref : "la préfecture de l’Indre-et-Loire, à Tours.",site_pref :"http://www.indre-et-loire.gouv.fr/", site_nat : "http://www.indre-et-loire.gouv.fr/Demarches-administratives/Naturalisations#!/Particuliers/page/N111"},
  "5-1" : {pref : "la préfecture de Haute-Corse, à Bastia.",site_pref :"http://www.haute-corse.gouv.fr/", site_nat : "http://www.haute-corse.gouv.fr/toutes-vos-demarches-a2144.html#!/particuliers/page/N111"},
  "6-1" : {pref : "la préfecture du Bas-Rhin, à Strasbourg.",site_pref :"http://www.bas-rhin.gouv.fr/", site_nat : "http://www.bas-rhin.gouv.fr/Demarches-administratives/Nationalite#!/Particuliers/page/N111"},
  "6-2" : {pref : "la plate-forme interdépartementale d’instruction des demandes de naturalisation à la sous-préfecture de la Marne, à Reims.",site_pref :"http://www.marne.gouv.fr/Services-de-l-Etat/Horaires-et-coordonnees/Sous-prefecture-de-Reims", site_nat : "http://www.marne.gouv.fr/Demarches-administratives/Naturalisation/Service-des-naturalisations#!/Particuliers/page/N111"},
  "6-3" : {pref : "la préfecture de Meurthe-et-Moselle, à Nancy.",site_pref :"http://www.meurthe-et-moselle.gouv.fr/", site_nat : "http://www.meurthe-et-moselle.gouv.fr/Demarches-administratives/Naturalisation#!/Particuliers/page/N111"},
  "7-1" : {pref : "la préfecture du Nord, à Lille.",site_pref :"http://www.nord.gouv.fr/", site_nat : "http://www.nord.gouv.fr/Demarches-administratives/Naturalisation"},
  "7-2" : {pref : "la préfecture de l’Oise, à Beauvais",site_pref :"http://www.oise.gouv.fr/", site_nat : "http://www.oise.gouv.fr/Demarches-administratives/Demarches-pour-les-particuliers#!/particuliers/page/N111"},
  "8-1" : {pref : "la préfecture de Police de Paris.",site_pref :"https://www.prefecturedepolice.interieur.gouv.fr/", site_nat : "https://www.prefecturedepolice.interieur.gouv.fr/Demarches/Services-en-ligne/Pour-vous-aider/Toutes-les-demarches#ressortissant"},
  "8-2" : {pref : "la sous-préfecture de Torcy.",site_pref :"http://www.seine-et-marne.gouv.fr/Demarches-administratives/Toutes-les-demarches/Accueil-des-Etrangers/Accueil-en-sous-prefecture-de-Torcy/Accueil-en-sous-prefecture-de-Torcy", site_nat : "http://www.seine-et-marne.gouv.fr/Demarches-administratives/Toutes-les-demarches/Accueil-des-Etrangers/Naturalisation"},
  "8-3" : {pref : "la sous-préfecture de Saint-Germain-en-Laye.",site_pref :"http://www.yvelines.gouv.fr/Services-de-l-Etat/Sous-prefecture-de-Saint-Germain-en-Laye", site_nat : "http://www.yvelines.gouv.fr/Services-de-l-Etat/Sous-prefecture-de-Saint-Germain-en-Laye/Les-demarches-administratives/Les-ressortissants-etrangers"},
  "8-4" : {pref : "la préfecture de l’Essonne.",site_pref :"http://www.essonne.gouv.fr/", site_nat : "http://www.essonne.gouv.fr/Demarches-administratives/Etrangers"},
  "8-5" : {pref : "la préfecture de Seine-Saint-Denis, à Bobigny ou à la sous-préfecture de Saint-Denis et du Raincy.",site_pref :"http://www.seine-saint-denis.gouv.fr/", site_nat : "http://www.seine-saint-denis.gouv.fr/Demarches-administratives/Etrangers-Vos-demarches/Naturalisation"},
  "8-6" : {pref : "la préfecture du Val d’Oise, à Cergy.",site_pref :"http://www.val-doise.gouv.fr/", site_nat : "http://www.val-doise.gouv.fr/Demarches-administratives/Documents-Etrangers-snow"},
  "8-7" : {pref : "la préfecture des Hauts-de-Seine, à Nanterre.",site_pref :"http://www.hauts-de-seine.gouv.fr/", site_nat : "http://www.hauts-de-seine.gouv.fr/Demarches-administratives/Etrangers-en-France/Naturalisation#!/Particuliers/page/N111"},
  "8-8" : {pref : "la préfecture du Val-de-Marne, à Créteil",site_pref :"http://www.val-de-marne.gouv.fr/", site_nat : "http://www.val-de-marne.gouv.fr/Demarches-administratives/Naturalisation#!/Particuliers/page/N111"},
  "9-1" : {pref : "la préfecture de la Seine-Maritime, à Rouen.",site_pref :"http://www.seine-maritime.gouv.fr/", site_nat : "http://www.seine-maritime.gouv.fr/Demarches-administratives/Etrangers-en-Seine-Maritime/Plate-forme-interdepartementale-de-naturalisation"},
  "9-2" : {pref : "la préfecture du Calvados, à Caen.",site_pref :"http://www.calvados.gouv.fr/", site_nat : "http://www.calvados.gouv.fr/particuliers-accedez-a-toutes-vos-demarches-a3435.html#!/particuliers/page/N111"},
  "10-1" : {pref : "la préfecture de Gironde, à Bordeaux.",site_pref :"http://www.gironde.gouv.fr/", site_nat : "http://www.gironde.gouv.fr/Demarches-administratives/Etrangers/Naturalisations2"},
  "10-2" : {pref : "la préfecture de la Haute-Vienne, à Limoges.",site_pref :"http://www.haute-vienne.gouv.fr/", site_nat : "http://www.haute-vienne.gouv.fr/Demarches-administratives/Naturalisation2#!/particuliers/page/N111"},
  "10-3" : {pref : "la préfecture des Deux-Sèvres, à Niort.",site_pref :"http://www.deux-sevres.gouv.fr/", site_nat : "http://www.deux-sevres.gouv.fr/Demarches-administratives/Naturalisations"},
  "11-1" : {pref : "la préfecture de l’Hérault, à Montpellier.",site_pref :"http://www.herault.gouv.fr/", site_nat : "http://www.herault.gouv.fr/Demarches-administratives/Demarches-etrangers/Naturalisation"},
  "11-2" : {pref : "la préfecture de Haute-Garonne, à Toulouse.",site_pref :"http://www.haute-garonne.gouv.fr/", site_nat : "http://www.haute-garonne.gouv.fr/Demarches-administratives/Immigration-et-integration/Naturalisation"},
  "12-1" : {pref : "la préfecture de la Loire-Atlantique, à Nantes.",site_pref :"http://www.loire-atlantique.gouv.fr/", site_nat : "http://www.loire-atlantique.gouv.fr/Demarches-administratives/Naturalisation"},
  "13-1" : {pref : "la préfecture des Alpes-Maritimes, à Nice.",site_pref :"http://www.alpes-maritimes.gouv.fr/", site_nat : "http://www.alpes-maritimes.gouv.fr/Demarches-administratives/Naturalisation"},
  "13-2" : {pref : "la préfecture des Bouches-du-Rhône, à Marseille.",site_pref :"http://www.bouches-du-rhone.gouv.fr/", site_nat : "http://www.bouches-du-rhone.gouv.fr/Demarches-administratives/Etrangers#!/particuliers/page/N111"},
  "14-1" : {pref : "la sous-préfecture de Pointe-à-Pitre",site_pref :"http://www.guadeloupe.gouv.fr/Rendez-vous-en-ligne/Sous-prefecture-de-Pointe-a-Pitre", site_nat : "http://www.guadeloupe.gouv.fr/Demarches-administratives/Etrangers-en-France2#!/Particuliers/page/N20306"},
  "14-2" : {pref : "la préfecture de Guyane",site_pref :"http://www.guyane.gouv.fr/", site_nat : "http://www.guyane.gouv.fr/Demarches-administratives/Etrangers-en-France/Acces-a-la-nationalite-francaise"},
  "14-3" : {pref : "la préfecture de la Martinique",site_pref :"http://www.martinique.gouv.fr/", site_nat : "http://www.martinique.gouv.fr/Demarches-administratives/Etrangers-en-France2/Etrangers-en-France#!/particuliers/page/N111"},
  "14-4" : {pref : "la préfecture de Mayotte",site_pref :"http://www.mayotte.gouv.fr/", site_nat : "http://www.mayotte.gouv.fr/Demarches-administratives/Naturalisation#!/Particuliers/page/N111"},
  "14-5" : {pref : "la préfecture de la Réunion",site_pref :"http://www.reunion.gouv.fr/", site_nat : "http://www.reunion.gouv.fr/"},
}


export default class DeposerDossierNaturalisation extends Component{
  constructor(props){
    super(props);
    this.state= {
      session : this.props.session,
      step : 1,
    }
  }

  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/constituer-dossier/deposer-dossier-naturalisation');
  }

  handleChange = (newValue: any) => {
      this.setState({
        step2_content : step2_regions[newValue.value],
        step : 2
      })
  }

  handleChangeStep2 = (newValue: any) => {
      this.setState({
        result : newValue.value,
        step : 3,
      })
  }

  showResult(){
    return (
      <div className="deposer-result-content">
        <h6> Vous êtes rattachés à {results[this.state.result].pref} </h6>
        <div className="links">
          <p> <span> Site de la préfecture  </span> <a href={results[this.state.result].site_pref} target="_blank" rel="noopener noreferrer"> Ici </a> </p>
          <p> <span> Lien naturalisation </span> <a href={results[this.state.result].site_nat} target="_blank" rel="noopener noreferrer"> Ici </a> </p>
        </div>
      </div>
    )
  }

  render(){
    return(
      <div className="constituer-main-container procedure">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <link rel="canonical" href={this.props.pageCanonicalLink} />
          <meta name="description" content={this.props.pageDescription} />
      </Helmet>
        <div className="top-image procedure">
            <div className="top-image-title">
              <h1> Choisir la bonne procédure de naturalisation </h1>
            </div>
        </div>

      <div className="constituer-content procedure">

        <div className="constituer-content-header procedure">
        <p> Maintenant que la constitution de votre dossier est faite, vous devez savoir à quelle préfecture vous devez vous adresser. Le rattachement à une préfecture se fait en fonction de votre domiciliation. Avant d’envoyer ou de déposer votre dossier il est important  de consulter le site de votre préfecture de police. </p>
        <p> Tout demandeur de la nationalité française doit savoir auprès de quelle préfecture, ou plate-forme, il doit effectuer ses démarches pour deux raisons :</p>
        <p> <span>- Savoir quelle est la procédure à suivre :</span><br/>
        Même si toutes les personnes demandant la nationalité française doivent constituer un dossier et passer un entretien, la procédure du dépôt de votre dossier est propre à chaque préfecture de police. En consultant le site de votre préfecture de police, vous pouvez de ce fait savoir quelles sont les démarches à suivre tout au long de la procédure. A titre d’exemple, certaines préfectures exigent que le dossier soit envoyé par courrier alors que pour d’autres, le dossier doit être déposé en main-propre.<br/>
        Attention, il en est de même pour votre entretien. </p>
        <p> <span>- Vérifier que votre dossier soit complet:</span><br/>
        Même si vous avez déjà tous les documents nécessaires à la création de votre dossier, il est important de vérifier la liste des documents demandés par votre préfecture. Les sites des préfectures de police sont régulièrement mis à jour. Certaines préfectures peuvent demander un document supplémentaire ou complémentaire à la liste initiale.
        </p>
        </div>



        <div className="constituer-section deposer section-1">
          <div className="constituer-section deposer section-1-container">
            <h4> A quelle préfecture suis-je rattaché(e) ? </h4>
              <div className="option_select">
                <CreatableSelect options={regions} onChange={this.handleChange} placeholder="Région"/>
              </div>
              {this.state.step >= 2?
                <div className="option_select">
                  Vous résidez  : <br/>
                  <CreatableSelect options={this.state.step2_content} onChange={this.handleChangeStep2} placeholder="Département"/>
                </div>
                :
                ""
              }
              {this.state.step >= 3?
                <div className="deposer-result">
                  {this.showResult()}
                </div>
                :
                ""
              }
          </div>
        </div>
      </div>
    </div>

    )}
}
