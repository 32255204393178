import React,{Component} from 'react';
import { Button, Form, FormGroup, Input,Alert } from 'reactstrap';

import './login.css';
import axios from 'axios';

var rem = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+[.][a-zA-Z0-9-.]+$");

export default class ForgotPwdForm extends Component{
  constructor(props) {
    super(props);
    this.state={
      email: "",
      validatedForm : false,
      error : false,
      emailErr : false
    }
    this.validate = this.validate.bind(this);
  }

  handleChange = (e) => {
      this.setState({
            [e.target.name]: e.target.value
        });
    }

  validate(){
    var res = true;

    if(this.state.email == null || this.state.email === "" ){
      this.setState({
        emailErr : true
      })
      res = false;
    }else{
      this.setState({
        emailErr : false
      })
    }

    if(rem.test(this.state.email)){
      this.setState({
        emailErr : false
      })
    }else{
      this.setState({
        emailErr : true
      })
    }

    if(res){
      this.renewPwd();
    }
  }

  renewPwd(){
    var self = this;
    axios({
              method: 'POST',
              url : "https://www.natification.fr/serv/api/client/renew.php",
              headers: {
                'Content-Type': 'application/json'
              },
              data: {
                'email': this.state.email
              }
            })
      .then(function (response) {
        self.setState({
          success: true,
          failed: false,
        })
      })
      .catch(function (error) {
        self.setState({
          failed : true,
          success : false,
        })
      });
  }

  render(){
    return(
      <div className="newPwdform">
        {
          this.state.success?
          <Alert color="success"> Votre demande a bien été enregistrée, verifiez votre boîte mail (ainsi que dans vos spam !)</Alert>
          :""
        }
        {
          this.state.failed?
          <Alert color="danger"> Votre adresse n'a pas été reconnue, veuillez vous <a href="/register"> inscrire </a> ou réessayer.</Alert>
          :""
        }
        {
          this.state.success?
          ""
          :
          <div className="lg-form">
            <p> Veuillez renseigner votre adresse e-mail. Si elle est valide, un e-mail vous sera envoyé avec votre mot de passe temporaire </p>
                <Form >
                    <FormGroup>
                        <FormGroup>
                          <Input type="text" autoComplete="username" className={this.state.emailErr ? 'field-error' : ''} name="email" id="email" placeholder="Email" onChange={this.handleChange} required/>
                        </FormGroup>

                      <Button className="roundblue" onClick={this.validate}>Envoyer</Button>
                    </FormGroup>
                  </Form>
          </div>
        }

      </div>
    )}
}
