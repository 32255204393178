import React, {Component} from 'react';
import axios from 'axios';
import { AreaChart, Area, RadialBarChart, RadialBar, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';

import Cookies from 'universal-cookie';

const cookies = new Cookies();
var sum = 0;
var calmean = 0;
var curr_max = 0;
var radialdata = [];
export default class ScoresHistory extends Component{
  constructor(props){
    super(props);
    this.state = {
      records : null,
    }
    this.setScores = this.setScores.bind(this);
  }

  componentWillReceiveProps(){
      this.retreiveData();
      this.setScores();
  }

  retreiveData(){
    let c_jwt  = cookies.get("jwt").split("=")[0];
    var self = this;
    axios({
              method: 'POST',
              url : "https://www.natification.fr/serv/api/quizz/get_scores.php",
              headers: {
                'Content-Type': 'application/json'
              },
              data: {
                'jwt': c_jwt
              }
            })
      .then(function (response) {
        var records = [];
        response.data.records.forEach(function(item, index, array) {
          records.push({date : new Date(item['datetime'].replace(/-/g,"/")).toLocaleDateString('fr-FR') , score : item['score'] });
        });
        self.setState({
          records : records,
        }
        );

      })
      .catch(function (error) {
        self.setState({
          error : true,
        });
      });
  }

  setScores(){
    if(this.state.records !== null){
      var recs = this.state.records;
      sum = 0;
      calmean = 0;
      curr_max = 0;

      for(var i in recs){
        var score = parseInt(recs[i]["score"], 10);
        sum += score;
        if (score > curr_max){
          curr_max = score
        }
        i += 1;
      }

      calmean = (sum / recs.length ) ;
      radialdata =  [ {name: '', val: 20, fill: 'white'}, {name: 'Score moyen', val: calmean, fill: '#2193b0'} ];
    }

  }


  render(){
    return(
        <div className="history">
          <div>
            <h3> Historique des scores </h3>

            <div className="history-chart" style={{ width: '100%', height: '200px' }}>
              <br/>
              <ResponsiveContainer>
                <AreaChart data={this.state.records} margin={{ top: 5, right: 20, bottom: 5, left: 0 }}>
                  <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                  <XAxis dataKey="date" />
                  <YAxis dataKey="score" domain={[0, 20]}/>
                  <Tooltip />
                  <Area type='monotone' dataKey='score' stroke='#45c4fa' fill='#2193b0' />
                </AreaChart>
              </ResponsiveContainer>
            </div>

            <div className="values">
              <div className="values-left">
              Votre meilleur score : <br/> <h3> {curr_max} </h3>
              </div>
              <br/>
              <div className="values-right" style={{ width: '100%', height: '200px' }}>

              Score moyen : <br/> <h3> {calmean} </h3>
                <ResponsiveContainer >
                <RadialBarChart
                    innerRadius="10%"
                    outerRadius="100%"
                    data={radialdata}
                    startAngle={180}
                    endAngle={0}
                    >
                    <RadialBar minAngle={15} background clockWise={true} dataKey='val' />
                    </RadialBarChart>
                </ResponsiveContainer>
              </div>

            </div>

          </div>
        </div>
    )}
}
