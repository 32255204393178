import React, {Component} from 'react';
import { VerticalTimelineElement }  from 'react-vertical-timeline-component';

export default class HistoryTLComponent extends Component{
  constructor(props){
    super(props);
    this.state={
      date : this.props.date,
      title : this.props.title,
      imgsrc : this.props.imgsrc,
      content : this.props.content,
    }
  }

  render(){
    return(
      <div className="historyTL-component">
        <VerticalTimelineElement
          className="vertical-timeline-element--work"
          date={this.state.date}
          iconStyle={{ background: this.props.iconColor }}
          position={this.props.position}
          >
          <h3 className="vertical-timeline-element-title">{this.state.title}</h3>
          <h4 className="vertical-timeline-element-subtitle">{this.state.subtitle}</h4>
          <p>
            {this.state.content}
          </p>
          {this.state.imgsrc!==""? <img src={require("./img/" +this.props.imgsrc)} alt="tile-img"/>:""}
        </VerticalTimelineElement>
      </div>
  )}
}
