import React, {Component} from 'react';
import { Form, FormGroup, Input, FormText } from 'reactstrap';
import ReactGA from 'react-ga';

var re = new RegExp("(0[6-7])[0-9]{8}$");
var rem = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$");
var rez = new RegExp("20[0-9]{2}X( [0-9]{4,6}|[0-9]{4,6})");

export default class PackageForm extends Component{
  constructor(props){
    super(props);
    this.saveAndContinue = this.saveAndContinue.bind(this);
    this.nextPage = this.nextPage.bind(this);
    this.previousPage = this.previousPage.bind(this);
    this.controlFields = this.controlFields.bind(this);
    this.state = {
            page : 1,
            email : null,
            phone : null,
            emailOn : true,
            phoneOn : true,
            product_choice : 1,

            phoneErr : false,
            emailErr : false,
            product_price : "2,50",

            fname: null,
            lname: null,
            dof: null,
            reze: null,
            fnameErr : false,
            lnameErr : false,
            dofErr : false,
            rezeErr : false,
        };
    this.buttonChange = this.buttonChange.bind(this);
  }
  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/notification/subscribe/packages');
  }
  // FIRST PART
  handleChange = (e) => {
    if(e.target.id==="emailcbox"){
      this.setState({
        emailOn : true,
        phoneOn : false,
      })
    }
    if(e.target.id==="phonecbox"){
      this.setState({
        phoneOn : true,
        emailOn : false,
      })
    }
    if(e.target.id==="bothcbox"){
      this.setState({
        phoneOn : true,
        emailOn : true,
      })
    }

    else{
      this.setState({
          [e.target.name]: e.target.value
      })
    }
    }




  saveAndContinue(e) {
    e.preventDefault();
    this.props.saveValues(this.state);
    this.props.next();
  }

  buttonChange(e){
    e.preventDefault();
    if(e.target.id==="emailcbox"){
      this.setState({
        phoneOn : false,
        emailOn : true,
        product_price : "0,99",
        product_choice : 2,
      })
    }else{
      this.setState({
        phoneOn : true,
        emailOn : true,
        product_price : "2,50",
        product_choice : 1,
      })
    }
  }

  // SECOND PART
  controlFields(e){
    e.preventDefault();
    this.checkReze(this.state.reze);
    if(this.checkOtherFields()){
      this.saveAndContinue(e);
    }

  }

  checkReze(reze){
    var res = rez.test(reze);
    console.log(res);
    if (res){
      this.setState({
        rezeErr : false,
      });
      return true;
    }else{
      this.setState({
        rezeErr : true,
      });
      return false
    }
  }

  checkOtherFields(){
    var res = true;
    var opt = false;

    if(this.state.fname == null || this.state.fname === "") {
      this.setState({
        fnameErr : true
      })
      res = false;
    }else{
      this.setState({
        fnameErr : false
      })
    }
    if(this.state.lname == null || this.state.lname === ""){
      this.setState({
        lnameErr : true
      })
      res = false;
    }else{
      this.setState({
        lnameErr : false
      })
    }
    if(this.state.dof == null || this.state.dof === ""){
      this.setState({
        dofErr : true
      })
      res = false;
    }else{
      this.setState({
        dofErr : false
      })
    }

    if(this.state.emailOn){
      opt = true;
      if(rem.test(this.state.email)){
        this.setState({
          emailErr: false
        })
      }else{
        this.setState({
          emailErr: true
        });
        res = false;
      }
    }else{
      opt = false;
    }

    if(this.state.phoneOn){
      opt = true;
      if(re.test(this.state.phone)){
        this.setState({
          phoneErr: false
        })
      }else{
        this.setState({
          phoneErr: true
        });
        res = false;
      }
    }

    if(opt===false){
      res=false;
    }

    return res;
  }

  nextPage(){
    this.setState({
      page : 2,
    })
  }

  previousPage(){
    this.setState({
      page : 1,
    })
  }



  render(){
    return(
      <div className="hp-form">
            <Form className={this.state.page===1?"visible-form":"visible-form" }>

                <div className="option">
                  <div className="photo">
                  {this.state.product_choice === 1 ?
                    <img src={require("./img/notifications.svg")} alt="img-mailsms"/>
                    :
                    <img src={require("./img/notification.svg")} alt="img-mail"/>
                  }
                  </div>
                  <div className="description">
                      <h3>Notification à l'acquisition de la nationnalité</h3>
                        {this.state.product_choice === 1 ?
                          <h4>Notification SMS & E-mail</h4>
                          :
                          <h4>Notification E-mail</h4>
                        }
                      <h1><img className="img-sec" src={require("./img/secured.png")} alt="img-sec"/><span id="price"> {this.state.product_price} € </span> </h1>

                      <div className="form-title">
                        <p>
                        Vous venez de passer votre entretien de naturalisation ? Découvrez notre service de notification afin d'être informé(e) {this.state.product_choice === 1 ? "par SMS et par email" : "par email"} dès que votre nom apparaîtra dans le décret du journal officiel.
                        <br/>N'attendez plus tous les décrets pour vérifier si vous êtes naturalisé(e).
                        <br/>Renseignez vos informations ci-dessous pour vous inscrire sur notre liste de notification et recevoir une alerte {this.state.product_choice === 1 ? "par SMS et email" : "par email"} dès que vous obtenez votre nationalité Française.</p>
                      </div>

                      <hr/>


                  </div>



                </div>


            </Form>

            <Form className={this.state.page===2?"visible-form":"visible-form" }>
              <FormGroup>
                <FormText>Nom <span>*</span></FormText>
                <Input className={this.state.lnameErr ? 'field-error' : ''} type="text" name="lname" ref="lname" defaultValue={ this.props.fieldValues.lname } onChange={e => this.handleChange(e)}/>
              </FormGroup>

              <FormGroup>
                <FormText>Prénom <span>*</span></FormText>
                <Input type="text" className={this.state.fnameErr ? 'field-error' : ''}name="fname" ref="fname" defaultValue={ this.props.fieldValues.fname }  onChange={e => this.handleChange(e)}/>
              </FormGroup>

              <FormGroup>
                <FormText>Date de naissance <span>*</span></FormText>
                <Input type="date" className={this.state.dofErr ? 'field-error' : ''} name="dof" ref="dof"  pattern="\d{1,2}/\d{1,2}/\d{4}"  defaultValue={ this.props.fieldValues.dof } onChange={e => this.handleChange(e)} />
              </FormGroup>

              <FormGroup>
                <FormText>N° REZE (2018X xxxxx) - optionnel </FormText>
                <Input type="text" className={this.state.rezeErr ? 'field-error' : ''} name="reze" ref="reze" defaultValue={ this.props.fieldValues.reze } onChange={e => this.handleChange(e)} />
              </FormGroup>

              {/*<div className="btn next">
                <Button className="send" onClick={this.controlFields}>Suivant</Button>
              </div>
              <div className="btn back">
                <Button className="send" onClick={this.previousPage}>Retour</Button>
              </div>*/}
              <hr/>

              <div className={this.state.emailOn ? 'divOff' : 'divOff hidden'}>
                <FormGroup>
                  <FormText>Email <span>*</span></FormText>
                  <Input type="text" className={this.state.emailErr ? 'field-error' : ''} name="email" id="email" onChange={e => this.handleChange(e)} />
                </FormGroup>
              </div>

              <div className={this.state.phoneOn ? 'divOff' : 'divOff hidden'}>
                <FormGroup>
                  <FormText>Numéro de téléphone <span>*</span></FormText>
                  <Input type="text" className={this.state.phoneErr ? 'field-error' : ''} name="phone" id="phone" onChange={e => this.handleChange(e)} />
                </FormGroup>
              </div>

          </Form>
          <div className="option">

              <hr/>

              <div className="btn next">

                <a className="send" onClick={this.controlFields} > Poursuivre <i className="fas fa-cart-arrow-down"></i> </a>
              </div>
              <br/>
              <br/>
              <div className="option-choice">
                {this.state.product_choice === 1 ?
                  <a id="emailcbox" onClick={this.buttonChange} > Je ne souhaite recevoir que le mail  </a>
                  :
                  <a id="bothcbox" onClick={this.buttonChange} >
                      Ajouter le SMS
                  </a>
                }
              </div>
          </div>



      </div>



  )}
}


/*

<FormGroup className="packages">

  {/*<FormGroup check>
    <Label check>
      <Input type="radio" id="phonecbox" name="option" onChange={e => this.handleChange(e)}/>{' '}
        <p> Être notifié(e) par SMS </p> <span id="price"> [1,79 €] </span>
    </Label>
  </FormGroup>

  <FormGroup check disabled className="main-option">
    <Label check>
      <Input type="radio" id="bothcbox" name="option" onChange={e => this.handleChange(e)}/>{' '}
      <p>x </p><span id="price"> 2,50 € </span>
    </Label>
  </FormGroup>

  <FormGroup check className="secondary-option">
    <Label check>
      <Input type="radio" id="emailcbox" name="option" onChange={e => this.handleChange(e)}/>{' '}
      <p> Je ne souhaite recevoir que le mail </p>
    </Label>
  </FormGroup>
</FormGroup>



<FormGroup check>
  <Label check>
    <Input type="radio" name="option"  value="3" defaultValue={ this.props.fieldValues.option } onChange={e => this.handleChange(e)}/>{' '}
    Être notifié par mail et/ou SMS et/ou notification mobile - 1.99€
  </Label>
</FormGroup>

*/
