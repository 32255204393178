import React, {Component} from 'react';
import CreatableSelect from 'react-select/creatable';
import {Button} from 'reactstrap';

const options_step1 = [
  { value: 'declaration', label: 'Demande d’acquisition de la nationalité par déclaration' },
  { value: 'decret', label: 'Demande d’acquisition de la nationalité par décret ' },
  { value: 'enfant', label: 'Demander la nationalité française pour un enfant' }
]

const options_step1_1 = [
  { value: 'mariage', label: 'Mariage avec un conjoint français' },
  { value: 'ascendant', label: 'Ascendant d’une personne française' },
  { value: 'frere', label: 'Frère ou sœur d’une personne française' }
]

const options_step1_2 = [
  { value: 'etudiant', label: 'Vous avez obtenu un diplôme après 2 années d’études supérieures dans un établissement français' },
  { value: 'redemandeur', label: 'Vous êtes demandeur de la réintégration de la nationalité française ' },
  { value: 'ressortissant', label: 'Vous êtes ressortissant(e) d’un pays dont l’une des langues officielles est le français' },
  { value: 'refugie', label: 'Vous êtes réfugié(e)' },
  { value: 'servicemil', label: 'Vous avez accompli des services militaires dans l’armée française' },
  { value: 'aucun', label: 'Autre cas' }

]

const options_step1_3 = [
  { value: 'ne', label: 'L’enfant est né en France de parents étrangers' },
  { value: 'adopte', label: 'L’enfant est adopté' },
  { value: 'acc', label: 'L’enfant est recueilli' }
]

const options_step1_3_1 = [
  { value: '1316', label: 'L’enfant a entre 13 et 16 ans' },
  { value: '1618', label: 'L’enfant a entre 16 et 18 ans' },
  { value: '18', label: 'L’enfant a 18 ans' }
]

export default class NatCases extends Component{
  constructor(props){
    super(props);
    this.state= {
      option_step1 : 0,
      option_step2 : 0,
      result_case : 0,
    }
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange = (newValue: any) => {
    if(newValue.value === "declaration"){
      this.setState({
        option_step1 : 1,
        option_step2 : 0,
        result_case : 0,
      })
    }
      if(newValue.value === "mariage"){
        this.setState({
          option_step1 : 1,
          result_case : 1,
        })
      }
      if(newValue.value === "ascendant"){
        this.setState({
          option_step1 : 1,
          result_case : 2,
        })
      }
      if(newValue.value === "frere"){
        this.setState({
          option_step1 : 1,
          result_case : 3,
        })
      }

    if(newValue.value === "decret"){
      this.setState({
        option_step1 : 2,
        option_step2 : 0,
        result_case : 0,
      })
    }
      if(newValue.value === "etudiant"){
        this.setState({
          option_step1 : 2,
          result_case : 4,
        })
      }
      if(newValue.value === "redemandeur" || newValue.value === "ressortissant" || newValue.value === "refugie" || newValue.value === "servicemil"){
        this.setState({
          option_step1 : 2,
          result_case : 5,
        })
      }
      if(newValue.value === "aucun"){
        this.setState({
          option_step1 : 2,
          result_case : 6,
        })
      }

    if(newValue.value === "enfant"){
      this.setState({
        option_step1 : 3,
        option_step2 : 0,
        result_case : 0,
      })
    }
      if(newValue.value === "ne"){
        this.setState({
          option_step1 : 3,
          option_step2 : 1,
          result_case : 0
        })
      }
        if(newValue.value === "1316"){
          this.setState({
            option_step1 : 3,
            option_step2 : 1,
            result_case : 7
          })
        }
        if(newValue.value === "1618"){
          this.setState({
            option_step1 : 3,
            option_step2 : 1,
            result_case : 8
          })
        }
        if(newValue.value === "18"){
          this.setState({
            option_step1 : 3,
            option_step2 : 1,
            result_case : 9
          })
        }
      if(newValue.value === "adopte"){
        this.setState({
          option_step1 : 3,
          result_case : 10,
          option_step2 : 0,
        })
      }
      if(newValue.value === "acc"){
        this.setState({
          option_step1 : 3,
          result_case : 11,
          option_step2 : 0,
        })
      }
  };


  render(){
    return(
      <div>
        <div className="option_select">
          <CreatableSelect options={options_step1} onChange={this.handleChange}/>
        </div>
        {this.state.option_step1 !== 0?
          <div>
          {this.state.option_step1 === 1?
            <div className="option_select">
              <CreatableSelect options={options_step1_1} onChange={this.handleChange}/>
            </div>
            :
            ""
          }
          {this.state.option_step1 === 2?
            <div className="option_select">
              <CreatableSelect options={options_step1_2} onChange={this.handleChange}/>
            </div>
            :
            ""
          }
          {this.state.option_step1 === 3?
            <div className="option_select">
              <CreatableSelect options={options_step1_3} onChange={this.handleChange}/>
            </div>
            :
            ""
          }
          </div>
        :
        ""
        }
        {this.state.option_step2 !==0?
          <div className="option_select">
            <CreatableSelect options={options_step1_3_1} onChange={this.handleChange}/>
          </div>
          :
          ""
        }
        {this.state.result_case !==0?
          <div className="conditions-result">

            {this.props.showResult(this.state.result_case)}
            {this.state.result_case === 4 || this.state.result_case === 5 || this.state.result_case ===  6?
              <p className="attention"> Attention : <br/><span>La nationalité par décret n’est pas un droit ! </span><br/>Même si vous remplissez toutes les conditions, un refus est toujours possible avec <span>un ajournement de 2 ans</span> pour toute nouvelle demande. </p>

              :
              ""
            }
            <Button outline onClick={this.print}> <i class="fas fa-print"></i> Imprimer  </Button>
          </div>
          :
          ""
        }
      </div>
    )
  }
}
