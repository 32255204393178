import React, {Component} from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import './livret.css';
import {QCMReminder} from '../QCMReminder/qcm-reminder';
import LivretChapitresMenu from './MenuChapitres/Chapitres';
import PersonnalitesCards from './LivretCards/PersonnalitesCards';
import {Helmet} from "react-helmet";

export default class LivretDuCitoyenPersonnalites extends Component{
  constructor(props){
    super(props);

    this.state= {
      session : this.props.session
    }
  }
  render(){
    return(
      <div className="livret-main-container">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <link rel="canonical" href={this.props.pageCanonicalLink} />
          <meta name="description" content={this.props.pageDescription} />
      </Helmet>
        <Breadcrumb>
          <BreadcrumbItem><a href="/preparer-entretien">Préparer mon entretien</a></BreadcrumbItem>
          <BreadcrumbItem><a href="/preparer-entretien/culture-generale">Culture générale</a></BreadcrumbItem>
          <BreadcrumbItem active>Eux aussi ont été naturalisés</BreadcrumbItem>
        </Breadcrumb>
        <div className="top-image livret">
          <div className="top-image-title">
            <h2> Préparer mon entretien : <span>Eux aussi ont été naturalisés</span></h2>
          </div>
        </div>

        {
          this.props.session?
        <div className="livret-content">
        <LivretChapitresMenu/>


          <div className="livret-content-header">
            <p> Tout au long de son histoire, la France a connu plusieurs personnalités qui ont fait le choix de vivre en France et de devenir français par naturalisation.
             Ces personnes ont contribué au rayonnement de la France à travers leur savoir-faire. </p>
          </div>


          <div className="livret-section section-1">
            <div className="personaliteCards">
              <div className="message">
              Sorry, your browser does not support CSS Grid.
              </div>

              <section className="section">
                <div className="grid">

                  <PersonnalitesCards
                    bg="url(https://histoireparlesfemmes.files.wordpress.com/2012/12/marie-curie01.jpg)"
                    size="large"
                    name="Marie Curie (1867 - 1934)"
                    detail= "Physicienne d'origine polonaise, Marie Curie est la première femme à recevoir le prix Nobel de physique grâce à ses recherches sur l'atome et les substances radioactives. Scientifique marquante de l'histoire de France, elle est inhumée au Panthéon"
                  />

                  <PersonnalitesCards
                    bg="url(https://img-3.journaldesfemmes.fr/6yWCxAlL5NDg7WXhfsVocFGu5A4=/819x546/smart/b26f0e01a12541ee8b3401892d3438ab/ccmcms-jdf/10673216.jpg)"
                    size="medium"
                    name="Carla Bruni-Sarkozy (1967 - )"
                    detail="D'origine italienne, le mannequin et auteure-compositrice-interprète ne devient française qu'après son mariage en 2008, avec le président Nicolas Sarkozy."
                  />

                  <PersonnalitesCards
                    bg="url(https://resize-parismatch.lanmedia.fr/r/625,417,center-middle,ffffff/img/var/news/storage/images/paris-match/people/romy-schneider-sa-relation-difficile-avec-son-fils-david-quelques-jours-avant-sa-mort-1527890/24910280-1-fre-FR/Romy-Schneider-sa-relation-difficile-avec-son-fils-David-quelques-jours-avant-sa-mort.jpg)"
                    size="medium"
                    name="Romy Schneider (1938 - 1982)"
                    detail="Icône du septième art, Romy Schneider est une actrice allemande née en Autriche. Connue entre autre pour son rôle de « Sissi l'impératrice » Son amour pour la France est né grâce à son idylle avec Alain Delon."
                  />


                  <PersonnalitesCards
                    bg="url(https://file1.telestar.fr/var/telestar/storage/images/2/2/2/3/2223544/dalida-retour-sur-destin-tragique-chanteuse-photos.jpg)"
                    size="large"
                    name="Dalida (1933 - 1987)"
                    detail="Issue d'une famille italienne installée en Egypte, Dalida est une chanteuse connue et reconnue au niveau international. C'est en France, son pays d'adoption qu'elle lance sa carrière de chanteuse. Parmi ses nombreuses chansons à succès on retrouve « Laissez-moi danser »  ou encore « Paroles, paroles » "
                  />

                  <PersonnalitesCards
                    bg="url(https://www.lopinion.fr/sites/nb.com/files/styles/w_1000/public/styles/paysage/public/images/2019/05/valls-emmanuel-barcelone-campagne.jpg?itok=b9RW_7tc)"
                    size="medium"
                    name="Manuel Valls (1962 - )"
                    detail="Issu d'une famille catalane, Manuel Valls est le premier Premier Ministre à être français par naturalisation. Figure emblématique de la politique française, il était auparavant maire d’Évry puis député dans l'Essonne."
                  />

                  <PersonnalitesCards
                    bg="url(https://cdn.radiofrance.fr/s3/cruiser-production/2018/03/1321fd62-e047-43a2-aa4b-2df75f4e1b84/838_apollinaire.jpg)"
                    size="medium"
                    name="Guillaume Apollinaire (1880 - 1918)"
                    detail="Poète d'origine polonaise (sujet de l’Empire russe) il demande à être naturalisé après avoir intégré l'armée française lors de la 1ère Guerre Mondiale. Il est à l'origine d'œuvres telles que Calligrammes ou encore le Pont Mirabeau"
                  />

                  <PersonnalitesCards
                    bg="url(https://static.lpnt.fr/images/2018/02/07/13206474lpw-13215104-article-jpg_4981780_660x281.jpg)"
                    size="large"
                    name="Emile Zola (1840 - 1902)"
                    detail="Emile Zola est l'un des auteurs français les plus populaires. D'origine italienne, l'écrivain de « Germinal », « l'Assommoir » ou encore « Au Bonheur des Dames » est naturalisé à l'âge de 22 ans."
                  />

                  <PersonnalitesCards
                    bg="url(http://techniquedepeinture.com/wp-custom/uploads/2014/03/kandinsky-portrait.jpg)"
                    size="medium"
                    name="Vassily Kandinsky (1866 - 1944)"
                    detail="D'origine russe, le peintre Vassily Kandinsky a marqué le 20ème siècle grâce à son art. Pionnier dans l'art abstrait, il est naturalisé allemand puis français en 1939"
                  />

                  <PersonnalitesCards
                    bg="url(https://media.ouest-france.fr/v1/pictures/2d9199a2e76c16bbf095fba4c582713a-nba-tony-parker-pour-moi-c-est-comme-fermer-le-chapitre-basket-en-jouant-pour-michael-jordan.jpg?width=1260&height=712&focuspoint=50%2C25&cropresize=1&client_id=cmsfront&sign=e7d49cc6b821d4ccd9fdc5d490e7d221281e0fddd2cfa6edaeab7aab55759b1d)"
                    size="medium"
                    name="Tony Parker (1982 - )"
                    detail="Fils d’un basketteur américain et d’un mannequin néerlandais, Tony Parker est le basketteur français le plus célèbre au monde. Naturalisé à l'âge de 15 ans, ce sportif de haut niveau joue en NBA, la célèbre ligue américaine.."
                  />

                  <PersonnalitesCards
                    bg="url(https://p4.storage.canalblog.com/43/17/1555892/118546388.jpg)"
                    size="medium"
                    name="Milan Kundera (1929 - )"
                    detail="Déchu de sa nationalité, cet écrivain d’origine tchèque émigre en France et devient citoyen français. Grâce à ses nombreuses œuvres, le célèbre auteur se voit récompenser de plusieurs grands prix de littérature dont celui de l’Académie Française."
                  />

                  <PersonnalitesCards
                    bg="url(https://www.babelio.com/users/AVT_Gao-Xingjian_610.jpeg)"
                    size="medium"
                    name="Gao Xingjian (1940 - )"
                    detail="Célèbre écrivain, dramaturge, metteur en scène et peintre, Gao Xingjian est d’origine chinoise. Censuré dans son pays et contraint à l'exil, il parvient à obtenir l'asile politique en France puis la nationalité française. En 2000, il reçoit le Prix Nobel de littérature."
                  />

                  <PersonnalitesCards
                    bg="url(https://static.euronews.com/articles/stories/03/70/35/46/880x495_cmsv2_9b6514e9-7037-5454-9f32-b6aceb52a2ea-3703546.jpg)"
                    size="medium"
                    name="Carlos Ghosn (1954 - )"
                    detail="D’origine libanaise, Carlos Ghosn est né au Brésil. Il s’installe en France dans le cadre de ses études. Diplômé de l'École des mines de Paris, il obtient la naturalisation française. Carlos Ghosn devient le Président-Directeur Général de Renault-Nissan."
                  />

                  <PersonnalitesCards
                    bg="url(https://www.olyrix.com/files/picture/photos/ArtisteIdentity/503/jacques-offenbach.jpg)"
                    size="medium"
                    name="Jacques Offenbach (1819 - 1880)"
                    detail="D’origine allemande, Jacques Offenbach est un célèbre compositeur et violoncelliste. Proposant une musique originale et variée, ce virtuose du violoncelle est à l’origine de plusieurs grands chefs-d’œuvre tels que «Les Contes d'Hoffmann » ou encore « l'Orphée aux Enfers »"
                  />

                  <PersonnalitesCards
                    bg="url(https://img.bfmtv.com/c/1000/600/8f9/61b7febc0d332c9f34880eff04e26.jpeg)"
                    size="medium"
                    name="Albert Uderzo (1927 - )"
                    detail="Célèbre auteur de bande dessinée d’origine italienne, Albert Uderzo est né en France  et obtient la naturalité française à l’âge de sept ans. Il doit sa forte notoriété à sa plus grande création : Asterix "
                  />

                  <PersonnalitesCards
                    bg="url(https://img-4.linternaute.com/b7uO-DJHwEoTVW1YgCN0iyCkIHI=/1240x/smart/c542343ffdea4eb389a3bfc55c7b9d0e/ccmcms-linternaute/11008364.jpg)"
                    size="large"
                    name="Cristina Cordula (1964 - )"
                    detail="Mannequin, conseillère en image et présentatrice de télévision, Cristina Cordula est d’origine brésilienne. Star du petit écran, l’animatrice des « Reines du shopping » est française (depuis décembre 2018) après 30 années passées en France."
                  />


                  <PersonnalitesCards
                    bg="url(https://image-api.nrj.fr/http/media.nrj.fr/436x327/josephine-baker-jpg_236990.jpg?referer=Nostalgie)"
                    size="medium"
                    name="Josephine Baker (1906 - 1975)"
                    detail="D'origine américaine, Josephine Baker est une chanteuse, danseuse, actrice, meneuse de revue. En plus d'être considérée comme la première célébrité noire, elle représente également l'opposition lors de la 2ème Guerre Mondiale"
                  />

                  <PersonnalitesCards
                    bg="url(http://www.lataille.fr/wp-content/uploads/2017/02/Eug%C3%A8ne-Ionesco.jpg)"
                    size="medium"
                    name="Eugène Ionesco (1909 - 1994)"
                    detail="Né d’un père roumain et d’une mère française, Eugène Ionesco est un écrivain dramatique naturalisé et membre de l'Académie Française. Etant l’un des pionniers du «théâtre de l'absurde», il est l’auteur de plusieurs œuvres dont «La Cantatrice chauve», «Rhinocéros» ou encore «Macbett»."
                  />

                  <PersonnalitesCards
                    bg="url(https://ds1.static.rtbf.be/image/media/object/default/16x9/1248x702/0/8/8/088229e4b40199344ba34ff316f83b0c.jpg)"
                    size="medium"
                    name="Virginie Efira (1977 - )"
                    detail="D’origine belge, Virginie Efira est aujourd’hui une animatrice et actrice Belgo-Française. Elle est connue en tant qu’ancienne présentatrice de la « Nouvelle Star »  ou encore en tant comédienne dans divers films tels que « 20 ans d’écart » ou « un homme à la hauteur »."
                  />



                {/*  <PersonnalitesCards
                    bg="url()"
                    size=""
                    name=""
                    detail=""
                  />

                  <PersonnalitesCards
                    bg="url()"
                    size=""
                    name=""
                    detail=""
                  />

                  <PersonnalitesCards
                    bg="url()"
                    size=""
                    name=""
                    detail=""
                  />*/}



                  </div>
              </section>
            </div>

            <div className="qcm-reminder">
              <QCMReminder/>
            </div>
          </div>


          <div className="livret-section section-end">
              <span> Chapitre précédent :</span>
              <span> </span>
              <span> </span>
             <div className="chap-prev"> <a href="/preparer-entretien/culture-generale-part6"><i className="fas fa-arrow-left"></i> La France en chiffres </a></div>
             <br/>
             <div className="chap-next"> </div>
          </div>

        </div>
        :
        <div className="offline-content livret">
          <h2> Veuillez vous connecter </h2>
          <p> Cette section n'est disponible que pour les membres du site </p>
          <a href="/login"> Me connecter </a> ou <a href="/register"> Créer un compte </a>
        </div>
        }
      </div>


  )}
}
