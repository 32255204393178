import React, {Component} from 'react';
import './constituerDossier.css';
import {QCMReminder} from '../PreparerEntretien/Revision/QCMReminder/qcm-reminder';
import ReactGA from 'react-ga';
import {Helmet} from "react-helmet";

export default class ConstituerDossier extends Component{
  constructor(props){
    super(props);
    this.state= {
      session : this.props.session,
    }
  }

  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/constituer-dossier');
  }


  render(){
    return(
      <div className="constituer-main-container home">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <link rel="canonical" href={this.props.pageCanonicalLink} />
          <meta name="description" content={this.props.pageDescription} />
      </Helmet>
        <div className="top-image constituer">
            <div className="top-image-title">
              <h1> Constitution du dossier de naturalisation </h1>
            </div>
        </div>

      <div className="constituer-content">

        <div className="constituer-content-header">

          <p>Vous désirez devenir citoyen français? Vous adhérez aux principes de la République française ?  <br/>
          Ce site est fait pour vous !</p>
          <p> La citoyenneté française est liée à la détention de la nationalité française. </p>
          <p> Si pour certaines personnes la nationalité s’acquiert par le droit du sang, pour d’autres une procédure administrative est obligatoire.</p>
          <br/>
          {/*
          <p> Il existe trois étapes clés dans la procédure de naturalisation : </p>
          <ol >
              <li className="list-secondary"> La constitution du dossier </li>
              <a href="/preparer-entretien"><li className="list-secondary"> La préparation et le déroulement de son entretien <i className="fas fa-arrow-right"/></li></a>
               <a href="/notification"><li className="list-secondary"> La notification de l’obtention de la nationalité <i className="fas fa-arrow-right"/> </li></a>
          </ol>
          <br/>*/}
          <p> Cette rubrique a pour objectif de vous aider en définissant, en fonction de votre situation, les démarches à adopter pour la constitution de votre dossier. </p>
          <p> Toutes les étapes : </p>

          <div className="chapitres">
            <ul>
              <a href="/constituer-dossier/procedure-de-naturalisation">
              <li>
                <p>Étape 1</p>
                <h4>Choisir sa procédure de naturalisation <i className="fas fa-angle-right"></i> </h4>
              </li>
              </a>

              <a href="/constituer-dossier/conditions-naturalisation">
              <li>
                <p>Étape 2</p>
                <h4>Vérifier les conditions à remplir <i className="fas fa-angle-right"></i> </h4>
              </li>
              </a>

              <a href="/constituer-dossier/preparer-dossier-naturalisation">
              <li>
                <p>Étape 3</p>
                <h4>Composer son dossier de naturalisation <i className="fas fa-angle-right"></i> </h4>
              </li>
              </a>

              <a href="/constituer-dossier/deposer-dossier-naturalisation">
              <li>
                <p>Étape 4</p>
                <h4>Déposer son dossier <i className="fas fa-angle-right"></i> </h4>
              </li>
              </a>

              <a href="/constituer-dossier/instruction-dossier-naturalisation">
              <li>
                <p>Étape 5</p>
                <h4>Instruction du dossier <i className="fas fa-angle-right"></i>  </h4>
              </li>
              </a>

              <a href="/constituer-dossier/decision-naturalisation">
              <li>
                <p>Étape 6</p>
                <h4>Décision Ministérielle <i className="fas fa-angle-right"></i>  </h4>
              </li>
              </a>


            </ul>
          </div>

        </div>


        <div className="qcm-reminder">
          <QCMReminder/>
        </div>



        <div className="constituer-section section-1">
          <div className="constituer-section section-1-container">
          </div>
        </div>
      </div>
    </div>

    )}
}
