import React from 'react';
import './style.css';

export const Footer = (props)=>{
  return <footer>
    <div className="footer-container">
      <div className="footer-c-right"><span></span></div>
      <div className="footer-c-center">
        <p>  <a href="mailto:contact@natification.fr"> Nous contacter </a>  </p>
        <p>  <a href="/mentions-legales">Mentions Légales</a> | <a href="/terms/cgu">Conditions Générales</a> | <a href="/terms/crvp">Vie privée</a>  </p>
        <p>NATIFICATION &copy; 2019 marque déposée <br/>SAS ENAGE &reg; 75015 PARIS <br/> Tous droits réservés.</p>
      </div>

    </div>
    </footer>
};

/*
<p> <a href="/Privacy">Privacy</a> | <a href="/Terms">Terms</a> |  <a href="/FAQ">FAQ</a> </p>

<div className="footer-c-left">
  <p><i className="far fa-envelope"></i> Nous Contacter </p>
  <a href="mailto:contact@enage.fr">contact@enage.fr</a>
</div>
*/
