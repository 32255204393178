import React,{Component} from 'react';
import './home.css'
import {Card,CardBody,CardTitle,CardImg,CardText,Button,Form,FormGroup,Input, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import TestimonialsCarrousel from './Carrousel';
//import {Spinner} from 'reactstrap';
import {Helmet} from 'react-helmet';

import axios from 'axios';
import ReactGA from 'react-ga';

var rem = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+[.][a-zA-Z0-9-.]+$");
var mainbutton;

export default class Home extends Component{
  constructor(props){
    super(props);
    this.state={
      valid: false,
      contactMailError: false,
      modal: false,
      spinon : true,
    }
    this.sendContactMessage = this.sendContactMessage.bind(this);
    this.handleContactFieldChange = this.handleContactFieldChange.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.handleButtons = this.handleButtons.bind(this);
    //this.cycleBackground = this.cycleBackground.bind(this);
  }
  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/');
    //this.cycleBackground();
  }

  /*cycleBackground(){
    var index = 1 ;
    var slides = 4;
    const self = this;
    setInterval(function () {
        index = index + 1 < slides+1 ? index + 1 : 1;
        self.setState({
          bgactive : index
        });
        if (index === 2){
          self.setState({
            spinon : false
          })
        }

    }, 5000);
  }*/

  toggleModal() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  handleContactFieldChange = (e) => {
      this.setState({
            [e.target.name]: e.target.value
        });
    }

  sendContactMessage(){
    if(rem.test(this.state.email)){
      this.setState({
        contactMailError : false
      })
    }else{
      this.setState({
        contactMailError : true
      });
      return 0;
    }
    if(this.state.message.length < 35){
      console.log("message too short");
      return 0;
    }
    var self = this;
    axios({
              method: 'POST',
              url : "https://www.natification.fr/serv/contact/contact.php",
              headers: {
                'Content-Type': 'application/json'
              },
              data: {
                'email': this.state.email,
                'message': this.state.message,
              }
            })
      .then(function (response) {
        self.state.email = "";
        self.state.message = "";
        self.toggleModal();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  handleButtons(e){
    console.log(e.target.name);
    switch (e.target.name) {
      case "btn-regi":
        if (this.props.session){
          window.open('/etapes',"_self");
        }else{
          window.open('/register',"_self");
        }
        break;
      case "btn-prepa":
        window.open('/preparer-entretien',"_self");
        break
      case "btn-consti":
        window.open('/constituer-dossier',"_self");
        break;
      case "btn-notif":
        window.open('/notification',"_self");
        break;
      case "btn-contactus":
        window.open('mailto:contact@natification.fr');
        break;
      default:

    }

  }

  render(){
    if (this.props.session){
      mainbutton = <button name="btn-regi" onClick={this.handleButtons}>Commencer !</button>
    } else{
      mainbutton = <button name="btn-regi" onClick={this.handleButtons}>M'inscrire !</button>
    }
    return(
    <div className="home-page-content">
    <Helmet>
        <meta charSet="utf-8" />
        <title>{this.props.pageTitle}</title>
        <link rel="canonical" href={this.props.pageCanonicalLink} />
        <meta name="description" content={this.props.pageDescription} />
    </Helmet>
      <div className="home-container">
        <div className="headerSection">
          {/*<div className={this.state.spinon? "spinner-header":"spinner-header hidden"}>
            <Spinner color="primary" />
          </div>*/}
          <div className="header">
            {/*<div className={this.state.bgactive === 1? 'slide show1 show': 'slide show1'}></div>
            <div className={this.state.bgactive === 2? 'slide show2 show': 'slide show2'}></div>
            <div className={this.state.bgactive === 3? 'slide show3 show': 'slide show3'}></div>
            <div className={this.state.bgactive === 4? 'slide show4 show': 'slide show4'}></div>*/}
            <div className='slide show1 show'></div>
            <div className="header-left">
              <div className="Iam">
                <h1>Tout pour la naturalisation française </h1>
                <b>
                <div className="innerIam">
                  Constitution du dossier<br />
                  Préparation de l'entretien<br />
                  Quizz d'entrainement<br />
                  Notification à l'acquisition<br />
                  Et plus encore !
                  </div>
                </b>

              </div>
              <div className="cta-button">
                {mainbutton}
              </div>
            </div>
            <div className="header-right">
            </div>
          </div>

        </div>

        <div className="section section1">
          <div className="section1-container">
            <h2>
              <span>Enfin</span> un service pour vous accompagner
              <br/> dans votre démarche de naturalisation
              <br/><a href="#down"><i className="fas fa-angle-down flashit"></i></a>
            </h2>
          </div>
        </div>

        <section className="down" id="down">
          <svg viewBox="0 0 1000 50" >
           <path fill="#66afda20" fillRule="nonzero" d="m 0 50 q 500 -50 1000 0 z"/>
          </svg>
        </section>


        <div className="section section2">
          <div className="section2-container">
            <h2>Comment ça marche ?</h2>
            <br/>
            <div className="howto-div">

              <Card>
                <CardBody>
                  <CardImg top width="100%" src={require("./img/man.svg")} alt="logo-1" />
                  <br/><br/>
                  <CardTitle><a href="https://www.natification.fr/register"><span>Créez votre profil</span></a></CardTitle>
                  <CardText><span>Accédez à tous nos services et aux fiches de révision.</span><br/><br/>Retrouvez tout ce dont vous avez besoin : complétez votre profil pour composer votre dossier, préparez vous à l'entretien, suivez votre progression dans le quizz et bien plus encore.</CardText>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardImg top width="100%" src={require("./img/folder.svg")} alt="logo-2" />
                  <br/><br/>
                  <CardTitle><a href="https://www.natification.fr/constituer-dossier"><span>Constituez votre dossier</span></a></CardTitle>
                  <CardText><span>Réduisez les risques de demande de complément à votre dossier.</span><br/><br/>Plus besoin de chercher partout : notre moteur de composition personnalisé vous donne la liste de documents à fournir. Il est constamment mis à jour pour vous fournir les dernières exigences de l'administration</CardText>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardImg top width="100%" src={require("./img/file.svg")} alt="logo-3" />
                  <br/><br/>
                  <CardTitle><a href="https://www.natification.fr/preparer-entretien"><span>Préparez votre entretien</span></a></CardTitle>
                  <CardText><span>Tout ce qu'il faut savoir pour l'entretien enfin réuni sur un site.</span><br/><br/>Plus besoin de parcourir tous les sites à la recherche des questions. Nous le faisons pour vous. Explorez nos fiches synthèse et devenez un champion de la culture Française grâce à notre quizz dynamique.</CardText>
                </CardBody>
              </Card>

              <Card>
                <CardBody>
                  <CardImg top width="100%" src={require("./img/phone.svg")} alt="logo-4" />
                  <br/><br/>
                  <CardTitle><a href="https://www.natification.fr/notification"><span>Soyez notifié une fois naturalisé</span></a></CardTitle>
                  <CardText><span>Plus besoin de vous connecter à chaque nouveau décret.</span><br/><br/>Inscrivez-vous sur NATIFICATION et soyez directement informés par mail ou par SMS dès que votre nom apparaît sur un décret.</CardText>
                </CardBody>
              </Card>
            </div>
            <br/>
            <p> Pas encore inscrit(e) ? </p>
            <button name="btn-regi" onClick={this.handleButtons}>Je crée mon profil !</button>
          </div>
        </div>

        <div className="section section2 part2">
          <div className="section2-container">
            <h2> La réponse à toutes vos questions </h2>
            <div className="home-services-list">
              <div className="home-service">
                <button name="btn-consti" onClick={this.handleButtons}>Constitution du dossier</button>
                <ul>
                  <li><a href="https://www.natification.fr/constituer-dossier/procedure-de-naturalisation"> Quelle procédure de naturalisation ? </a></li>
                  <li><a href="https://www.natification.fr/constituer-dossier/conditions-naturalisation"> Quelles sont les conditions ? </a></li>
                  <li><a href="https://www.natification.fr/constituer-dossier/preparer-dossier-naturalisation"> Quel document pour mon dossier ? </a></li>
                  <li><a href="https://www.natification.fr/constituer-dossier/deposer-dossier-naturalisation"> Où déposer mon dossier ? </a></li>
              </ul>
              </div>
              <div className="home-service">
                <button name="btn-prepa" onClick={this.handleButtons}>Préparation de l'entretien</button>
                <ul>
                  <li><a href="https://www.natification.fr/preparer-entretien/deroulement"> Comment se déroule l'entretien ? </a></li>
                  <li><a href="https://www.natification.fr/preparer-entretien/culture-generale"> Que réviser pour l'entretien ? </a></li>
                  <li><a href="https://www.natification.fr/preparer-entretien/charte-des-droits-et-devoirs"> Quels seront mes droits de citoyen ? </a></li>
                  <li><a href="https://www.natification.fr/preparer-entretien/questions-personnelles"> C'est quoi les "questions ouvertes" ? </a></li>
              </ul>
              </div>
              <div className="home-service">
                <button name="btn-notif" onClick={this.handleButtons}>Être notifié de ma naturalisation</button>
                <ul>
                  {/*<li><a href="https://www.natification.fr/notification"> Par où passe mon dossier ? </a></li>
                  <li><a href="https://www.natification.fr/notification"> Comment retrouver les décrets ? </a></li>*/}
                  <li><a href="https://www.natification.fr/notification"> Suis-je naturalisé ? </a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="section section3">
          <div className="section3-container">
            <div className="section3-title">
              <h2> Un service créé sur mesure pour vous </h2>
            </div>
            <TestimonialsCarrousel/>
          </div>
        </div>

        <hr/>

        <div className="section section4">
          <div className="section4-container">
            <p> Vous souhaitez </p>
            <div className="section4-buttons">
              <button name="btn-consti" onClick={this.handleButtons}>Constituer mon dossier</button>
              <button name="btn-prepa" onClick={this.handleButtons}>Préparer mon entretien</button>
              <button name="btn-notif" onClick={this.handleButtons}>Être notifié de ma naturalisation</button>
            </div>
          </div>
        </div>

        <div>
          <h2>On <i className="fa fa-heart"></i> aussi les réseaux sociaux !</h2>
          <p> Rejoignez nos communauté </p>
          <div className="socialLogos">
            <a href="https://www.facebook.com/natification.fr/"  rel="noopener noreferrer" target="_blank">
              <i className="fab fa-facebook-square"></i>
            </a>
            <a href="https://www.instagram.com/natification.fr/" rel="noopener noreferrer" target="_blank">
              <i className="fab fa-instagram"></i>
            </a>
          </div>

        </div>

        <div className="section sectionContact" id="nous-contacter">
          <div className="sectionContact-container">
          <hr/>
            <h4><a href="mailto:contact@natification.fr" target="_blank" rel="noopener noreferrer"> Nous Contacter</a></h4>
          </div>
        </div>
      </div>
    </div>
  )}
}

/*//<Form className="contact-form">
  //<FormGroup>
    //  <FormGroup>
      //  <Input type="text" className={this.state.contactMailError ? 'field-error' : ''} value={this.state.email}  name="email" id="email" placeholder="Votre email" onChange={this.handleContactFieldChange} required/>
      //</FormGroup>

      //<FormGroup>
      //  <Input type="textarea" className="contact-message" name="message" id="message" value={this.state.message}  placeholder="Votre message (min. 100 caractères)" onChange={this.handleContactFieldChange} required/>
      //  {this.state.message? <p>{this.state.message.length} caractères</p> : <p>0 caractères</p>  }
      //</FormGroup>

  //  <Button className="send" onClick={this.sendContactMessage}>Envoyer</Button>
  //</FormGroup>
//</Form>

//<Modal isOpen={this.state.modal} toggle={this.toggleModal} className={this.props.className}>
//  <ModalHeader toggle={this.toggleModal}>Merci pour votre message</ModalHeader>
//  <ModalBody>
//    Nous reviendrons vers vous dès que possible afin d'y apporter une réponse.<br/><br/>
//    Cordialement,<br/>
//    L'équipe Natification
//  </ModalBody>
//  <ModalFooter>
//    <Button color="primary" onClick={this.toggleModal}>Quitter</Button>{' '}
//  </ModalFooter>
//</Modal>
*/
