import React, {Component} from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import './deroulement.css'
import {QCMReminder} from '../QCMReminder/qcm-reminder';
import ReactGA from 'react-ga';
import {Helmet} from "react-helmet";

export default class Deroulement extends Component{
  constructor(props){
    super(props);

    this.state= {
      session : this.props.session,
    }
  }

  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/preparer-entretien/deroulement');
  }


  render(){
    return(
      <div className="deroulement-main-container deroulement">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <link rel="canonical" href={this.props.pageCanonicalLink} />
          <meta name="description" content={this.props.pageDescription} />
      </Helmet>
      <Breadcrumb>
        <BreadcrumbItem><a href="/preparer-entretien">Préparer mon entretien</a></BreadcrumbItem>
        <BreadcrumbItem active>Déroulement</BreadcrumbItem>
      </Breadcrumb>
        <div className="top-image deroulement">
            <div className="top-image-title">
              <h1> Préparer mon entretien : <span>Déroulement de l'entretien </span></h1>
            </div>
        </div>
        {
          this.props.session?
          <div className="deroulement-content">

            <div className="deroulement-content-header">

              <p>Vouloir devenir Français est la volonté d’adopter l’histoire, les principes et les valeurs de la France. <br/>
              Acquérir la nationalité française n’est pas qu’une simple démarche administrative, c’est devenir citoyen de la République !</p>
              <p>Une fois que votre dossier est validé par la préfecture de police, vous allez recevoir une convocation pour votre « entretien de naturalisation ».
              <br/> Un entretien de naturalisation ça se PREPARE ! </p>

            </div>

            <div className="qcm-reminder">
              <QCMReminder/>
            </div>



            <div className="deroulement-section section-1">
              <div className="deroulement-section section-1-container">



                <div className="deroulement-section section-1-right">
                  <h2> L’entretien se déroule en plusieurs étapes </h2>
                  <h3> 1. Mise à jour de votre dossier </h3>
                  <p>Lors de la réception de votre convocation, vous savez quel est le lieu et la date de votre entretien mais également les papiers supplémentaires à fournir.<br/> Votre interlocuteur débutera probablement votre entretien en vous demandant de lui remettre les documents additionnels demandés lors de votre convocation. </p>

                  <h3> 2. Lecture et signature de la « Charte Des Droits Et Devoirs Du Citoyen Français »</h3>
                  <p> En signant ce document vous confirmez la bonne compréhension des droits et devoirs de tout citoyen français, ainsi que la loyauté que chacun doit à la République française.Vous pouvez consulter la charte en cliquant<a href="/preparer-entretien/charte-des-droits-et-devoirs"> ici </a> </p>
                </div>

                <div className="deroulement-section section-1-top-left">
                </div>


                <div className="deroulement-section section-1-bottom-right">
                </div>

                <div className="deroulement-section section-1-bottom-left">
                  <h3> 3. Présentation personnelle </h3>
                  <p>L’agent commence toujours pas vous poser des questions sur vous. L’objectif est de jauger votre niveau de langue mais également de connaître vos motivations.<br/> Vous pouvez vous préparer en consultant ici la liste des questions que vous pouvez avoir</p>

                  <h3> 4. Les questions / réponses </h3>
                  <p> C’est généralement la partie de l’entretien que vous appréhendez le plus. <br/>
                  L’agent va vous poser une série de questions afin d’évaluer vos connaissances concernant l’histoire, la culture, la politique et l’actualité française.Vous pouvez vou spréparer en révisant votre <a href="/preparer-entretien/culture-generale"> culture générale </a> et en vous entraînant <a href="/quizz"> ici </a></p>
                </div>

              </div>

              <div className="deroulement-section section-end">
                 Chapitre suivant :<a href="/preparer-entretien/culture-generale"> Culture Générale <i className="fas fa-arrow-right"/> </a>
              </div>
            </div>
          </div>
        :
        <div className="offline-content deroulement">
          <h2> Veuillez vous connecter </h2>
          <p> Cette section n'est disponible que pour les membres du site </p>
          <a href="/login"> Me connecter </a> ou <a href="/register"> Créer un compte </a>

        </div>
      }
    </div>
  )}
}
