import React, {Component} from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import './livret.css';
import {QCMReminder} from '../QCMReminder/qcm-reminder';
import ReactGA from 'react-ga';
import {Helmet} from "react-helmet";

export default class LivretDuCitoyen extends Component{
  constructor(props){
    super(props);

    this.state= {
      session : this.props.session
    }
  }

  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/preparer-entretien/culture-generale');
  }

  render(){
    return(
      <div className="livret-main-container">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{this.props.pageTitle}</title>
            <link rel="canonical" href={this.props.pageCanonicalLink} />
            <meta name="description" content={this.props.pageDescription} />
        </Helmet>
        <Breadcrumb>
          <BreadcrumbItem><a href="/preparer-entretien">Préparer mon entretien</a></BreadcrumbItem>
          <BreadcrumbItem active>Culture générale</BreadcrumbItem>
        </Breadcrumb>
        <div className="top-image livret">
          <div className="top-image-title">
            <h2> Préparer mon entretien : <span>Culture générale </span></h2>
          </div>
        </div>

        {
          this.props.session?
        <div className="livret-content">

          <div className="livret-content-header">
            <p>Préparer son entretien de naturalisation c’est bien, savoir quelles sont les informations à connaître c’est mieux !</p>
            <br/><p>Lors de votre entretien l’agent de préfecture a pour objectif de vérifier que vous possédez une connaissance suffisante de l’histoire, de la culture et de la société française.
            Dans cette rubrique, vous trouverez tous les éléments fondamentaux à connaître (apprendre ?) afin d’atteindre le niveau de connaissances attendues.</p>
          </div>
          <br/>
          <div className="chapitres">
            <ol>
              <a href="/preparer-entretien/culture-generale-part1">
              <li>
                <p>Chapitre 1</p>
                <h4>Les valeurs de la République <i className="fas fa-angle-right"></i></h4>
              </li>
              </a>

              <a href="/preparer-entretien/culture-generale-part2">
              <li>
                <p>Chapitre 2</p>
                <h4>La devise <i className="fas fa-angle-right"></i> </h4>
              </li>
              </a>

              <a href="/preparer-entretien/culture-generale-part3">
              <li>
                <p>Chapitre 3</p>
                <h4>Le système démocratique <i className="fas fa-angle-right"></i> </h4>
              </li>
              </a>

              <a href="/preparer-entretien/culture-generale-part4">
              <li>
                <p>Chapitre 4</p>
                <h4>Organisation du territoire  <i className="fas fa-angle-right"></i> </h4>
              </li>
              </a>

              <a href="/preparer-entretien/culture-generale-part5">
              <li>
                <p>Chapitre 5</p>
                <h4>Évenements et histoire de France <i className="fas fa-angle-right"></i> </h4>
              </li>
              </a>

              <a href="/preparer-entretien/culture-generale-part6">
              <li>
                <p>Chapitre 6</p>
                <h4>La France en chiffre  <i className="fas fa-angle-right"></i> </h4>
              </li>
              </a>

              <a href="/preparer-entretien/culture-generale-part7">
              <li>
                <p>Chapitre 7</p>
                <h4>Eux aussi ont été naturalisés<i className="fas fa-angle-right"></i></h4>
              </li>
              </a>

            </ol>
          </div>


          <div className="qcm-reminder">
            <QCMReminder/>
          </div>

        </div>
        :
        <div className="offline-content livret">
          <h2> Veuillez vous connecter </h2>
          <p> Cette section n'est disponible que pour les membres du site </p>
          <a href="/login"> Me connecter </a> ou <a href="/register"> Créer un compte </a>
        </div>
      }
    </div>

  )}
}
