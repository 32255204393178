import React, {Component} from 'react';
import {Helmet} from 'react-helmet';
import './home.css';
import ReactGA from 'react-ga';

export default class Steps extends Component{
  constructor(props){
    super(props);
    this.state = {
      step : 1,
    }
  }

  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/etapes');
    //this.cycleBackground();
  }

  render(){
    return(
      <div className="home-page-content">
        <Helmet>
            <meta charSet="utf-8" />
            <title>{this.props.pageTitle}</title>
            <link rel="canonical" href={this.props.pageCanonicalLink} />
            <meta name="description" content={this.props.pageDescription} />
        </Helmet>
        <div className="home-container services">
          <div className="header-content">
            <h1> Que fait-on aujourd'hui ? </h1>
          </div>
          <div className="body-content">
            <a href="/constituer-dossier">
                <div className="block-step">
                  <div>
                    <h2> Je commence la procédure </h2>
                    <img src={require("./img/services.svg")} alt="step-1"/>
                  </div>
                </div>
            </a>

            <a href="/preparer-entretien">
              <div className="block-step">
                <div>
                  <h2> Je prépare mon entretien </h2>
                  <img src={require("./img/folder.svg")} alt="step-2"/>
                </div>
              </div>
            </a>

            <a href="/notification">
              <div className="block-step">
                <div>
                  <h2> Je veux être alerté(e) de ma naturalisation </h2>
                  <img src={require("./img/file.svg")} alt="step-3"/>
                </div>
              </div>
            </a>

            {/*
            <a href="/">
              <div className="block-step">
                <div>
                  <h2> J'ai été naturalisé(e), et maintenant ? </h2>
                  <img src={require("./img/phone.svg")} alt="step-3"/>
                </div>
              </div>
            </a>
            */}
          </div>
        </div>
      </div>
  )}

}
