import React,{Component} from 'react';
import './livretCards.scss';

export default class LivretCards extends Component{

  render(){
    return(
      <div classNameName="livret-cards">


      <div className="blog-card">
        <div className="meta">
          <div className="photo photo-drapeau"></div>
        </div>
        <div className="description">
          <h1>Le drapeau</h1>
          <h2>Le drapeau tricolore</h2>
          <p>L’emblème de la France le drapeau tricolore date également de cette période.</p>
          <br/> <p> Il est constitué de trois couleurs (dans l'ordre): </p>
            <lu>
              <li> Bleu : couleur de Paris</li>
              <li> Blanc : couleur des Rois</li>
              <li> Rouge : couleur su sang versé pour la libération du peuple</li>
            </lu>
          <p className="read-more">
            <a href="https://www.elysee.fr/la-presidence/le-drapeau-francais">En savoir plus</a>
          </p>
        </div>
      </div>

        <div className="blog-card alt">
          <div className="meta">
            <div className="photo photo-marseillaise"></div>
          </div>
          <div className="description">
            <h1>La Marseillaise</h1>
            <h2>L'hymne nationale</h2>
            <p>La Marseillaise est l'hymne nationale de La France.
            <br/>Ecrite par Rouget de Lisle en 1792, elle était à la base un chant patriotique de la Révolution</p>
            <p className="read-more">
              <a href="http://www2.assemblee-nationale.fr/decouvrir-l-assemblee/histoire/dossier-historique-la-marseillaise/les-paroles-de-la-marseillaise">En savoir plus</a>
            </p>
          </div>
        </div>

        <div className="blog-card">
          <div className="meta">
            <div className="photo photo-marianne"></div>
          </div>
          <div className="description">
            <h1>La Marianne</h1>
            <p>Étant l'un des symboles de la République, Marianne est une paysanne portant un bonnet phrygien.
            <br/>Elle représente la Révolution Française, la démocratie, la liberté et les valeurs de la France.
            <br/>Vous pouvez une statue à son effigie dans toutes les mairies.</p>
            <p className="read-more">
              <a href="http://www.francesoir.fr/culture-art-expo/pourquoi-marianne-est-elle-la-figure-allegorique-de-la-france">En savoir plus</a>
            </p>
          </div>
        </div>


        <div className="blog-card alt">
          <div className="meta">
            <div className="photo photo-devise"></div>
          </div>
          <div className="description">
            <h1>La devise</h1>
            <h2>Liberté Égalité Fraternité</h2>
            <p>Ces trois mots sont la devise de la République française. Ensemble, ils représentent les valeurs qui unissent le peuple Français.<br/>
              En tant que Français, chacun naît libre et à égalité avec les autres. Pendant votre vie en France il faut faire preuve de fraternité, aider les autres.<br/>
             Ces valeurs font la fierté de la France et représente les français partout dans le monde.</p>
            <p className="read-more">
              <a href="https://fr.wikipedia.org/wiki/Libert%C3%A9,_%C3%89galit%C3%A9,_Fraternit%C3%A9">En savoir plus</a>
            </p>
          </div>
        </div>

        <div className="blog-card">
          <div className="meta">
            <div className="photo photo-14juillet"></div>
          </div>
          <div className="description">
            <h1>Le 14 juillet</h1>
            <h2> </h2>
            <p>Le 14 juillet 1789 est la date à laquelle le peuple français s’empare de la prison de la Bastille. Symbole de la Révolution Française cette date est devenue la date de notre fête nationale.</p>
            <p className="read-more">
              <a href="https://www.elysee.fr/la-presidence/la-fete-nationale-du-14-juillet">En savoir plus</a>
            </p>
          </div>
        </div>


        <div className="blog-card alt">
          <div className="meta">
            <div className="photo photo-coq"></div>
          </div>
          <div className="description">
            <h1>Le coq</h1>
            <h2> </h2>
            <p>Cet emblème fait référence à la Gaule et les Gaulois car il est basé sur un jeu de mot. Le mot « gallus » en latin signifie aussi bien le mot Gaulois que coq.<br/>
            Au cours de l’histoire, les rois de France ont utilisé l’image du coq pour son courage et sa bravoure. Dès la Révolution Française le coq apparaît sur la monnaie</p>
            <p className="read-more">
              <a href="https://fr.wikipedia.org/wiki/Embl%C3%A8mes_de_la_France#Coq_gaulois">En savoir plus</a>
            </p>
          </div>
        </div>


        <div className="blog-card">
          <div className="meta">
            <div className="photo photo-faisceau"></div>
          </div>
          <div className="description">
            <h1>Le faisceau de licteur</h1>
            <h2> </h2>
            <p>Sans jamais avoir eu un caractère officiel, le faisceau de licteur est fréquemment utilisé pour représenter la République.<br/>
            Ce symbole a marqué tout particulièrement la Révolution Française car il évoque l'union et la force des citoyens français dans leur combat pour la liberté.</p>
            <p className="read-more">
              <a href="https://www.elysee.fr/la-presidence/le-faisceau-de-licteur">En savoir plus</a>
            </p>
          </div>
        </div>

      </div>
  )}
}
