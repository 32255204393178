import React, {Component} from 'react';
import { injectStripe} from 'react-stripe-elements';
import { Button } from 'reactstrap';
//CardElement
import ErrorForm from './ErrorForm.js'

class CheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.verify = this.verify.bind(this);
    switch(this.props.package) {
        case 1:
          this.state = {
            complete : false,
            cgvChecked : false,
            amountttc : "0,99",
            sku : "sku_F63JSIItCF6eXF"
          };
          break;
        case 2:
          this.state = {
            complete : false,
            cgvChecked : false,
            amountttc : "1,79",
            sku : "sku_F63J35PuZJoDEe"
          };
          break;
        case 3:
          this.state = {
            complete : false,
            cgvChecked : false,
            amountttc : "2,50",
            sku : "sku_F63IBnxG5BzOMy"
          };
          break;
        default:
          this.state = {
            complete : false,
            cgvChecked : false,
            amountttc : "2,50",
            sku : "sku_F63IBnxG5BzOMy"
          };
          break;

    }
  }

  async submit() {
    this.props.stripe
      .redirectToCheckout({
          items: [
            // Replace with the ID of your SKU
            {sku: this.state.sku, quantity: 1}
          ],
          locale:'fr',
          successUrl: 'https://www.natification.fr/notification/subscribe/success',
          cancelUrl: 'https://www.natification.fr/notification/subscribe/cancel',
        }).then(function (result) {
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          console.log(result.error.message);
          return <ErrorForm message={result.error.message}/>

        })

  }


  handleCheck = (e) => {
    this.setState({cgvChecked: !this.state.cgvChecked});
  }

  verify(){
    var res = true;

    if(this.state.cgvChecked === false){
      this.setState({
        cgvErr : true
      })
      res = false;
    }else{
      this.setState({
        cgvErr : false
      })
    }

    if(res){
      this.submit();
    }
  }

  render() {
    return (
      <div className="checkout" >
        <div className="bill">

          {this.props.package===1||this.props.package===3?
            <div className="bill-item">
              <div className="bill-item name"><p> Notification par email </p></div>
              <div className="bill-item price"><p> 0,99 € </p></div>
            </div>
            :
            ""
          }
          {this.props.package===2||this.props.package===3?
            <div className="bill-item">
              <div className="bill-item name"><p> Notification par SMS </p> </div>
              <div className="bill-item price"><p> 1,79 € </p> </div>
            </div>
            :
            ""
          }
          {this.props.package===3?
            <div className="bill-item">
              <div className="bill-item name"><p> Total </p> </div>
              <div className="bill-item price"><p> 2,88 € </p> </div>

              <div className="bill-item name"><p> PROMO 2019 </p> </div>
              <div className="bill-item price"><p> - 15 % </p> </div>
            </div>
            :
            ""
          }
          __________________________

          <div className="bill-item">
            <div className="bill-item total-title"> TOTAL TTC </div>
            <div className="bill-item total-price"> {this.state.amountttc} € </div>
          </div>
        </div>

        <br/>

        <br/>
        <div className={this.state.cgvErr ? 'cgv-text field-error' : 'cgv-text'}>
          <input type="checkbox" id="cgvcheck" name="cgvcheck" defaultChecked={this.state.cgvChecked} onChange={this.handleCheck} />
          <label> J'accepte les CGV. </label>
          <p>En cochant la case ci-dessus, j'affirme avoir pris connaissance et accepte sans réserve les <a href="/terms/cgu" target="_blank"> Conditions Générales d'Utilisation. </a></p>
        </div>
        <div className="btn next">
          <Button className="send" id={"checkout-button-"+this.state.sku} onClick={this.verify}>Procéder au paiement</Button>
        </div>
      </div>
    );
  }
}

export default injectStripe(CheckoutForm);

/*
<CardElement />

*/
