import React,{Component} from 'react';
import './legals.css';
import {Helmet} from 'react-helmet';

export default class CharteRespectViePrivee extends Component{
  render(){
    return(
      <div className="legal-page-content">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <link rel="canonical" href={this.props.pageCanonicalLink} />
          <meta name="description" content={this.props.pageDescription} />
      </Helmet>
        <div className="legal-content">
          <h1> Charte du Respect de la Vie Privée </h1>
          <br/><br/>
          <p>Conformément au Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016, il est précisé aux utilisateurs du présent Site la présente Charte afin de formaliser notre engagement quant au respect de la vie privée des utilisateurs du site <a href="/"> www.natification.fr </a>.</p>
          <p> La Charte et les <a href="/terms/cgu"> Conditions Générales d'Utilisation </a> forment un ensemble contractuel. Tous les termes non-définis dans la présente Charte sont définis dans les Conditions Générales d’Utilisation. </p>
          <br/>

          <div id="articles">
            <h2> Article 1 : Préambule</h2>
            <p>
            Les utilisateurs et annonceurs de www.natification.fr sont invités à lire attentivement la présente politique de protection de la vie privée avant de transmettre leurs données personnelles.</p>
            <h2> Article 2 : Généralités</h2>
            <p>
            NATIFICATION veille tout particulièrement à ce que le droit au respect de la vie privée de ses utilisateurs soit respecté dans le cadre de la loi du 6 aout 2004 informatique et libertés. En recourant aux services proposés par www.natification.fr, les utilisateurs peuvent être amenés à communiquer des données à caractère personnel telles que leurs noms, prénoms, adresses électroniques, adresses postales, numéros de téléphone, dates de naissance ainsi que d'autres données supplémentaires (numéro de téléphone, numéro REZE, préférences, choix de langue, code d'accès, situation géographique). En transmettant leurs données personnelles, les utilisateurs autorisent expressément www.natification.fr à les utiliser conformément à sa politique de protection de la vie privée. La présente politique de protection de la vie privée a pour objet de définir les données collectées, les objectifs poursuivis par le traitement de ces données, et les droits des utilisateurs quant à l'utilisation de ces données.</p>

            <h2> Article 3 : Le responsable du traitement de vos données personnelles</h2>
            <p>
            Les données personnelles des utilisateurs seront traitées par la S.A.S ENAGE Conseil dont le siège social est 324 rue Lecourbe 75015 PARIS</p>

            <h2> Article 4 : Pourquoi le site recueille les données privées de ses utilisateurs ?</h2>
            <p>
            Les données à caractère personnel communiquées à www.natification.fr sont avant tout nécessaires pour identifier les utilisateurs et annonceurs du site et permettre aux visiteurs de se connecter et d'avoir accès aux services proposés par le site. Ces données sont également nécessaires pour informer les utilisateurs des nouveaux services proposés par www.natification.fr. Le site peut également utiliser ces données pour améliorer la qualité de ses services, pour réaliser des études de marché, ou à des fins statistiques notamment en conservant les données des biens postés pour créer son index de référence. </p>
            <p>
            Ces données peuvent également être utilisées à des fins commerciales et marketing, c'est-à-dire, pour permettre à www.natification.fr de tenir ses utilisateurs informés de ses services, promotions, et actions. </p>

            <h2> Article 5 : Utilisation des données personnelles recueillies</h2>
            <p>
            Toutes données personnelles collectées sont susceptibles d'être utilisées par www.natification.fr aux fins suivantes :</p>
            <ul>
              <li>	l'envoi de propositions commerciales et/ou promotionnelles, émanant de www.natification.fr </li>
              <li>	l'envoi de propositions commerciales et/ou promotionnelles, émanant de sociétés tiers partenaires de www.natification.fr</li>
              <li>	l'envoi d'enquêtes de satisfaction</li>
              <li>	statistiques</li>
            </ul>
            <p>
            Par dérogation, l'Utilisateur et l'Annonceur sont informés que www.natification.fr peut être amenée à communiquer les données personnelles collectées aux autorités administratives et judiciaires autorisées, uniquement sur réquisition judiciaire.</p>

            <h2> Article 6 : L'utilisation des données privées par des Services tiers (Facebook, Twitter…)</h2>
            <p>
            L'utilisateur est libre d'établir une connexion entre son compte www.natification.fr et des services tiers (tel que Facebook, Twitter ou instagram ou autre) en vue de faciliter le partage d'informations et d'identifier et communiquer avec d'autres personnes utilisant www.natification.fr. www.natification.fr n'est pas responsable du traitement des données qui peut être effectué par les services tiers.</p>

            <h2> Article 7 : Carte de crédit</h2>
            <p>
            Lorsqu'un utilisateur communique le numéro de sa carte de crédit pour payer des services sur www.natification.fr, www.natification.fr fait appel à un prestataire de service qui utilise un système de paiement en ligne avec cryptage qui répond à des standards très stricts de sécurité contre tout accès, utilisation ou divulgation non autorisée. Les numéros de cartes de crédit sont utilisés uniquement dans le cadre des paiements en ligne et ne sont pas conservés à d'autres fins.</p>

            <h2> Article 8 : L'utilisation des Cookies</h2>
            <p>
            Les cookies sont des éléments d'information qu'un site web donné installe sur l'ordinateur du visiteur du site web dans l'intention de renvoyer cette information vers le site web lors d'une consultation ultérieure. Il est ainsi possible de tenir aisément à jour des informations sur les utilisateurs d'Internet. Les cookies temporaires ne sont stockés dans le fichier cookies du navigateur que jusqu'à la fin de la visite sur le site Internet. Ce type de cookies est incontournable pour certaines applications et fonctions sur le site Internet, qui ne fonctionnent pas correctement sans eux.</p>
            <p>
            Les cookies permanents sont utilisés pour personnaliser la visite sur le site Internet (par exemple en stockant un champ qui a été entré). Ces cookies restent plus longtemps stockés dans le fichier cookie du navigateur, en fonction des paramètres de l'utilisateur. Les cookies peuvent être supprimés par l'utilisateur en adaptant les paramètres de son navigateur. Pour toute information complémentaire, l'utilisateur peut consulter le site de la commission de la protection de la vie privée.</p>

            <h2> Article 9 : Le droit des utilisateurs de s'opposer gratuitement au traitement de leurs données personnelles à des fins de marketing direct</h2>
            <p>
            Tout utilisateur a le droit de s'opposer gratuitement et sans justification au traitement de ses données à caractère personnel à des fins de marketing direct ou commerciales (démarches publicitaires) et d'obtenir la modification ou l'effacement des données le concernant. L'utilisateur est libre de retirer à tout moment son consentement de l'utilisation de ses données et solliciter à tout moment la suppression définitive de ces données. Pour ce faire, l'utilisateur doit simplement en informer www.natification.fr soit par e-mail à l'adresse info@www.natification.fr.com ou par l'envoi d'une lettre à l'adresse suivante : Enage Conseil, 324 rue Lecourbe 75015 PARIS</p>

            <h2> Article 10 : Le droit d'accès et de rectification des données à caractère personnel</h2>
            <p>
            Les utilisateurs ont le droit de demander d'avoir accès à ces données et d'en demander la rectification. Pour ce faire, l'utilisateur doit en informer www.natification.fr soit par e-mail à l'adresse contact@natification.fr ou par l'envoi d'une lettre à l'adresse suivante : ENAGE CONSEIL, 324 rue Lecourbe 75015 PARIS</p>
		      </div>



        </div>

      </div>
    )}
}
