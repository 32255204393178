import React,{Component} from 'react';
import './legals.css';
import {Helmet} from 'react-helmet';

export default class MentionsLegales extends Component{
  render(){
    return(
      <div className="legal-page-content">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <link rel="canonical" href={this.props.pageCanonicalLink} />
          <meta name="description" content={this.props.pageDescription} />
      </Helmet>
        <div className="legal-content">
          <h1> Mentions Légales </h1>
          <br/><br/>
          <p>Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l'économie numérique, il est précisé aux utilisateurs du présent Site l'identité des différents intervenants dans le cadre de sa réalisation et de son suivi.</p>

          <h2> Edition du site </h2>
          <p> Le site est édité par la socité ENAGE Conseil SAS au capital social de 1000 € euros,
          immatriculé au RCS de Paris sous le numéro 841 959 042
          et dont le siège social se situe au 324 Rue Lecourbe 75015 PARIS
          (Siret N° 841959042 00018 et TVA intracommunautaire N° FR 48 841959042 )
          </p>


          <h2> Directrice de publication </h2>
          <p> Mme BACHOUCHI Ghita  </p>

          <h2> Nous Contacter </h2>
          <ul>
            <li> Par mail : <a href="mailto:contact@natification.fr"> contact@natification.fr </a> </li>
            <li> Par téléphone : +33786167023 </li>
            <li> Par courrier :  Enage Conseil, 324 rue Lecourbe, 75015 PARIS</li>
          </ul>

          <h2> Données Personnelles </h2>
          <p> La traitement de vos données à caractère personnel est régi par la <a href="/terms/crvp"> Charte de Respect de la Vie Privée </a> et conformément au Règlement Général sur la Protection des Données 2016/679 du 27 avril 2016 et entré en application au 28 Mai 2018 ("RGPD").</p>

          <p> Pour toute question concernant vos données personnelles ou si vous souhaitez supprimer votre compte, merci de nous contacter à l’adresse suivante: ENAGE CONSEIL, 324 rue Lecourbe 75015 PARIS (en indiquant "Vie Privée - Protection des Données") ou par email à contact@natification.fr.</p>


        </div>

      </div>
    )}
}
