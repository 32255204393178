import React,{Component} from 'react';
import LoginForm from './LoginForm';
import ForgotPwdForm from './ForgotPwdForm';
import ReactGA from 'react-ga';
import {Helmet} from "react-helmet";

export default class Login extends Component{
  constructor(props){
    super(props);
    this.state = {
      pwdForgot : false,
    };
    this.forgotPW = this.forgotPW.bind(this);
  }

  forgotPW(){
    this.setState({
      pwdForgot : !this.state.pwdForgot
    })
  }

  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/login');
  }

  render(){


    return(
    <div className="login-page-content">
    <Helmet>
        <meta charSet="utf-8" />
        <title>{this.props.pageTitle}</title>
        <link rel="canonical" href={this.props.pageCanonicalLink} />
        <meta name="description" content={this.props.pageDescription} />
    </Helmet>
      <div className="page-content">
        {this.state.pwdForgot?
        <div className="content-login">
        <h2> Mot de passe oublié </h2>
        <hr/>
        <ForgotPwdForm />
        <br/>
        <button className="mdp-forget" onClick={this.forgotPW}> Retour </button>
        <br/>
        </div>
        :
        <div className="content-login">
        <h2> Accédez à votre espace </h2>
        <hr/>
        <LoginForm/>
        <br/>

        <button className="mdp-forget" onClick={this.forgotPW}> Mot de passe oublié </button> | <a className="register-link" href="/register">S'inscrire</a>
        </div>
        }
      </div>
    </div>
  )}
}
