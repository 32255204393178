import React,{Component} from 'react';
import './CartesQuestPersos.css'

export default class CartesQuestPersos extends Component{
  constructor(props){
    super(props);
    this.randomImage = this.randomImage.bind(this);
  }

  randomImage(){
    var res = Math.floor(Math.random() * 16) + 1;
    return [require("./img/tile-"+res+".jpg"),"alt-"+res];
  }

  render(){
    return(
      <div class="wrap">

        <div class="tile">
            <img src={this.randomImage()[0]} alt={this.randomImage()[1]} />
            <div class="text">
              <h1>Depuis quand êtes-vous en France ?</h1>
              <p class="animate-text">La question est quasi-systématique. Vous devez donner votre date de première entrée, c'est à dire la date à partir de laquelle vous résidez en France de manière permanente.<br/> N’oubliez pas qu’ils ont tout votre dossier !
              <br/>Évidement une date approximative est tolérée.</p>

            </div>
          </div>


          <div class="tile">
          <img src={this.randomImage()[0]} alt={this.randomImage()[1]} />
            <div class="text">
                <h1>Pourquoi voulez-vous devenir français ?</h1>
                <p class="animate-text">C’est LA question que l’on pose à tout le monde !
                Vous devez expliquer les attaches que vous avez avec la France : vie personnelle, vie professionnelle, vos projets, votre mode de vie, votre entourage, votre maison, vos activités.
L’objectif est de montrer que votre vie est en France désormais.Afin de soutenir vos propos n’hésitez pas à citer des valeurs françaises </p>

            </div>
           </div>

          <div class="tile">
          <img src={this.randomImage()[0]} alt={this.randomImage()[1]} />
            <div class="text">
              <h1>De quelles nationalités sont vos amis et votre entourage ?</h1>
              <p class="animate-text">L’objectif est de mettre en valeur vos relations avec votre entourage français.
Votre entourage contient probablement des personnes de votre nationalité. Le but n’est pas de les renier. Vous devez répondre de la manière la plus honnête possible tout en essayant de montrer votre intégration.  Vous pouvez de ce fait citer vos collègues,  vos amis ou encore certaines personnes de la famille qui sont français.</p>

            </div>
         </div>

         <div class="tile">
         <img src={this.randomImage()[0]} alt={this.randomImage()[1]} />
             <div class="text">
               <h1>Où vivent vos parents, vos frères, vos sœurs ?</h1>
               <p class="animate-text">La famille reste une attache importante et révélatrice. Si vos parents vivent en dehors de la France il faut le dire. L'agent peut vous demandez si la distance vous pose un problème, vous devez alors expliquer de la manière la plus claire possible que vous avez votre vie en France et que rien ne vous empêche d’aller voir vos parents quand vous le désirez. </p>

             </div>
           </div>


           <div class="tile">
           <img src={this.randomImage()[0]} alt={this.randomImage()[1]} />
             <div class="text">
                 <h1>Gardez vous un lien avec votre pays d’origine ? Combien de fois rentrez-vous à votre pays ?</h1>
                 <p class="animate-text">Il n’est pas question de renier votre patrie à travers cette question. Vous pouvez parfaitement avoir un lien sans pour autant vouloir y vivre. Souvent le lien est la famille. Vous pouvez donc citer votre famille. Concernant le nombre de fois où vous rentrez,  vous devez donner une fourchette réelle car ils peuvent parfaitement vérifier cette information avec votre passeport.N’essayez pas de minimiser l’information. Vous avez parfaitement le droit de rentrer fréquemment.</p>

             </div>
            </div>

           <div class="tile">
           <img src={this.randomImage()[0]} alt={this.randomImage()[1]} />
             <div class="text">
               <h1>Envoyez-vous de l’argent à votre pays d’origine ?</h1>
               <p class="animate-text">Dans le cas où vous envoyer de l’argent, il faut le dire mais également le justifier.
               Envoyer de l’argent pour aider votre famille  ou pour investir n’est pas la même chose. Si vous précisez envoyer de l’argent pour investir ou pour épargner, cela peut être un point négatif. Cela peut donner le sentiment que vous préparer votre retour dans votre pays natal. Privilégiez donc l’aide familiale.</p>

             </div>
          </div>


          <div class="tile">
          <img src={this.randomImage()[0]} alt={this.randomImage()[1]} />
            <div class="text">
              <h1>
              Quelle(s) langue(s) parlez-vous au travail ?
              </h1>
              <p class="animate-text">
              L’objectif en vous posant cette question est de vérifier votre aisance avec la langue de Molière et donc de ce fait votre intégration en France.
              <br/>Si vous parlez plusieurs langues, vous pouvez les citer tant que dans votre le liste il y a le français.
              </p>

            </div>
          </div>

          <div class="tile">
          <img src={this.randomImage()[0]} alt={this.randomImage()[1]} />
            <div class="text">
              <h1>
              Faites-vous partie d’une association?
              </h1>
              <p class="animate-text">
              Etre membre d’une association est toujours un point positif car en plus de donner une bonne image, elle prouve également votre investissement et votre intégration en tant que citoyen français.
              <br/>Si vous ne participez pas à une association rien ne sert de mentir car votre interlocuteur va essayer d’en savoir plus (ce que fait votre association, son siège ou encore votre rôle au sein de cet organisme).
              <br/>Attention, le fait d’être donateur ne fait pas de vous un membre !
              </p>

            </div>
          </div>

          <div class="tile">
          <img src={this.randomImage()[0]} alt={this.randomImage()[1]} />
            <div class="text">
              <h1>
              Quelles sont les villes de France que vous avez visitées ?
              </h1>
              <p class="animate-text">
              Cette question a pour objectif de jauger votre intérêt pour la France.Il suffit  d’énumérer l’ensemble des villes que vous avez réellement visitées. L’agent peut vous poser des questions sur ces villes s'il doute de votre sincérité. N’ayez crainte si vous ne connaissez que peu (ou pas) de villes en dehors de la vôtre. L’intérêt de cette question n’est pas de remettre en doute votre intérêt mais uniquement de le confirmer.
              </p>

            </div>
          </div>


          <div class="tile">
          <img src={this.randomImage()[0]} alt={this.randomImage()[1]} />
            <div class="text">
              <h1>
              Quel est le montant de votre loyer ?
              </h1>
              <p class="animate-text">
              Même si cette question peut sembler sans intérêt pour vous, elle a pour rôle de vérifier votre dossier et surtout vos connaissances sur des éléments de votre vie quotidienne. Ne pas connaître le montant de son loyer peut être un aspect inquiétant pour votre agent.
              </p>

            </div>
          </div>


          <div class="tile">
          <img src={this.randomImage()[0]} alt={this.randomImage()[1]} />
            <div class="text">
              <h1>
              Qu’avez-vous en plus en France que vous n’avez pas dans votre pays?
              </h1>
              <p class="animate-text">
              Ces l’une des questions que vous devez bien préparer en amont. Même si cette question peut sembler compliquée, elle a pour rôle de connaître vos motivations.Préparez de ce fait un argumentaire en vous basant sur votre emploi, votre vie sociale, votre vie privée, vos conditions de vie, vos habitudes et vos loisirs. L’intérêt est de montrer que votre vie est en France et que vous adhérez aux valeurs de la République.
              </p>

            </div>
          </div>

      </div>

    )}

}
