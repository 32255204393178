import React, {Component} from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import './questionsperso.css'
import CartesQuestPersos from './CartesQuestions/CartesQuestPersos';
import {QCMReminder} from '../QCMReminder/qcm-reminder';
import {Helmet} from "react-helmet";

export default class QuestionsPersonnelles extends Component{
  constructor(props){
    super(props);

    this.state= {
      session : this.props.session
    }
  }
  render(){
    return(
      <div className="preparer-main-container questions-persos">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <link rel="canonical" href={this.props.pageCanonicalLink} />
          <meta name="description" content={this.props.pageDescription} />
      </Helmet>
        <Breadcrumb>
          <BreadcrumbItem><a href="/preparer-entretien">Préparer mon entretien</a></BreadcrumbItem>
          <BreadcrumbItem active>Questions Personnelles</BreadcrumbItem>
        </Breadcrumb>
        <div className="top-image deroulement">
            <div className="top-image-title">
              <h2> Préparer mon entretien : <span>Les questions personnelles</span></h2>
            </div>
        </div>
        {
          this.props.session?

          <div className="questions-persos-content">

            <div className="questions-persos-content-header">
              <p>Lors de votre entretien l’agent commencera par vous poser des questions sur vous.<br/>L’objectif est d’évaluer votre niveau de langue, vos motivations mais également votre intégration en France.</p>
              <p>Afin de vous préparer à cette partie de votre entretien, vous trouverez dans cette rubriques des questions types que vous pouvez avoir.</p><br/>
              <h4>Il est essentiel de préparer vos réponses en amont !</h4><br/>
              <p>Nous vous proposons des éventuelles pistes de réponses mais il est nécessaire de les adapter à votre situation personnelle.<br/> L’intérêt n’est pas de mentir mais de rester soi-même !
              </p>
            </div>

            <div className="questions-perso-section section-1">
              <h3> Les Questions </h3>
              <p><em> <i className="fas fa-arrow-right"/>  Sélectionner une carte pour plus d'information sur la question <i className="fas fa-arrow-left"/>  </em></p>
              <CartesQuestPersos />
            </div>

            <div className="qcm-reminder">
              <QCMReminder/>
            </div>

          </div>


        :
        <div className="offline-content questions-peros">
          <h2> Veuillez vous connecter </h2>
          <p> Cette section n'est disponible que pour les membres du site </p>
          <a href="/login"> Me connecter </a> ou <a href="/register"> Créer un compte </a>

        </div>
      }
    </div>
  )}
}
