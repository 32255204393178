import React,{Component} from 'react';
import { Button, Form, FormGroup, Input,Alert,FormFeedback } from 'reactstrap';
import './login.css';
import axios from 'axios';
import ReactGA from 'react-ga';
import FacebookLogin from 'react-facebook-login';
import {Helmet} from 'react-helmet';

var rem = new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+[.][a-zA-Z0-9-.]+$");

export default class Register extends Component{
  constructor(props) {
    super(props);
    this.state={
      created : false,
      fname: null,
      lname: null,
      email: null,
      pwdA: null,
      pwdB: null,
      cguChecked : false,
      emailAlreadyExist : false
    }
    this.handleChange = this.handleChange.bind(this);
    this.verify = this.verify.bind(this);
    this.send = this.send.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.checkAvailable = this.checkAvailable.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);

  }

  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/register');
  }

  handleCheck = (e) => {
    this.setState({cguChecked: !this.state.cguChecked});
  }

  handleChange = (e) => {
      this.setState({
            [e.target.name]: e.target.value
        });
      if(e.target.name==="email"){
        this.checkAvailable(e.target.value);
      }
    }

  checkAvailable(emailValue){
    var self = this;
    axios({
              method: 'POST',
              url : "https://www.natification.fr/serv/api/client/check_user.php",
              headers: {
                'Content-Type': 'application/json'
              },
              data: {
                'email': emailValue
              }
            })
      .then(function (response) {
        if(response.data.state==="0x01"){
          self.setState({
            emailAlreadyExist : true,
          });
        }
        else{
          self.setState({
            emailAlreadyExist : false,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  verify(){
    var res = true;

    if(this.state.cguChecked === false){
      this.setState({
        cguErr : true
      })
      res = false;
    }else{
      this.setState({
        cguErr : false
      })
    }

    if(this.state.fname == null || this.state.fname === "") {
      this.setState({
        fnameErr : true
      })
      res = false;
    }else{
      this.setState({
        fnameErr : false
      })
    }

    if(this.state.lname == null || this.state.lname === ""){
      this.setState({
        lnameErr : true
      })
      res = false;
    }else{
      this.setState({
        lnameErr : false
      })
    }

    if(this.state.email == null || this.state.email === "" || this.state.emailNotAvailable){
      this.setState({
        emailErr : true
      })
      res = false;
    }else{
      this.setState({
        emailErr : false
      })
    }

    if(rem.test(this.state.email)){
      this.setState({
        emailErr : false
      })
    }else{
      this.setState({
        emailErr : true
      })
    }


    if(this.state.pwdA == null || this.state.pwdA === ""){
      this.setState({
        pwdAErr : true
      })
      res = false;
    }else{
      this.setState({
        pwdAErr : false
      })
    }

    if(this.state.pwdB == null || this.state.pwdB === "" || this.state.pwdB !== this.state.pwdA){
      this.setState({
        pwdBErr : true
      })
      res = false;
    }else{
      this.setState({
        pwdBErr : false
      })
    }


    if(res){
      this.send();
    }

  }

  send(){
    var self = this;
    axios({
              method: 'POST',
              url : "https://www.natification.fr/serv/api/client/create_user.php",
              headers: {
                'Content-Type': 'application/json'
              },
              data: {
                'firstname': this.state.fname,
                'lastname': this.state.lname,
                'email': this.state.email,
                'password': this.state.pwdA
              }
            })
      .then(function (response) {
        if(response.data.state==="0x01"){
          self.setState({
            created : true,
          });
        }
        self.forceUpdate();

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  responseFacebook(response){
    var self = this;
    axios({
              method: 'POST',
              url : "https://www.natification.fr/serv/api/client/create_user_fb.php",
              headers: {
                'Content-Type': 'application/json'
              },
              data: {
                'email': response.email,
                'userID': response.id,
                'name' : response.name,
              }
            })
      .then(function (response) {
        if(response.data.state==="0x01"){
          self.setState({
            created : true,
          });
        }
        self.forceUpdate();

      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render(){
    return(
    <div className="register-page-content">
    <Helmet>
        <meta charSet="utf-8" />
        <title>{this.props.pageTitle}</title>
        <link rel="canonical" href={this.props.pageCanonicalLink} />
        <meta name="description" content={this.props.pageDescription} />
    </Helmet>
      <div className="page-content">
      {
        this.state.created?
        ""
        :
        <h2> Créer un compte </h2>
      }
        <hr/>
        <div id="main-block">
        {
          this.state.created?
          <div className="next-step">
            <Alert color="success"> Compte créé. Vous allez recevoir un email de notre part contenant le lien pour valider votre adresse mail.</Alert>
            {/*<LoginForm/>*/}
          </div>
                  :

        <div className="lg-form">
              <Form>
                  <FormGroup>
                  {this.state.emailAlreadyExist? <Alert color="danger">Cet email est déjà utilisé <br/> <a href="/login"> Me connecter </a></Alert>:""}
                      <FormGroup>
                        {this.state.lnameErr ?
                        <Input invalid type="text" name="lname" id="lname" placeholder="Nom" onChange={e => this.handleChange(e)} required/>
                        :
                        <Input type="text" name="lname" id="lname" placeholder="Nom" onChange={e => this.handleChange(e)} required/>
                        }
                      </FormGroup>

                      <FormGroup>
                        {this.state.fnameErr ?
                        <Input invalid type="text" name="fname" id="fname" placeholder="Prénom" onChange={e => this.handleChange(e)} required/>
                        :
                        <Input type="text" name="fname" id="fname" placeholder="Prénom" onChange={e => this.handleChange(e)} required/>
                        }
                      </FormGroup>

                        {this.state.emailErr ?
                          <FormGroup>
                            <Input invalid type="text"  name="email" id="email" placeholder="Email" autoComplete="username" onBlur={e => this.handleChange(e)} required/>

                          </FormGroup>
                          :
                        <FormGroup>
                          <Input  type="text"  name="email" id="email" placeholder="Email" autoComplete="username" onBlur={e => this.handleChange(e)} required/>
                          {this.state.emailAlreadyExist? <FormFeedback>Cet email est déjà utilisé</FormFeedback>:""}
                        </FormGroup>
                      }

                      <FormGroup>
                        {this.state.pwdAErr?
                        <Input invalid type="password"  name="pwdA" id="pwdA" autoComplete="new-password" placeholder="Mot de passe" onChange={e => this.handleChange(e)} required/>
                        :
                        <Input type="password"  name="pwdA" id="pwdA" autoComplete="new-password" placeholder="Mot de passe" onChange={e => this.handleChange(e)} required/>
                        }
                      </FormGroup>

                      {this.state.pwdBErr?
                        <FormGroup>
                          <Input invalid type="password" name="pwdB" id="pwdB" autoComplete="new-password" placeholder="Confirmez votre mot de passe" onChange={e => this.handleChange(e)} required/>
                          <FormFeedback>Le mot de passe ne correspond pas</FormFeedback>
                        </FormGroup>
                      :
                        <FormGroup>
                          <Input type="password" name="pwdB" id="pwdB" autoComplete="new-password" placeholder="Confirmez votre mot de passe" onChange={e => this.handleChange(e)} required/>
                        </FormGroup>

                      }

                      <div className={this.state.cguErr ? 'cgu-text field-error' : 'cgu-text'}>
                        <input type="checkbox" id="cgucheck" name="cgucheck" defaultChecked={this.state.cguChecked} onChange={this.handleCheck} />
                        <label> J'accepte les CGU. </label>
                        <p>En cochant la case ci-dessus, j'affirme avoir pris connaissance et accepte sans réserve les <a href="/terms/cgu" target="_blank"> Conditions Générales d'Utilisation. </a></p>
                      </div>

                    <Button className="roundblue" onClick={this.verify}>S'inscrire</Button>
                  </FormGroup>
                </Form>
                <hr/>
                  <div className="fb-login">
                    <FacebookLogin
                      appId="372998533574014" //APP ID NOT CREATED YET
                      fields="name,email,picture"
                      callback={this.responseFacebook}
                      icon="fa-facebook"
                    />
                  </div>

          </div>
        }
        </div>

        <br/>
        <a className="register-link" href="/login">Se connecter</a>

      </div>
    </div>
  )}
}
