import React, {Component} from 'react';
import './constituerDossier.css';
import ReactGA from 'react-ga';
import NatCases from './NatCases/NatCases';
import CheckListConditions from './CheckListConditions/CheckListConditions';
import {Helmet} from "react-helmet";

const result = {
  1 :  {
    subtitle : "Liste des documents à fournir",
    style : "cond-list",
    conditions :
      [
        "Photocopie de votre passeport",
        "Photocopie recto-verso de votre titre de séjour valide si non ressortissant européen ",
        "Formulaire cerfa n°15277*03 rempli, daté et signé ",
        "Demande de francisation (optionnelle) ",
        "Demande de choix d'un nom de famille pour votre 1er enfant mineur (optionnelle) ",
        "Timbre fiscale de 55€ (27,50€ en Guyane) ",
        "Enveloppe timbrée à votre adresse ",
        "Une lettre « suivie » vierge affranchie à 500 grammes ",
        "Une photo d'identité récente ",
        "Copie originale et intégrale de votre acte de naissance, avec traduction légalisée ou apostillée ",
        "Si votre filiation n'est pas indiquée, Copie originale et intégrale des actes de naissance de vos parents, avec traduction légalisée ou apostillée (et acte de décès en cas de décès) ",
        "Acte de mariage des parents avec traduction légalisée ou apostillée ",
        "Copie intégrale de votre acte de mariage de moins de 3 mois  ",
        "Si vous êtes réfugié ou apatride, fournir des pièces d’état civil, originales, délivrées par l’Office français de protection des réfugiés et apatrides ",
        "Si le mariage a eu lieu à l'étranger: la copie intégrale de la transcription de votre acte de mariage délivré par le Service central de l’état civil de Nantes (ou sur les registres consulaires français) ",
        "Si vous avez eu des unions antérieures: les originaux des copies intégrales des actes de tous les mariages ainsi que tout document prouvant la dissolution de ces derniers (acte de divorce, acte de décès…) avec traduction ",
        "Si vous avez des enfants mineurs étrangers: copie intégrale des actes de naissance (avec traduction) et justificatif de résidence habituelle ou alternative (ex: attestation de présence en crèche, certificat de scolarité de l’année en cours, jugement statuant sur la garde de l’enfant…) ",
        "Tout document justifiant l'acquisition de la nationalité française de votre époux(se)  lors de votre mariage et lors de votre demande de naturalisation (ex: certificat de nationalité française, copie intégrale de mois de 3 mois d'un acte de naissance avec une mention relative a l'obtention de la nationalité française...) ",
        "Justificatif de domicile de moins de trois mois mentionnant vos deux noms, prénoms et votre adresse actuelle  ",
        "Si vous résidez en France depuis moins de trois ans après votre mariage, vous devez fournir tout document justifiant d’une résidence régulière et ininterrompue en France ",
        "Si vous ne résidez pas en France vous devez fournir un certificat d’inscription de votre conjoint français au registre des Français établis hors de France pendant toute votre période à l’étranger ",
        "Justificatif de votre niveau de connaissance de la langue française au moins équivalent au niveau B1 du Cadre européen commun de référence pour les langues du Conseil de l’Europe: diplômes, test de connaissance du français (TCF) du Centre international d’études pédagogiques ou un test d’évaluation de français (TEF) délivré depuis moins de 3 ans à la date de réception de la chambre de commerce et d’industrie de Paris ",
        "les personnes souffrant d’un handicap, d’un état de santé déficient chronique ou âgées d’au moins 60 ans n’ont pas à produire un justificatif de niveau de connaissance de la langue française (il sera évalué lors d'un entretien) ",
        "Si vous résidez en France depuis moins de 10 ans: fournir un extrait original de casier judiciaire ou un document équivalent délivré par une autorité judiciaire ou administrative compétente du ou des pays dans lesquels vous avez résidé plus de 6 mois consécutivement ou, en cas d’impossibilité absolue avérée de produire ces documents, du pays dont vous avez la nationalité.",
        "Document non exigé pour les réfugiés et les apatrides protégés par l’Office français de protection des réfugiés et apatrides et pour les personnes entrées en France durant leur minorité."
      ],
    nb : [
        ]
  },
  2 :  {
    subtitle : "Liste des documents à fournir",
    style : "cond-list",
    conditions :
      [
        "Photocopie de votre passeport  ",
        "Photocopie recto-verso de votre titre de séjour valide si non ressortissant européen ",
        "Demande de francisation (optionnelle) ",
        "Demande de choix d'un nom de famille pour votre 1er enfant mineur (optionnelle) ",
        "Timbre fiscale de 55€ (27,50€ en Guyane) ",
        "Enveloppe timbrée à votre adresse ",
        "Une lettre « suivie » vierge affranchie à 500 grammes ",
        "Une photo d'identité récente ",
        "Copie originale et intégrale de votre acte de naissance, avec traduction légalisée ou apostillée ",
        "Si votre filiation n'est pas indiquée, Copie originale et intégrale des actes de naissance de vos parents, avec traduction légalisée ou apostillée (et acte de décès en cas de décès) ",
        "Acte de mariage des parents avec traduction légalisée ou apostillée ",
        "Si vous êtes réfugié ou apatride, fournir des pièces d’état civil, originales, délivrées par l’Office français de protection des réfugiés et apatrides ",
        "Si le mariage a eu lieu à l'étranger: la copie intégrale de la transcription de votre acte de mariage délivré par le Service central de l’état civil de Nantes (ou sur les registres consulaires français) ",
        "Si vous avez eu des unions antérieures: les originaux des copies intégrales des actes de tous les mariages ainsi que tout document prouvant la dissolution de ces derniers (acte de divorce, acte de décès…) avec traduction ",
        "Si vous avez des enfants mineurs étrangers: copie intégrale des actes de naissance (avec traduction) et justificatif de résidence habituelle ou alternative (ex: attestation de présence en crèche, certificat de scolarité de l’année en cours, jugement statuant sur la garde de l’enfant…) ",
        "Formulaire cerfa n°15561*01 rempli, daté et signé ",
        "Si vous êtes marié(e): acte de mariage de moins de 3 mois avec traduction légalisée ou apostillée ",
        "Tout document justifiant l'acquisition de la nationalité française de votre descendant  (ex: certificat de nationalité française, copie intégrale de mois de 3 mois d'un acte de naissance avec une mention relative a l'obtention de la nationalité française...) ",
        "si le descendant est votre fils ou votre fille: fournir l’original d'une copie intégrale récente de son acte de naissance, mentionnant les noms des parents. Si l'enfant est adopté, vous devez fournir la copie intégrale du jugement d’adoption. ",
        "Si le descendant est votre petit-enfant ou arrière petit-enfant: fournir l’original d'une  copie intégrale récente de son acte de naissance mentionnant les noms des parents, les originaux des copies intégrales des actes de naissance de ses ascendants (parents, grands-parents) mentionnant le nom des parents et les actes de mariage. Si l’un d’entre eux a été adopté, vous devez produire la copie intégrale du jugement d’adoption. ",
        "Justificatif de domicile de moins de trois mois mentionnant votre nom, prénom et votre adresse actuelle  ",
        "Tout document justifiant votre résidence de manière permanente et régulière en France depuis 25 ans (ex : relevé de carrière délivré par un organisme de sécurité sociale, notification de retraite…). En cas d'absence d'activité professionnelle, vous pouvez donner ceux de votre époux(se) si vous êtes pris en charge par lui/elle ",
        "si vous êtes marié(e): copie du titre de séjour de votre conjoint ou tout document justifiant de sa résidence en France s’il est ressortissant européen ",
        "Si vous avez des enfants mineurs: fournir tous documents justifiant sa résidence en France (ex: attestation de présence en crèche, certificat de scolarité de l'année en cours, jugement statuant sur la garde de l'enfant) ",
        ],
    nb : [
        ]
  },
  3 : {
    subtitle : "Liste des documents à fournir",
    style : "cond-list",
    conditions :
    [
      "Photocopie de votre passeport  ",
      "Photocopie recto-verso de votre titre de séjour valide si non ressortissant européen ",
      "Demande de francisation (optionnelle) ",
      "Demande de choix d'un nom de famille pour votre 1er enfant mineur (optionnelle) ",
      "Timbre fiscale de 55€ (27,50€ en Guyane) ",
      "Enveloppe timbrée à votre adresse ",
      "Une lettre « suivie » vierge affranchie à 500 grammes ",
      "Une photo d'identité récente ",
      "Copie originale et intégrale de votre acte de naissance, avec traduction légalisée ou apostillée ",
      "Si votre filiation n'est pas indiquée, Copie originale et intégrale des actes de naissance de vos parents, avec traduction légalisée ou apostillée (et acte de décès en cas de décès) ",
      "Acte de mariage des parents avec traduction légalisée ou apostillée ",
      "Si vous êtes réfugié ou apatride, fournir des pièces d’état civil, originales, délivrées par l’Office français de protection des réfugiés et apatrides ",
      "Si vous avez eu des unions antérieures: les originaux des copies intégrales des actes de tous les mariages ainsi que tout document prouvant la dissolution de ces derniers (acte de divorce, acte de décès…) avec traduction ",
      "Si vous avez des enfants mineurs étrangers: copie intégrale des actes de naissance (avec traduction) et justificatif de résidence habituelle ou alternative (ex: attestation de présence en crèche, certificat de scolarité de l’année en cours, jugement statuant sur la garde de l’enfant…) ",
      "Formulaire cerfa n°15562*01 rempli, daté et signé ",
      "Si vous êtes marié(e): acte de mariage de moins de 3 mois avec traduction légalisée ou apostillée ",
      "Si votre frère/sœur est français(e) au titre de l'article 21-7 du code civil: photocopie de son certificat de nationalité française (l'original sera demandé lors de l'entretien) ",
      "Si votre frère/sœur est français(e) au titre de l'article 21-11 du code civil, photocopie de mois de 3 mois de son acte de naissance incluant la mention - acquisition de la nationalité française - (l'original sera demandé lors de l'entretien) ",
      "Copie intégrale de moins de trois mois de l'acte de naissance de votre frère/sœur, mentionnant le nom des parents ",
      "Si vous avez les mêmes parents: copie du livret de famille (l'original sera demandé lors de l'entretien) ",
      "Copie intégrale de moins de trois mois de l'acte de naissance de vos parents. Si vous avez un seul parent en commun, il ne faut l'acte de naissance que de ce dernier ",
      "Tous documents justifiant le suivi de scolarité en France: certificats de scolarités couvrant la période de scolarité obligatoire (de 6 à 16 ans) ",
      "Justificatif de domicile de moins de trois mois mentionnant votre nom, prénom et votre adresse actuelle  ",
      "Tout document justifiant votre résidence de manière permanente et régulière en France depuis l'âge de 16 ans (ex :contrats d’apprentissage, attestations de stage, certificats de travail, attestations d’inscription à pôle emploi, relevé de carrière) ",
      "si vous êtes marié(e): copie du titre de séjour de votre conjoint ou tout document justifiant de sa résidence en France s’il est ressortissant européen ",
      "Si vous avez des enfants mineurs: fournir tous documents justifiant sa résidence en France (ex: attestation de présence en crèche, certificat de scolarité de l'année en cours, jugement statuant sur la garde de l'enfant) ",
    ],
    nb : [ ]
  },

  4 : {
    subtitle : "Liste des documents à fournir",
    style : "cond-list",
    conditions : [
      "Photocopie de votre passeport  ",
      "Photocopie recto-verso de votre titre de séjour valide si non ressortissant européen ",
      "Demande de francisation (optionnelle) ",
      "Demande de choix d'un nom de famille pour votre 1er enfant mineur (optionnelle) ",
      "Timbre fiscale de 55€ (27,50€ en Guyane) ",
      "Copie originale et intégrale de votre acte de naissance, avec traduction légalisée ou apostillée ",
      "Si votre filiation n'est pas indiquée, Copie originale et intégrale des actes de naissance de vos parents, avec traduction légalisée ou apostillée (et acte de décès en cas de décès) ",
      "Acte de mariage des parents avec traduction légalisée ou apostillée ",
      "Si vous êtes réfugié ou apatride, fournir des pièces d’état civil, originales, délivrées par l’Office français de protection des réfugiés et apatrides ",
      "Si le mariage a eu lieu à l'étranger: la copie intégrale de la transcription de votre acte de mariage délivré par le Service central de l’état civil de Nantes (ou sur les registres consulaires français) ",
      "Si vous avez eu des unions antérieures: les originaux des copies intégrales des actes de tous les mariages ainsi que tout document prouvant la dissolution de ces derniers (acte de divorce, acte de décès…) avec traduction ",
      "Si vous avez des enfants mineurs étrangers: copie intégrale des actes de naissance (avec traduction) et justificatif de résidence habituelle ou alternative (ex: attestation de présence en crèche, certificat de scolarité de l’année en cours, jugement statuant sur la garde de l’enfant…) ",
      "Justificatif de votre niveau de connaissance de la langue française au moins équivalent au niveau B1 du Cadre européen commun de référence pour les langues du Conseil de l’Europe: diplômes, test de connaissance du français (TCF) du Centre international d’études pédagogiques ou un test d’évaluation de français (TEF) délivré depuis moins de 3 ans à la date de réception de la chambre de commerce et d’industrie de Paris  ",
      "les personnes souffrant d’un handicap, d’un état de santé déficient chronique ou âgées d’au moins 60 ans n’ont pas à produire un justificatif de niveau de connaissance de la langue française (il sera évalué lors d'un entretien) ",
      "Si vous résidez en France depuis moins de 10 ans: fournir un extrait original de casier judiciaire ou un document équivalent délivré par une autorité judiciaire ou administrative compétente du ou des pays dans lesquels vous avez résidé plus de 6 mois consécutivement ou, en cas d’impossibilité absolue avérée de produire ces documents, du pays dont vous avez la nationalité.",
      "Document non exigé pour les réfugiés et les apatrides protégés par l’Office français de protection des réfugiés et apatrides et pour les personnes entrées en France durant leur minorité.",
      "Formulaire cerfa n°12753*02 rempli, daté et signé en double exemplaires ",
      "Si vous êtes marié(e): acte de mariage de moins de 3 mois avec traduction légalisée ou apostillée ",
      "Si vous avez le statut Réfugié : la photocopie du certificat de réfugié ou de l’attestation d’admission au statut de réfugié ou l’attestation de maintien de ce statut ",
      "En cas de mariage, Pacs ou concubinage, les 3 derniers bulletins de salaire du conjoint ou concubin et les 3 derniers avis d’imposition ",
      "Si vous avez conclu un PACS: fournir le récépissé d’enregistrement délivré par le tribunal d’instance ",
      "Si vous êtes séparé(e): fournir la décision de séparation de corps ou l’ordonnance de non-conciliation ",
      "Si vous êtes divorcé(e): fournir la décision de divorce ou l’acte de répudiation accompagné de la preuve du caractère définitif du divorce ou de la répudiation  ",
      "Si vous êtes veuf ou veuve: fournir la copie originale et intégrale de l’acte de décès du conjoint. ",
      "Si vous avez un enfant adopté: fournir le jugement d'adoption ",
      "Si votre conjoint(e) ou concubin(e) ne dépose pas en même temps que vous une demande de naturalisation: fournir une photocopie recto-verso de son titre de séjour et de sa carte nationale d’identité ",
      "Si vos parents vous prennent en charge: fournir une photocopie recto-verso du titre de séjour ou de la carte nationale d’identité des deux parents ",
      "Si vous êtes pris(e) en charge par votre conjoint(e) ou votre concubin(e) ou un tiers, un justificatif des ressources de celui (celle)-ci ainsi qu'une photocopie de sa pièce d'identité ",
      "Si vous êtes dans le cadre d'une procédure de regroupement familial: fournir la décision autorisant l’entrée en France des membres de la famille prise ",
      "Justificatif de domicile si vous êtes locataire: fournir le contrat de location, les 3 dernières quittances de loyer et la dernière facture de téléphone ou d’électricité ",
      "Justificatif de domicile si vous êtes propriétaire: fournir l’attestation de propriété délivrée par le notaire  ",
      "Justificatif de domicile si vous êtes hébergé(e): fournir une attestation d’hébergement, un justificatif de l’identité de la personne qui vous héberge et une quittance de loyer ou de la dernière facture de téléphone ou d’électricité au nom de votre logeur ",
      "Si vous avez des enfants mineurs: fournir tous documents justifiant sa résidence en France (ex: attestation de présence en crèche, certificat de scolarité de l'année en cours, jugement statuant sur la garde de l'enfant) ",
      "Si vous êtes salarié(e) vous devez fournir:",
      "- les certificats de travail (si possible des 3 dernières années)",
      "- le contrat de travail en cours indiquant le salaire, la date d’entrée, l’emploi occupé",
      "- les 3 derniers bulletins de salaire",
      "- En cas de mariage, Pacs ou concubinage les 3 derniers bulletins de salaire du conjoint ou concubin et les 3 derniers avis d’imposition.",
      "Si vous êtes demandeur d’emploi vous devez fournir:",
      "- les justificatifs d’inscription à Pôle emploi",
      "- les 3 derniers bordereaux de versement des indemnités",
      "- tous justificatifs de votre activité professionnelle au cours des 3 dernières années.",
      "Si vous êtes stagiaire vous devez fournir:",
      "- une attestation de l’organisme de formation mentionnant les dates de début et fin de stage",
      "- le dernier bulletin de rémunération ",
      "- tous justificatifs de votre activité professionnelle, au cours des 3 dernières années.",
      "Si vous êtes artisan, commerçant exploitant agricole ou gérant vous devez fournir:",
      "- l’extrait d’immatriculation au registre du commerce ou des métiers",
      "- les statuts de la société pour les dirigeants ou associés",
      "- le bilan financier des 3 derniers exercices",
      "- le bordereau de situation fiscale de la société dont vous êtes actionnaire ou gérant",
      "Si vous exercez une profession libérale vous devez fournir:",
      "- une copie de l’inscription à l’ordre professionnel",
      "- un justificatif des ressources des 3 dernières années",
      "- l’inscription éventuelle au SIRENE",
      "Si vous êtes lycéen(ne) ou étudiant(e) vous devez fournir",
      "- le certificat de scolarité pour l’année en cours ",
      "- la photocopie des diplômes de l’enseignement supérieur obtenus en France ",
      "- si vous êtes boursier: une attestation de versement de bourse incluant le montant",
      "- si vous êtes pris(e) en charge par vos parents, photocopie recto-verso de leur titre de séjour ou de leur carte nationale d’identité et de leur dernier avis d’imposition ou de non imposition. Fournir un justificatif des versements si ils résident à l'étranger",
      "- si vous êtes contractuel de l’enseignement: fournir les contrats des 3 dernières années",
      "Si vous avez des revenus mobiliers ou immobiliers en France et/ou à l’étranger, vous devez fournir:",
      "- une déclaration datée et signée précisant la nature, l’origine et l’évaluation de votre patrimoine",
      "- une attestation bancaire précisant le montant des revenus de votre patrimoine et/ou le montant et la périodicité des versements si vous avez des revenus provenant de l’étranger",
      "Si vous louez des biens immobiliers: fournir la copie du ou des contrats de bail. ",
      "Si vous percevez des prestations sociales; fournir le dernier bordereau de versement des allocations familiales, de l’allocation logement, du revenu de solidarité active ",
      "Si vous êtes retraité(e): fournir le titre de pension et le dernier bordereau de versement ",
      "Si vous êtes une personne handicapée ou en situation d'invalidité, vous devez fournir:",
      "- la décision de la commission des droits et de l’autonomie des personnes handicapées en cours de validité mentionnant le taux d’invalidité, une attestation de travail en structure de travail protégé",
      "- le document délivré par la MDPH attestant de la décision d'allocation de l'AAH et un justificatif de versement par la CAF",
      "- la carte d’invalidité et le bordereau de versement d’une pension ou d’une allocation d’invalidité ",
      "Fournir les 3 derniers avis d’imposition ou de non-imposition et les bulletins de salaire de novembre et décembre correspondant à ces 3 années ",
      "Fournir l’attestation valant bordereau de situation fiscale  P. 237 datée de moins de 3 mois portant sur les 3 dernières années (document délivré par la direction des finances publiques dont vous dépendez)  ",
      "Fournir un état des services pour les anciens combattants et les légionnaires et les décorations et citations obtenues "
    ],
    nb : [ ]
  },
  5 : {
    subtitle : "Liste des documents à fournir",
    style : "cond-list",
    conditions : [
      "Photocopie de votre passeport  ",
      "Photocopie recto-verso de votre titre de séjour valide si non ressortissant européen ",
      "Demande de francisation (optionnelle) ",
      "Demande de choix d'un nom de famille pour votre 1er enfant mineur (optionnelle) ",
      "Timbre fiscale de 55€ (27,50€ en Guyane) ",
      "Copie originale et intégrale de votre acte de naissance, avec traduction légalisée ou apostillée ",
      "Si votre filiation n'est pas indiquée, Copie originale et intégrale des actes de naissance de vos parents, avec traduction légalisée ou apostillée (et acte de décès en cas de décès) ",
      "Acte de mariage des parents avec traduction légalisée ou apostillée ",
      "Si vous êtes réfugié ou apatride, fournir des pièces d’état civil, originales, délivrées par l’Office français de protection des réfugiés et apatrides ",
      "Si le mariage a eu lieu à l'étranger: la copie intégrale de la transcription de votre acte de mariage délivré par le Service central de l’état civil de Nantes (ou sur les registres consulaires français) ",
      "Si vous avez eu des unions antérieures: les originaux des copies intégrales des actes de tous les mariages ainsi que tout document prouvant la dissolution de ces derniers (acte de divorce, acte de décès…) avec traduction ",
      "Si vous avez des enfants mineurs étrangers: copie intégrale des actes de naissance (avec traduction) et justificatif de résidence habituelle ou alternative (ex: attestation de présence en crèche, certificat de scolarité de l’année en cours, jugement statuant sur la garde de l’enfant…) ",
      "Justificatif de votre niveau de connaissance de la langue française au moins équivalent au niveau B1 du Cadre européen commun de référence pour les langues du Conseil de l’Europe: diplômes, test de connaissance du français (TCF) du Centre international d’études pédagogiques ou un test d’évaluation de français (TEF) délivré depuis moins de 3 ans à la date de réception de la chambre de commerce et d’industrie de Paris  ",
      "les personnes souffrant d’un handicap, d’un état de santé déficient chronique ou âgées d’au moins 60 ans n’ont pas à produire un justificatif de niveau de connaissance de la langue française (il sera évalué lors d'un entretien) ",
      "Si vous résidez en France depuis moins de 10 ans: fournir un extrait original de casier judiciaire ou un document équivalent délivré par une autorité judiciaire ou administrative compétente du ou des pays dans lesquels vous avez résidé plus de 6 mois consécutivement ou, en cas d’impossibilité absolue avérée de produire ces documents, du pays dont vous avez la nationalité.",
      "Document non exigé pour les réfugiés et les apatrides protégés par l’Office français de protection des réfugiés et apatrides et pour les personnes entrées en France durant leur minorité.",
      "Formulaire cerfa n°12753*02 rempli, daté et signé en double exemplaires ",
      "Si vous êtes marié(e): acte de mariage de moins de 3 mois avec traduction légalisée ou apostillée ",
      "Si vous avez le statut Réfugié : la photocopie du certificat de réfugié ou de l’attestation d’admission au statut de réfugié ou l’attestation de maintien de ce statut ",
      "En cas de mariage, Pacs ou concubinage, les 3 derniers bulletins de salaire du conjoint ou concubin et les 3 derniers avis d’imposition ",
      "Si vous avez conclu un PACS: fournir le récépissé d’enregistrement délivré par le tribunal d’instance ",
      "Si vous êtes séparé(e): fournir la décision de séparation de corps ou l’ordonnance de non-conciliation ",
      "Si vous êtes divorcé(e): fournir la décision de divorce ou l’acte de répudiation accompagné de la preuve du caractère définitif du divorce ou de la répudiation  ",
      "Si vous êtes veuf ou veuve: fournir la copie originale et intégrale de l’acte de décès du conjoint. ",
      "Si vous avez un enfant adopté: fournir le jugement d'adoption ",
      "Si votre conjoint(e) ou concubin(e) ne dépose pas en même temps que vous une demande de naturalisation: fournir une photocopie recto-verso de son titre de séjour et de sa carte nationale d’identité ",
      "Si vos parents vous prennent en charge: fournir une photocopie recto-verso du titre de séjour ou de la carte nationale d’identité des deux parents ",
      "Si vous êtes pris(e) en charge par votre conjoint(e) ou votre concubin(e) ou un tiers, un justificatif des ressources de celui (celle)-ci ainsi qu'une photocopie de sa pièce d'identité ",
      "Si vous êtes dans le cadre d'une procédure de regroupement familial: fournir la décision autorisant l’entrée en France des membres de la famille prise ",
      "Justificatif de domicile si vous êtes locataire: fournir le contrat de location, les 3 dernières quittances de loyer et la dernière facture de téléphone ou d’électricité ",
      "Justificatif de domicile si vous êtes propriétaire: fournir l’attestation de propriété délivrée par le notaire  ",
      "Justificatif de domicile si vous êtes hébergé(e): fournir une attestation d’hébergement, un justificatif de l’identité de la personne qui vous héberge et une quittance de loyer ou de la dernière facture de téléphone ou d’électricité au nom de votre logeur ",
      "Si vous avez des enfants mineurs: fournir tous documents justifiant sa résidence en France (ex: attestation de présence en crèche, certificat de scolarité de l'année en cours, jugement statuant sur la garde de l'enfant) ",
      "Si vous êtes salarié(e) vous devez fournir:",
      "- les certificats de travail (si possible des 3 dernières années)",
      "- le contrat de travail en cours indiquant le salaire, la date d’entrée, l’emploi occupé",
      "- les 3 derniers bulletins de salaire",
      "- En cas de mariage, Pacs ou concubinage les 3 derniers bulletins de salaire du conjoint ou concubin et les 3 derniers avis d’imposition.",
      "Si vous êtes demandeur d’emploi vous devez fournir:",
      "- les justificatifs d’inscription à Pôle emploi",
      "- les 3 derniers bordereaux de versement des indemnités",
      "- tous justificatifs de votre activité professionnelle au cours des 3 dernières années.",
      "Si vous êtes stagiaire vous devez fournir:",
      "- une attestation de l’organisme de formation mentionnant les dates de début et fin de stage",
      "- le dernier bulletin de rémunération ",
      "- tous justificatifs de votre activité professionnelle, au cours des 3 dernières années.",
      "Si vous êtes artisan, commerçant exploitant agricole ou gérant vous devez fournir:",
      "- l’extrait d’immatriculation au registre du commerce ou des métiers",
      "- les statuts de la société pour les dirigeants ou associés",
      "- le bilan financier des 3 derniers exercices",
      "- le bordereau de situation fiscale de la société dont vous êtes actionnaire ou gérant",
      "Si vous exercez une profession libérale vous devez fournir:",
      "- une copie de l’inscription à l’ordre professionnel",
      "- un justificatif des ressources des 3 dernières années",
      "- l’inscription éventuelle au SIRENE",
      "Si vous êtes lycéen(ne) ou étudiant(e) vous devez fournir",
      "- le certificat de scolarité pour l’année en cours ",
      "- la photocopie des diplômes de l’enseignement supérieur obtenus en France ",
      "- si vous êtes boursier: une attestation de versement de bourse incluant le montant",
      "- si vous êtes pris(e) en charge par vos parents, photocopie recto-verso de leur titre de séjour ou de leur carte nationale d’identité et de leur dernier avis d’imposition ou de non imposition. Fournir un justificatif des versements si ils résident à l'étranger",
      "- si vous êtes contractuel de l’enseignement: fournir les contrats des 3 dernières années",
      "Si vous avez des revenus mobiliers ou immobiliers en France et/ou à l’étranger, vous devez fournir:",
      "- une déclaration datée et signée précisant la nature, l’origine et l’évaluation de votre patrimoine",
      "- une attestation bancaire précisant le montant des revenus de votre patrimoine et/ou le montant et la périodicité des versements si vous avez des revenus provenant de l’étranger",
      "Si vous louez des biens immobiliers: fournir la copie du ou des contrats de bail. ",
      "Si vous percevez des prestations sociales; fournir le dernier bordereau de versement des allocations familiales, de l’allocation logement, du revenu de solidarité active ",
      "Si vous êtes retraité(e): fournir le titre de pension et le dernier bordereau de versement ",
      "Si vous êtes une personne handicapée ou en situation d'invalidité, vous devez fournir:",
      "- la décision de la commission des droits et de l’autonomie des personnes handicapées en cours de validité mentionnant le taux d’invalidité, une attestation de travail en structure de travail protégé",
      "- le document délivré par la MDPH attestant de la décision d'allocation de l'AAH et un justificatif de versement par la CAF",
      "- la carte d’invalidité et le bordereau de versement d’une pension ou d’une allocation d’invalidité ",
      "Fournir les 3 derniers avis d’imposition ou de non-imposition et les bulletins de salaire de novembre et décembre correspondant à ces 3 années ",
      "Fournir l’attestation valant bordereau de situation fiscale  P. 237 datée de moins de 3 mois portant sur les 3 dernières années (document délivré par la direction des finances publiques dont vous dépendez)  ",
      "Fournir un état des services pour les anciens combattants et les légionnaires et les décorations et citations obtenues "
    ],
    nb : [ ]
  },
  6 : {
    subtitle : "Liste des documents à fournir",
    style : "cond-list",
    conditions : [
      "Photocopie de votre passeport  ",
      "Photocopie recto-verso de votre titre de séjour valide si non ressortissant européen ",
      "Demande de francisation (optionnelle) ",
      "Demande de choix d'un nom de famille pour votre 1er enfant mineur (optionnelle) ",
      "Timbre fiscale de 55€ (27,50€ en Guyane) ",
      "Copie originale et intégrale de votre acte de naissance, avec traduction légalisée ou apostillée ",
      "Si votre filiation n'est pas indiquée, Copie originale et intégrale des actes de naissance de vos parents, avec traduction légalisée ou apostillée (et acte de décès en cas de décès) ",
      "Acte de mariage des parents avec traduction légalisée ou apostillée ",
      "Si vous êtes réfugié ou apatride, fournir des pièces d’état civil, originales, délivrées par l’Office français de protection des réfugiés et apatrides ",
      "Si le mariage a eu lieu à l'étranger: la copie intégrale de la transcription de votre acte de mariage délivré par le Service central de l’état civil de Nantes (ou sur les registres consulaires français) ",
      "Si vous avez eu des unions antérieures: les originaux des copies intégrales des actes de tous les mariages ainsi que tout document prouvant la dissolution de ces derniers (acte de divorce, acte de décès…) avec traduction ",
      "Si vous avez des enfants mineurs étrangers: copie intégrale des actes de naissance (avec traduction) et justificatif de résidence habituelle ou alternative (ex: attestation de présence en crèche, certificat de scolarité de l’année en cours, jugement statuant sur la garde de l’enfant…) ",
      "Justificatif de votre niveau de connaissance de la langue française au moins équivalent au niveau B1 du Cadre européen commun de référence pour les langues du Conseil de l’Europe: diplômes, test de connaissance du français (TCF) du Centre international d’études pédagogiques ou un test d’évaluation de français (TEF) délivré depuis moins de 3 ans à la date de réception de la chambre de commerce et d’industrie de Paris  ",
      "les personnes souffrant d’un handicap, d’un état de santé déficient chronique ou âgées d’au moins 60 ans n’ont pas à produire un justificatif de niveau de connaissance de la langue française (il sera évalué lors d'un entretien) ",
      "Si vous résidez en France depuis moins de 10 ans: fournir un extrait original de casier judiciaire ou un document équivalent délivré par une autorité judiciaire ou administrative compétente du ou des pays dans lesquels vous avez résidé plus de 6 mois consécutivement ou, en cas d’impossibilité absolue avérée de produire ces documents, du pays dont vous avez la nationalité.",
      "Document non exigé pour les réfugiés et les apatrides protégés par l’Office français de protection des réfugiés et apatrides et pour les personnes entrées en France durant leur minorité.",
      "Formulaire cerfa n°12753*02 rempli, daté et signé en double exemplaires ",
      "Si vous êtes marié(e): acte de mariage de moins de 3 mois avec traduction légalisée ou apostillée ",
      "Si vous avez le statut Réfugié : la photocopie du certificat de réfugié ou de l’attestation d’admission au statut de réfugié ou l’attestation de maintien de ce statut ",
      "En cas de mariage, Pacs ou concubinage, les 3 derniers bulletins de salaire du conjoint ou concubin et les 3 derniers avis d’imposition ",
      "Si vous avez conclu un PACS: fournir le récépissé d’enregistrement délivré par le tribunal d’instance ",
      "Si vous êtes séparé(e): fournir la décision de séparation de corps ou l’ordonnance de non-conciliation ",
      "Si vous êtes divorcé(e): fournir la décision de divorce ou l’acte de répudiation accompagné de la preuve du caractère définitif du divorce ou de la répudiation  ",
      "Si vous êtes veuf ou veuve: fournir la copie originale et intégrale de l’acte de décès du conjoint. ",
      "Si vous avez un enfant adopté: fournir le jugement d'adoption ",
      "Si votre conjoint(e) ou concubin(e) ne dépose pas en même temps que vous une demande de naturalisation: fournir une photocopie recto-verso de son titre de séjour et de sa carte nationale d’identité ",
      "Si vos parents vous prennent en charge: fournir une photocopie recto-verso du titre de séjour ou de la carte nationale d’identité des deux parents ",
      "Si vous êtes pris(e) en charge par votre conjoint(e) ou votre concubin(e) ou un tiers, un justificatif des ressources de celui (celle)-ci ainsi qu'une photocopie de sa pièce d'identité ",
      "Si vous êtes dans le cadre d'une procédure de regroupement familial: fournir la décision autorisant l’entrée en France des membres de la famille prise ",
      "Justificatif de domicile si vous êtes locataire: fournir le contrat de location, les 3 dernières quittances de loyer et la dernière facture de téléphone ou d’électricité ",
      "Justificatif de domicile si vous êtes propriétaire: fournir l’attestation de propriété délivrée par le notaire  ",
      "Justificatif de domicile si vous êtes hébergé(e): fournir une attestation d’hébergement, un justificatif de l’identité de la personne qui vous héberge et une quittance de loyer ou de la dernière facture de téléphone ou d’électricité au nom de votre logeur ",
      "Si vous avez des enfants mineurs: fournir tous documents justifiant sa résidence en France (ex: attestation de présence en crèche, certificat de scolarité de l'année en cours, jugement statuant sur la garde de l'enfant) ",
      "Si vous êtes salarié(e) vous devez fournir:",
      "- les certificats de travail (si possible des 3 dernières années)",
      "- le contrat de travail en cours indiquant le salaire, la date d’entrée, l’emploi occupé",
      "- les 3 derniers bulletins de salaire",
      "- En cas de mariage, Pacs ou concubinage les 3 derniers bulletins de salaire du conjoint ou concubin et les 3 derniers avis d’imposition.",
      "Si vous êtes demandeur d’emploi vous devez fournir:",
      "- les justificatifs d’inscription à Pôle emploi",
      "- les 3 derniers bordereaux de versement des indemnités",
      "- tous justificatifs de votre activité professionnelle au cours des 3 dernières années.",
      "Si vous êtes stagiaire vous devez fournir:",
      "- une attestation de l’organisme de formation mentionnant les dates de début et fin de stage",
      "- le dernier bulletin de rémunération ",
      "- tous justificatifs de votre activité professionnelle, au cours des 3 dernières années.",
      "Si vous êtes artisan, commerçant exploitant agricole ou gérant vous devez fournir:",
      "- l’extrait d’immatriculation au registre du commerce ou des métiers",
      "- les statuts de la société pour les dirigeants ou associés",
      "- le bilan financier des 3 derniers exercices",
      "- le bordereau de situation fiscale de la société dont vous êtes actionnaire ou gérant",
      "Si vous exercez une profession libérale vous devez fournir:",
      "- une copie de l’inscription à l’ordre professionnel",
      "- un justificatif des ressources des 3 dernières années",
      "- l’inscription éventuelle au SIRENE",
      "Si vous êtes lycéen(ne) ou étudiant(e) vous devez fournir",
      "- le certificat de scolarité pour l’année en cours ",
      "- la photocopie des diplômes de l’enseignement supérieur obtenus en France ",
      "- si vous êtes boursier: une attestation de versement de bourse incluant le montant",
      "- si vous êtes pris(e) en charge par vos parents, photocopie recto-verso de leur titre de séjour ou de leur carte nationale d’identité et de leur dernier avis d’imposition ou de non imposition. Fournir un justificatif des versements si ils résident à l'étranger",
      "- si vous êtes contractuel de l’enseignement: fournir les contrats des 3 dernières années",
      "Si vous avez des revenus mobiliers ou immobiliers en France et/ou à l’étranger, vous devez fournir:",
      "- une déclaration datée et signée précisant la nature, l’origine et l’évaluation de votre patrimoine",
      "- une attestation bancaire précisant le montant des revenus de votre patrimoine et/ou le montant et la périodicité des versements si vous avez des revenus provenant de l’étranger",
      "Si vous louez des biens immobiliers: fournir la copie du ou des contrats de bail. ",
      "Si vous percevez des prestations sociales; fournir le dernier bordereau de versement des allocations familiales, de l’allocation logement, du revenu de solidarité active ",
      "Si vous êtes retraité(e): fournir le titre de pension et le dernier bordereau de versement ",
      "Si vous êtes une personne handicapée ou en situation d'invalidité, vous devez fournir:",
      "- la décision de la commission des droits et de l’autonomie des personnes handicapées en cours de validité mentionnant le taux d’invalidité, une attestation de travail en structure de travail protégé",
      "- le document délivré par la MDPH attestant de la décision d'allocation de l'AAH et un justificatif de versement par la CAF",
      "- la carte d’invalidité et le bordereau de versement d’une pension ou d’une allocation d’invalidité ",
      "Fournir les 3 derniers avis d’imposition ou de non-imposition et les bulletins de salaire de novembre et décembre correspondant à ces 3 années ",
      "Fournir l’attestation valant bordereau de situation fiscale  P. 237 datée de moins de 3 mois portant sur les 3 dernières années (document délivré par la direction des finances publiques dont vous dépendez)  ",
      "Fournir un état des services pour les anciens combattants et les légionnaires et les décorations et citations obtenues "
    ],
    nb : [ ]
  },

  7 : {
    subtitle : "Prendre contact avec le Tribunal d'instance",
    style : "cond-list",
    conditions : 
    [
      "Les parents doivent contacter le Tribunal d’instance du domicile compétent et prendre rendez-vous avec le greffe du tribunal afin d’avoir la liste des documents à fournir. C’est le greffier en chef qui est en charge de l’étude et de la validation du dossier.",
      "Que faire en cas de refus d’enregistrement ? Si le greffier en chef refuse l’enregistrement de la déclaration, vous pouvez contester ce refus devant le Tribunal de grande instance."
    ],
    nb :
    [
      "Si l’un des parents étrangers est né en France, l’enfant est automatique de nationalité française."
    ]
  },
  8 : {
    subtitle : "Prendre contact avec le Tribunal d'instance",
    style : "cond-list",
    conditions : 
    [
      "Les parents doivent contacter le Tribunal d’instance du domicile compétent et prendre rendez-vous avec le greffe du tribunal afin d’avoir la liste des documents à fournir. C’est le greffier en chef qui est en charge de l’étude et de la validation du dossier.",
      "Que faire en cas de refus d’enregistrement ? Si le greffier en chef refuse l’enregistrement de la déclaration, vous pouvez contester ce refus devant le Tribunal de grande instance."
    ],
    nb :
    [
      "Si l’un des parents étrangers est né en France, l’enfant est automatique de nationalité française."
    ]
  },
  9 : {
    subtitle : "Il n'y a pas de documents à fournir",
    style : "cond-none",
    conditions : 
    [
      "Si toutes les conditions de la rubrique précédente sont remplies, le jeune adulte n’a aucune démarche à suivre pour acquérir la nationalité française : il est automatiquement français.",
      "Afin de justifier l’obtention de sa nationalité, le jeune adulte doit demander un certificat de nationalité française auprès du greffier en chef du Tribunal d’instance de son domicile.",
      "C’est grâce à ce document qu’il pourra obtenir une carte nationale d'identité et un passeport."
    ],
    nb : [ ]
  },
  10 : {
    subtitle : "Prendre contact avec le Tribunal d'instance",
    style : "cond-list",
    conditions :
    [
      "Les parents doivent contacter le Tribunal d’instance du domicile compétent et prendre rendez-vous avec le greffe du tribunal afin d’avoir la liste des documents à fournir. C’est le greffier en chef qui est en charge de l’étude et de la validation du dossier.",
      "A titre informatif, les pièces à fournir sont généralement :",
      "- la déclaration de nationalité, datée et signée, en 2 exemplaires",
      "- l’extrait d'acte de naissance de l'enfant",
      "- Tous documents justifiants la résidence permanente et régulière de l'enfant réside en France (ou à l’étranger si l'adoptant français réside à l'étranger)",
      "- Si l'enfant a moins de 16 ans, fournir tous documents prouvant que son ou ses représentants exercent à son égard une autorité parentale",
      "- Tous documents justifiant que l'adoptant possédait la nationalité française à la date de l'adoption de l'enfant (certificat de nationalité française, actes de l'état civil...)",
      "- Copie authentique du jugement ou de l'arrêt prononçant l'adoption",
      "- En cas d'adoption prononcée à l'étranger, la décision d'exequatur du juge français",
      "Que faire en cas de refus d’enregistrement ? Si le greffier en chef refuse l’enregistrement de la déclaration, vous pouvez contester ce refus devant le Tribunal de grande instance.",
    ],
    nb : [
      "Avant les 16 ans de l'enfant, les parents adoptifs peuvent faire la déclaration au nom de l'enfant. Dès l'âge de 16 ans, l'enfant peut faire la déclaration lui-même, sans autorisation parentale",
    ]
  },
  11 : {
    subtitle : "Prendre contact avec le Tribunal d'instance",
    style : "cond-list",
    conditions :
    [
      "Les parents doivent contacter le Tribunal d’instance du domicile compétent et prendre rendez-vous avec le greffe du tribunal afin d’avoir la liste des documents à fournir. C’est le greffier en chef qui est en charge de l’étude et de la validation du dossier.",
      "A titre informatif, les pièces à fournir sont généralement :",
      "- la déclaration de nationalité, datée et signée, en 2 exemplaires",
      "- l’extrait d'acte de naissance de l'enfant",
      "- Tous documents justifiants la résidence permanente et régulière de l'enfant réside en France (ou à l’étranger si l'adoptant français réside à l'étranger)",
      "- Si l'enfant a moins de 16 ans, fournir tous documents prouvant que son ou ses représentants exercent à son égard une autorité parentale",
      "- Si nécessaire, fournir tous documents administratifs ou notification de la décision de justice indiquant que l'enfant a été confié au service de l'Ase depuis au moins 3 ans",
      "- Tous documents attestant que l'enfant a été recueilli et élevé par un(e) Français(e) depuis au moins 3 ans",
      "- Tous documents attestant que l'enfant a reçu une formation française pendant au moins 5 ans",
      "Que faire en cas de refus d’enregistrement ? Si le greffier en chef refuse l’enregistrement de la déclaration, vous pouvez contester ce refus devant le Tribunal de grande instance.",
    ],
    nb : [
      "Avant les 16 ans de l'enfant, les parents adoptifs peuvent faire la déclaration au nom de l'enfant. Dès l'âge de 16 ans, l'enfant peut faire la déclaration lui-même, sans autorisation parentale",
    ]
  },
}

export default class PreparerDossierNaturalisation extends Component{
  constructor(props){
    super(props);
    this.state= {
      session : this.props.session,
      res : 0,
    }
  }

  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/constituer-dossier/preparer-dossier-naturalisation');
  }


  showResult(res){
    return(
      <div>
        <CheckListConditions
                conditions={result[res].conditions}
                subtitle= {result[res].subtitle}
                nb= {result[res].nb}
                subtitle2= "À noter"
                listtype= {result[res].style}
                />
      </div>
    )
  }

  render(){
    return(
      <div className="constituer-main-container preparer-dossier">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <link rel="canonical" href={this.props.pageCanonicalLink} />
          <meta name="description" content={this.props.pageDescription} />
      </Helmet>
        <div className="top-image procedure">
            <div className="top-image-title">
              <h1> Préparer son dossier de naturalisation </h1>
            </div>
        </div>

      <div className="constituer-content  preparer-dossier">

        <div className="constituer-content-header  preparer-dossier">
          <p> Vous connaissez la procédure que correspond à votre situation et vous répondez à toutes les conditions nécessaires ?<br/> Il est temps de vous consacrer à la constitution de votre dossier. </p>
        </div>



        <div className="constituer-section  preparer-dossier section-1">
          <div className="constituer-section  preparer-dossier section-1-container">
            <p> Quels documents dois-je mettre dans mon dossier ? </p>
            <NatCases showResult={this.showResult} setRes={this.setRes}/>
          </div>
        </div>
      </div>
    </div>

    )}
}
