import React,{Component} from 'react';
import { Alert } from 'reactstrap';
import axios from 'axios';
import {Helmet} from 'react-helmet';

import ReactGA from 'react-ga';

export default class Validate extends Component{
  constructor(props){
    super(props);
    this.state = {
      validCode : true,
    };
    this.validateCode = this.validateCode.bind(this);
    let validationCode = this.props.match.params.code;
    this.validateCode(validationCode);
  }

  componentWillReceiveProps(){
      this.validateCode();
  }

  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/validate-registration');
  }

  validateCode(validationCode){
    var self = this;
    axios({
              method: 'POST',
              url : "https://www.natification.fr/serv/api/client/validate_code.php",
              headers: {
                'Content-Type': 'application/json'
              },
              data: {
                'valid': validationCode
              }
            })
      .then(function (response) {
        if(response.data.state==="0x00"){
          self.setState({
            validCode : false,
          });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render(){
    return(
    <div className="login-page-content">
    <Helmet>
        <meta charSet="utf-8" />
        <title>{this.props.pageTitle}</title>
        <link rel="canonical" href={this.props.pageCanonicalLink} />
        <meta name="description" content={this.props.pageDescription} />
    </Helmet>
      <div className="page-content">
        {this.state.validCode?
        <div className="content-login">
          <Alert color="success"> Vous pouvez désormais utiliser votre compte NATIFICATION </Alert>
        </div>
        :
        <div className="content-login">
          <Alert color="danger"> Erreur de validation </Alert>
        </div>
        }
        <a class="register-link" href="/login"> Me connecter </a>
      </div>
    </div>
  )}
}
