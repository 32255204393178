import React, {Component} from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import './livret.css';
import {QCMReminder} from '../QCMReminder/qcm-reminder';
import SwipeCards from './LivretCards/SwipeCards';
import LivretChapitresMenu from './MenuChapitres/Chapitres';
import {Helmet} from "react-helmet";

export default class LivretDuCitoyenDevise extends Component{
  constructor(props){
    super(props);

    this.state= {
      session : this.props.session
    }
  }
  render(){
    return(
      <div className="livret-main-container">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <link rel="canonical" href={this.props.pageCanonicalLink} />
          <meta name="description" content={this.props.pageDescription} />
      </Helmet>
        <Breadcrumb>
          <BreadcrumbItem><a href="/preparer-entretien">Préparer mon entretien</a></BreadcrumbItem>
          <BreadcrumbItem><a href="/preparer-entretien/culture-generale">Culture générale</a></BreadcrumbItem>
          <BreadcrumbItem active>La devise</BreadcrumbItem>
        </Breadcrumb>
        <div className="top-image livret">
          <div className="top-image-title">
            <h2> Préparer mon entretien : <span>La devise</span></h2>
          </div>
        </div>

        {
          this.props.session?
        <div className="livret-content">
          <LivretChapitresMenu/>



          <div className="livret-content-header">
            <h2> Liberté Égalité Fraternité </h2><br/>
            <p>La République garantit le respect des principes de liberté, d’égalité et de fraternité.<br/><span className="effect quoting-focus"> Ces trois mots constituent sa devise.</span></p>
          </div>

          <img src="https://upload.wikimedia.org/wikipedia/commons/f/fe/Libert%C3%A9%2C_%C3%A9galit%C3%A9%2C_fraternit%C3%A9.jpg" alt="devise"/>


          <div className="livret-section section-1">
            <div id="swip-c">
              <SwipeCards slides={slides}/>
            </div>

            <div className="qcm-reminder">
              <QCMReminder/>
            </div>
          </div>


          <div className="livret-section section-end">
              <span> Chapitre précédent :</span>
              <span> </span>
              <span> Chapitre suivant : </span>
             <div className="chap-prev"> <a href="/preparer-entretien/culture-generale-part1"><i className="fas fa-arrow-left"></i> Les valeurs de la République </a></div>
             <br/>
             <div className="chap-next"> <a href="/preparer-entretien/culture-generale-part3"> Le système démocratique <i className="fas fa-arrow-right"></i> </a></div>
          </div>

        </div>
        :
        <div className="offline-content livret">
          <h2> Veuillez vous connecter </h2>
          <p> Cette section n'est disponible que pour les membres du site </p>
          <a href="/login"> Me connecter </a> ou <a href="/register"> Créer un compte </a>
        </div>
        }
      </div>


  )}
}

const slides = [
  {
    city: 'Liberté',
    first : 'La République Françaises garantit toutes les libertés fondamentales  telles que: la liberté de pensée, la liberté de croyance, la liberté de s’exprimer ou encore la liberté de se réunir',
    img: '#027da4',
    description: "La liberté d’expression est un droit fondamental mais qui a des limites ! La liberté des uns s'arrête là où commence celle des autres. Il est interdit de diffuser des injures, des propos diffamatoires, des provocations à la haine, ou de faire l’apologie de crimes contre l’humanité."
  },
  {
    city: 'Égalité',
    img: "#bfa600",
    first : "Tous les citoyens ont les mêmes droits et les mêmes devoirs quels que soient leur sexe, leur origine, leur religion, leurs opinions ou leur orientation sexuelle. La DécLaration des Droits de l’Homme et du Citoyen a pour objectif de garantir la liberté et l'égalité entre tous les citoyens",
    description: "La nationalité française peut être refusée à toute personne ne respectant pas l'égalité homme/femme"

  },
  {
    city: 'Fraternité',
    first : "La fraternité est la volonté que tous les citoyens français vivent ensemble, sans la moindre discrimination et en toute solidarité. Le racisme est un délit! toute forme de discrimination est condamnée en France. Le système de sécurité sociale est un basé sur ce principe.",
    img: "#bf3b3b",
    description: "Un employeur ne peut refuser d’embaucher un salarié en raison de ses origines, de son appartenance à une ethnie ou à une religion ou de son sexe."

  },
  {
    city: '( Laïcité )',
    first :"La France devint laïque en 1905. La laïcité est un principe fondamental de la République. La Laïcité est régie par plusieurs principes: La liberté de conscience, la séparation entre les pouvoirs publics et religieux, l’égalité devant la loi quelque soit la religion, l’état garantit la liberté religieuse et l’état est neutre envers les religions. " ,
    img: "#023c8a",
    description: "Il est interdit aux élèves des écoles, collèges et lycées de porter des signes religieux dans les établissements scolaires depuis la loi du 15 mars 2014. L'école est une institution publique qui forme les citoyens de demain. Elle doit de ce fait être neutre face aux religions"

  },
];
