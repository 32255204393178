import React, {Component} from 'react';

const badRes = ["Quelques révisions s'imposent !", "Ne perdez pas espoir, vous allez y arriver !"];
const readMore = ["Vous êtes sur la bonne voie !", "Il faut persévérer en révisant votre culture générale et en vous entraînant !"];
const goodRes = ["Vous y êtes presque !","Encore quelques efforts pour devenir imbattable"];
const veryGoodRes = ["Félicitations ! vous n'avez que des bonnes réponses !", "Continuez à vous entraîner afin de mettre toutes les chances de votre côté pour votre entretien !"];

export default class ScoreAdvice extends Component{
  constructor(props){
    super(props);
    this.setContent = this.setContent.bind(this);
  }

  setContent(){
    if(0 < this.props.score < 11 ){
      return (<div><h4> {badRes[0]} </h4><br/> <p> {badRes[1]} </p></div>)
    }
    if( 11 < this.props.score < 16 ){
      return (<div><h4> {readMore[0]} </h4><br/> <p> {readMore[1]} </p></div>)
    }
    if( 16 < this.props.score < 19 ){
      return (<div><h4> {goodRes[0]} </h4><br/> <p> {goodRes[1]} </p></div>)
    }
    else{
      return (<div><h4> {veryGoodRes[0]} </h4><br/> <p> {veryGoodRes[1]} </p></div>)
    }
  }

  render(){
    return(
      <div className="adivce-part">
        {this.setContent()}
      </div>
  )}
}
