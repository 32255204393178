import React, {Component} from 'react';
import './constituerDossier.css';
import ReactGA from 'react-ga';
import {Button} from 'reactstrap';
import {Helmet} from "react-helmet";

export default class ProcedureNaturalisation extends Component{
  constructor(props){
    super(props);
    this.state= {
      session : this.props.session,
      step : 1,
      type : null,
      result : "",
    }
    this.handleChangeStep = this.handleChangeStep.bind(this);
    this.reinit = this.reinit.bind(this);
  }

  handleChangeStep(event) {
    console.log(event.target.value);
      this.setState({
        type: event.target.value
      });
      if(event.target.value === "adult"){
        this.setState({
          step : this.state.step+1,
        })
      }

      if(event.target.value === "adult_marie" || event.target.value === "adult_ascendant" || event.target.value === "adult_frere" ){
        this.setState({
          step : this.state.step+1,
          result : "DÉCLARATION",
        })
      }

      if(event.target.value === "adult_aucun"){
        this.setState({
          step : this.state.step+1,
          result : "DÉCRET",
        })
      }

      if(event.target.value === "child"){
        this.setState({
          step : this.state.step+10,
        })
      }

      if(event.target.value === "child_born_in_fr"){
        this.setState({
          step : this.state.step+1,
        })
      }
      if(event.target.value === "parent_born_in_fr"){
        this.setState({
          step : this.state.step+2,
          result : "L'enfant est français de naissance par le principe du « double droit du sol »",
        })
      }

      if(event.target.value === "parent_born_else" || event.target.value === "child_adopted" || event.target.value === "child_acc" ){
        this.setState({
          step : this.state.step+1,
          result : "Une déclaration auprès du Tribunal d’Instance",
        })
      }




  }

  reinit(){
    this.setState({
      step : 1,
    })
  }

  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/constituer-dossier/procedure-de-naturalisation');
  }


  render(){
    return(
      <div className="constituer-main-container procedure">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <link rel="canonical" href={this.props.pageCanonicalLink} />
          <meta name="description" content={this.props.pageDescription} />
      </Helmet>
        <div className="top-image procedure">
            <div className="top-image-title">
              <h1> Choisir sa procédure de naturalisation </h1>
            </div>
        </div>

      <div className="constituer-content procedure">

        <div className="constituer-content-header procedure">
          <p> L’obtention de la nationalité française peut se faire de différentes manières car les démarches varient en fonction de votre situation personnelle.
          <br/> La première étape est donc de définir la procédure que vous allez utiliser.</p>
        </div>


        <div className="constituer-section procedure section-1">
          <h2> Trouvez votre procédure </h2>
          <div className="constituer-section procedure section-1-container">
            <div className="form-selection">
              {this.state.step === 1?
                <ul>
                  <li className="list-spaced">
                    <label>
                      <input
                        type="radio"
                        value="adult"
                        checked={this.state.type === "adult"}
                        onChange={this.handleChangeStep}
                      />
                      La procédure concerne un adulte
                    </label>
                  </li>
                  <li className="list-spaced">
                    <label>
                      <input
                        type="radio"
                        value="child"
                        checked={this.state.type === "child"}
                        onChange={this.handleChangeStep}
                      />
                      La procédure concerne un enfant
                    </label>
                  </li>
                </ul>
              :
              ""
              }

              {this.state.step === 2?
                <ul>
                  <li className="list-spaced">
                    <label>
                      <input
                        type="radio"
                        value="adult_marie"
                        checked={this.state.adult_type === "adult_marie"}
                        onChange={this.handleChangeStep}
                      />
                      Vous êtes marié(e) à un citoyen français
                    </label>
                  </li>
                  <li className="list-spaced">
                    <label>
                      <input
                        type="radio"
                        value="adult_ascendant"
                        checked={this.state.adult_type === "adult_ascendant"}
                        onChange={this.handleChangeStep}
                      />
                      Vous êtes ascendant(e) d’une personne française
                    </label>
                  </li>
                  <li className="list-spaced">
                    <label>
                      <input
                        type="radio"
                        value="adult_frere"
                        checked={this.state.adult_type === "adult_frere"}
                        onChange={this.handleChangeStep}
                      />
                      Vous êtes frère ou sœur d’une personne française
                    </label>
                  </li>
                  <li className="list-spaced">
                    <label>
                      <input
                        type="radio"
                        value="adult_aucun"
                        checked={this.state.adult_type === "adult_aucun"}
                        onChange={this.handleChangeStep}
                      />
                      Vous n'êtes dans aucune des trois situations citées au-dessus
                    </label>
                  </li>
                </ul>
              :
              ""
              }
              {this.state.step === 3?
                <div>
                  <div className="procedure-form-result">
                    <p> Vous devez <br/> demander la nationalité française par <br/> <span> {this.state.result}</span> </p>
                  </div>
                  <div>
                    Vous devez maintenant <br/><a href="/constituer-dossier/conditions-naturalisation"> <h4>vérifier les conditions à remplir <i className="fas fa-angle-right"></i> </h4></a>
                  </div>
                </div>
                :
                ""
              }

              {this.state.step === 11?
                <ul>
                  <li className="list-spaced">
                    <label>
                      <input
                        type="radio"
                        value="child_born_in_fr"
                        checked={this.state.child_type === "child_born_in_fr"}
                        onChange={this.handleChangeStep}
                      />
                      L’enfant est né en France de parents étrangers
                    </label>
                  </li>
                  <li className="list-spaced">
                    <label>
                      <input
                        type="radio"
                        value="child_adopted"
                        checked={this.state.child_type === "child_adopted"}
                        onChange={this.handleChangeStep}
                      />
                      L’enfant est adopté
                    </label>
                  </li>
                  <li className="list-spaced">
                    <label>
                      <input
                        type="radio"
                        value="child_acc"
                        checked={this.state.child_type === "child_acc"}
                        onChange={this.handleChangeStep}
                      />
                      L’enfant est accuelli
                    </label>
                  </li>
                </ul>
                :
                ""
              }
              {this.state.step === 12?
                <ul>
                  <li className="list-spaced">
                    <label>
                      <input
                        type="radio"
                        value="parent_born_in_fr"
                        checked={this.state.child_type === "parent_born_in_fr"}
                        onChange={this.handleChangeStep}
                      />
                      Un des parents est né en France
                    </label>
                  </li>
                  <li className="list-spaced">
                    <label>
                      <input
                        type="radio"
                        value="parent_born_else"
                        checked={this.state.child_type === "parent_born_else"}
                        onChange={this.handleChangeStep}
                      />
                      Aucun des deux parents n'est né en France
                    </label>
                  </li>
                </ul>
                :
                ""
              }
              {this.state.step === 13 || this.state.step === 14  ?
                <div>
                  <div className="procedure-form-result">
                    <p> Vous devez faire <br/> <span> {this.state.result}</span> </p>
                  </div>
                  <div>
                    Vous devez ensuite <br/> <a href="/constituer-dossier/conditions-naturalisation"><h4>vérifier les conditions à remplir <i className="fas fa-angle-right"></i> </h4></a>
                  </div>
                </div>
              :
              ""
              }
            </div>

            {this.state.step > 1 ?
              <Button outline color="info" className="form-restart" onClick={this.reinit}> Recommencer </Button>
            :
            ""}
          </div>
        </div>
      </div>
    </div>

    )}
}
