import React, {Component} from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import './livret.css';
import {QCMReminder} from '../QCMReminder/qcm-reminder';
import LivretChapitresMenu from './MenuChapitres/Chapitres';
import InfographieFrance from './Infographie/Infographie';
import {Helmet} from "react-helmet";

export default class LivretDuCitoyenRayonnement extends Component{
  constructor(props){
    super(props);

    this.state= {
      session : this.props.session
    }
  }
  render(){
    return(
      <div className="livret-main-container">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <link rel="canonical" href={this.props.pageCanonicalLink} />
          <meta name="description" content={this.props.pageDescription} />
      </Helmet>
        <Breadcrumb>
          <BreadcrumbItem><a href="/preparer-entretien">Préparer mon entretien</a></BreadcrumbItem>
          <BreadcrumbItem><a href="/preparer-entretien/culture-generale">Culture générale</a></BreadcrumbItem>
          <BreadcrumbItem active>La France en chiffres</BreadcrumbItem>
        </Breadcrumb>
        <div className="top-image livret">
          <div className="top-image-title">
            <h2> Préparer mon entretien : <span>La France en chiffres</span></h2>
          </div>
        </div>

        {
          this.props.session?
        <div className="livret-content">
        <LivretChapitresMenu/>

          <div className="livret-content-header">

          </div>



          <div className="livret-section section-1">
          <h3> La France en chiffres </h3>
          <p> Nous allons présenter ici quelques chiffres qui caractérisent la France.</p>
          <p> Explorer les en parcourant les images.</p>
          <br/><br/>
            <InfographieFrance/>

            <div className="qcm-reminder">
              <QCMReminder/>
            </div>
          </div>


          <div className="livret-section section-end">
              <span> Chapitre précédent :</span>
              <span> </span>
              <span> Chapitre suivant : </span>
             <div className="chap-prev"> <a href="/preparer-entretien/culture-generale-part5"><i className="fas fa-arrow-left"></i> Événements et histoire de France</a></div>
             <br/>
             <div className="chap-next"> <a href="/preparer-entretien/culture-generale-part7"> Eux aussi ont été naturalisés <i className="fas fa-arrow-right"></i> </a></div>
          </div>

        </div>
        :
        <div className="offline-content livret">
          <h2> Veuillez vous connecter </h2>
          <p> Cette section n'est disponible que pour les membres du site </p>
          <a href="/login"> Me connecter </a> ou <a href="/register"> Créer un compte </a>
        </div>
        }
      </div>


  )}
}
