import React, {Component} from 'react';
import {Button} from 'reactstrap';
import './landingQuizz.css';
import Quiz from 'react-quiz-component';
import ReactGA from 'react-ga';
import ScoreAdvice from './ScoreAdvice';
import {Helmet} from "react-helmet";

import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();
const goodAnswerTxt = "Bonne réponse !";

export default class LandingQuizz extends Component{
  constructor(props){
    super(props);
    this.state= {
      session : this.props.session,
      quizzLaunched : false,
      quizz :  {
        "quizTitle": "Quizz",
        "quizSynopsis": "Prenez votre temps pour répondre à chaque question. Après chaque question, si votre réponse est fausse, nous vous indiquerons la réponse qui était attendue.",
      },
      quizzFinished : false,
      quizzResult : 0,
    }
    this.launchQuizz = this.launchQuizz.bind(this);
  }

  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/quizz');
  }

  launchQuizz(){
    this.setState({
      quizzLaunched : true
    })
  }

  onCompleteAction = (obj) => {
    this.pushScore(obj['numberOfCorrectAnswers']);
    this.setState({
      quizzFinished : true,
      quizzResult : obj['numberOfCorrectAnswers'],
    })
  }

  componentWillReceiveProps(){
      this.retreiveData();
  }

  async pushScore(val){
    let c_jwt  = cookies.get("jwt").split("=")[0];
    axios({
              method: 'POST',
              url : "https://www.natification.fr/serv/api/quizz/save.php",
              headers: {
                'Content-Type': 'application/json'
              },
              data: {
                'jwt': c_jwt,
                'score' : val,
              }
            })
      .then(function (response) {
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  retreiveData(){
    let c_jwt  = cookies.get("jwt").split("=")[0];
    var self = this;
    axios({
              method: 'POST',
              url : "https://www.natification.fr/serv/api/quizz/quizz.php",
              headers: {
                'Content-Type': 'application/json'
              },
              data: {
                'jwt': c_jwt
              }
            })
      .then(function (response) {
        var records = [];
        response.data.records.forEach(function(item, index, array) {
          records.push([item['quest'],item['ans'],[item['ans'],item['opt1'],item['opt2']].sort(() => Math.random() - 0.5)]);
        });
        self.appendQuestions(records)
      })
      .catch(function (error) {
        self.setState({
          error : true,
        });
      });
  }

  refresh(){
    window.location.reload();
  }

  appendQuestions(array) {
    var questions = []
    array.forEach(function(item,index,array){
      var question = item[0];
      var goodAnsw = item[1];
      var answers = item[2]; // this is an array
      var questionDict = {
                          "question": question,
                          "answers": answers,
                          "correctAnswer" : answers.indexOf(goodAnsw)+1,
                          "questionType": "text",
                          "messageForCorrectAnswer": goodAnswerTxt ,
                          "messageForIncorrectAnswer": "La bonne réponse était : \n" + goodAnsw,
                        };
      questions.push(questionDict);
    })
    this.state.quizz["questions"] = questions;
  }

  render(){
    return(
      <div className="landing-quizz-main-container">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <link rel="canonical" href={this.props.pageCanonicalLink} />
          <meta name="description" content={this.props.pageDescription} />
      </Helmet>
      {
        this.props.session?

        <div className="landing-quizz-content">
          {this.state.quizzLaunched?
            <div className="quizz-component">
              {this.state.quizzFinished?
                  <div className="quizz-result">
                  <h2> Résultat : </h2>
                  <p> Vous avez obtenu : {this.state.quizzResult}/20 </p>
                  <ScoreAdvice score={this.state.quizzResult}/>
                  <br/>
                  <Button color="primary" size="lg"  onClick={this.refresh}> Recommencer </Button>

                  </div>
                :
              <Quiz quiz={this.state.quizz} shuffle={true} showInstantFeedback={true} showDefaultResult={false} onComplete={this.onCompleteAction}/>
              }
            </div>
            :
            <div className="quizz-presentation">
              <div className="quizz-presentation title">
              <h1> 201 questions pour préparer son entretien de naturalisation </h1>
              </div>
              <div className="quizz-presentation text">

                <h2> S’entraîner en s’amusant c’est possible ! </h2><br/>
                <p> Ce quizz a pour objectif de<em> tester vos connaissances </em>et de vous aider au mieux dans la préparation de votre entretien de naturalisation.
                A travers ce QCM notre volonté est de vous proposer toutes les questions susceptibles de vous être posées de manière ludique. </p>
                <p> <em> Toutes ces questions sont issues de réels entretiens de naturalisation, alors ne les négligez pas ! </em> </p>
                <br/>

                <p> Bonne révision ! </p>
                <p> L'équipe Natification </p>
                <br/>
                <Button color="primary" size="lg"  onClick={this.launchQuizz}> Lancer le quizz </Button>

                <br/><br/>
                <h3> Mettez toutes les chances de votre côté </h3>
                <br/>
                  <p><i className="fas fa-angle-right"/> Préparez-vous au mieux au quizz en révisant<a href="/preparer-entretien/culture-generale"> votre culture générale </a> </p>
                  <p><i className="fas fa-angle-right"/> N’oubliez pas de préparer également vos<a href="/preparer-entretien/questions-personnelles"> questions personnelles</a> </p>

              </div>
            </div>
          }

        </div>
      :
      <div className="not-connected-quizz-content">
        <h2> Veuillez vous connecter </h2>
        <p> Cette section n'est disponible que pour les membres du site </p>
        <a href="/login"> Me connecter </a> ou <a href="/register"> Créer un compte </a>
      </div>
      }
      </div>
    )}
}
