import React,{Component} from 'react';
import './personnalitesCards.scss';


export default class PersonnalitesCards extends Component{
  constructor(props){
    super(props);
    switch (this.props.size) {
      case "normal":
        this.state = ({
          size : "item"
        })
        break;
      case "medium":
        this.state = ({
          size : "item item--medium"
        })
        break;
      case "large":
        this.state = ({
          size : "item item--large"
        })
        break;
      case "full":
        this.state = ({
          size : "item item--full"
        })
        break;
      default:
        this.state = ({
          size : "item"
        })
    }
  }

  render(){
    var styling = {backgroundImage:this.props.bg}

    return(
          <div className={this.state.size} style={styling}>
            <div className="item__details">
              {this.props.name} >
              <br/><span className="smallText">  </span>
            </div>
            <div className="description">
              {this.props.detail}
            </div>
          </div>
    )
  }
}
