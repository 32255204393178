import React,{Component} from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Home from './Pages/Home/Home';
import MainConnected from './Pages/Home/MainConnected';
import SecretPage from './Pages/SecretPage';
import NoMatch from './Pages/NoMatch/NoMatch';
import Steps from './Pages/Home/Steps';

// -- USER ACCOUNT
import Login from './Pages/Login/Login';
import Register from './Pages/Login/Register';
import MyAccount from './Pages/Account/MyAccount';
import Validate from './Pages/Login/Validate';

// -- LEGAL
import Terms from './Pages/Legals/Terms';
import SellTerms from './Pages/Legals/SellTerms';
import MentionsLegales from './Pages/Legals/MentionsLegales';
import CharteRespectViePrivee from './Pages/Legals/CharteRespectViePrivee';


// -- PREPARER ENTRETIEN
//// --  REVISION
import PreparerEntretien from './Pages/PreparerEntretien/Revision/PreparerEntretien'
import Deroulement from './Pages/PreparerEntretien/Revision/Deroulement/Deroulement'

import LivretDuCitoyen from './Pages/PreparerEntretien/Revision/Livret/LivretDuCitoyen'
import LivretDuCitoyenSymboles from './Pages/PreparerEntretien/Revision/Livret/LivretDuCitoyenSymboles'
import LivretDuCitoyenDevise from './Pages/PreparerEntretien/Revision/Livret/LivretDuCitoyenDevise'
import LivretDuCitoyenDemocratie from './Pages/PreparerEntretien/Revision/Livret/LivretDuCitoyenDemocratie'
import LivretDuCitoyenTerritoire from './Pages/PreparerEntretien/Revision/Livret/LivretDuCitoyenTerritoire'
import LivretDuCitoyenHistoire from './Pages/PreparerEntretien/Revision/Livret/LivretDuCitoyenHistoire'
import LivretDuCitoyenPersonnalites from './Pages/PreparerEntretien/Revision/Livret/LivretDuCitoyenPersonnalites'
import LivretDuCitoyenRayonnement from './Pages/PreparerEntretien/Revision/Livret/LivretDuCitoyenRayonnement'

import CharteDroitsEtDevoirs from './Pages/PreparerEntretien/Charte/CharteDroitsEtDevoirs'

import QuestionsPersonnelles from './Pages/PreparerEntretien/Revision/QuestionsPersos/QuestionsPersonnelles'

//// --  QCM
import LandingQuizz from './Pages/Quizz/LandingQuizz'
/*import QuizzPacks from './Pages/Quizz/QuizzPack/QuizzPacks'
import QuizzSuccess from './Pages/Quizz/QuizzPack/QuizzSuccess'
import QuizzCancel from './Pages/Quizz/QuizzPack/QuizzCancel'*/

// -- CONSTITUER DOSSIER
import ConstituerDossier from './Pages/ConstituerDossier/ConstituerDossier'
import ProcedureNaturalisation from './Pages/ConstituerDossier/ProcedureNaturalisation'
import ConditionsNaturalisation from './Pages/ConstituerDossier/ConditionsNaturalisation'
import PreparerDossierNaturalisation from './Pages/ConstituerDossier/PreparerDossierNaturalisation'
import DeposerDossierNaturalisation from './Pages/ConstituerDossier/DeposerDossierNaturalisation'
import InstructionDossierNaturalisation from './Pages/ConstituerDossier/InstructionDossierNaturalisation'
import DecisionNaturalisation from './Pages/ConstituerDossier/DecisionNaturalisation'

// -- NOTIFICATIONS
import Notification from './Pages/Notification/Notification';
import NotificationSuccess from './Pages/Notification/NotificationSuccess';
import NotificationCancel from './Pages/Notification/NotificationCancel';


export default class Main extends Component {

  render(){
    return (
    <Switch>
      <Route exact path="/" render={(props) => (<Home {...props} pageTitle="Natification | Tout pour la naturalisation française" pageCanonicalLink="https://www.natification.fr" pageDescription="Constitution du dossier de naturalisation. Préparation à l'entretien. Notification à l'acquisition de la naturalisation." session={this.props.session} />)}/>
      <Route exact path="/my/secret/page" render={(props) => (<SecretPage {...props} pageTitle="Natification | Naturalisation Française" pageCanonicalLink="https://www.natification.fr" pageDescription="Natification.fr, premier service dédié à l'aide pour la procédure de naturalisation Française" session={this.props.session} />)}/>
      <Route exact path="/etapes" render={(props) => (<Steps {...props} pageTitle="Natification | Tout pour la naturalisation française" pageCanonicalLink="https://www.natification.fr/etapes" pageDescription="Constitution du dossier de naturalisation. Préparation à l'entretien. Notification à l'acquisition de la naturalisation." session={this.props.session} />)}/>
      <Route exact path="/main" render={(props) => (<MainConnected {...props} pageTitle="Natification | Tout pour la naturalisation française" pageCanonicalLink="https://www.natification.fr" pageDescription="Constitution du dossier de naturalisation. Préparation à l'entretien. Notification à l'acquisition de la naturalisation." session={this.props.session} />)}/>


      <Route exact path="/login" render={(props) => (<Login {...props} pageTitle="Natification | Se connecter" pageCanonicalLink="https://www.natification.fr/login" pageDescription="Se connecter sur le site Natification.fr, premier service dédié à l'aide pour la procédure de naturalisation Française" session={this.props.session} />)}  />
        <Route exact path="/login/success" >
          <Redirect to="/" render={(props) => (<Home {...props} pageTitle="Natification | Tout pour la naturalisation française" pageCanonicalLink="https://www.natification.fr" pageDescription="Constitution du dossier de naturalisation. Préparation à l'entretien. Notification à l'acquisition de la naturalisation." session={this.props.session} />)}  />
        </Route>
      <Route exact path="/register" render={(props) => (<Register {...props} pageTitle="Natification | Créer un compte" pageCanonicalLink="https://www.natification.fr/register" pageDescription="Créer un compte sur Natification.fr, premier service dédié à l'aide pour la procédure de naturalisation Française" session={this.props.session} />)}   />
      <Route exact path="/mon-compte" render={(props) => (<MyAccount {...props} pageTitle="Natification | Mon compte" pageCanonicalLink="https://www.natification.fr/mon-compte" pageDescription="Espace mon compte du site Natification.fr, premier service dédié à l'aide pour la procédure de naturalisation Française" session={this.props.session} />)}   />
      <Route exact path="/validate/:code" render={(props) => (<Validate {...props} pageTitle="Natification | Créer un compte" pageCanonicalLink="https://www.natification.fr/validate/" pageDescription="Valider la création du compte utilisateur sur Natification.fr" session={this.props.session} />)}   />

      <Route exact path="/terms/cgu" render={(props) => (<Terms {...props} pageTitle="Natification | Conditions générales d'utilisation" pageCanonicalLink="https://www.natification.fr/terms/cgu" pageDescription="Conditions générales d'utilisation du site Natification.fr, premier service dédié à l'aide pour la procédure de naturalisation Française" session={true} />)}   />
      <Route exact path="/terms/cgv" render={(props) => (<SellTerms {...props} pageTitle="Natification | Conditions générales de vente" pageCanonicalLink="https://www.natification.fr/terms/cgv" pageDescription="Conditions générales d'utilisation des services Natification.fr, premier service dédié à l'aide pour la procédure de naturalisation Française" session={true} />)}   />
      <Route exact path="/mentions-legales" render={(props) => (<MentionsLegales {...props} pageTitle="Natification | Mentions légales" pageCanonicalLink="https://www.natification.fr/mentions-legales" pageDescription="Mentions légales du site Natification.fr, premier service dédié à l'aide pour la procédure de naturalisation Française" session={true} />)}   />
      <Route exact path="/terms/crvp" render={(props) => (<CharteRespectViePrivee {...props} pageTitle="Natification | Charte de respect de la vie privée" pageCanonicalLink="https://www.natification.fr/terms/crvp" pageDescription="Charte de respect de la vie privée du site Natification.fr, premier service dédié à l'aide pour la procédure de naturalisation Française" session={true} />)}   />



      <Route exact path="/constituer-dossier" render={(props) => (<ConstituerDossier {...props} pageTitle="Aide à la constitution du dossier de naturalisation" pageCanonicalLink="https://www.natification.fr/constituer-dossier" pageDescription="Consultez nos fiches pratiques pour vous aider à constituer votre dossier de naturalisation par décret, mariage ou ascendance et obtenir la nationalité française." session={true} />)}   />
      <Route exact path="/constituer-dossier/procedure-de-naturalisation" render={(props) => (<ProcedureNaturalisation {...props} pageTitle="Procédure de naturalisation | Décret | Mariage | Enfant" pageCanonicalLink="https://www.natification.fr/constituer-dossier/procedure-de-naturalisation" pageDescription="Demande de nationalité Française : Découvrez la procédure qui correspond à votre profil." session={true} />)}   />
      <Route exact path="/constituer-dossier/conditions-naturalisation" render={(props) => (<ConditionsNaturalisation {...props} pageTitle="Conditions pour être citoyen français" pageCanonicalLink="https://www.natification.fr/constituer-dossier/conditions-naturalisation" pageDescription="Outil d'aide à la constitution du dossier de naturalisation : vérifiez si vous êtes éligibles à une demande de naturalisation." session={true} />)}   />
      <Route exact path="/constituer-dossier/conditions-naturalisation/:natcase" render={(props) => (<ConditionsNaturalisation {...props} pageTitle="Conditions pour être citoyen français" pageCanonicalLink="https://www.natification.fr/constituer-dossier/conditions-naturalisation" pageDescription="Outil d'aide à la constitution du dossier de naturalisation : vérifiez si vous êtes éligibles à une demande de naturalisation." session={true} />)}   />
      <Route exact path="/constituer-dossier/preparer-dossier-naturalisation" render={(props) => (<PreparerDossierNaturalisation {...props} pageTitle="Naturalisation | Listes des pièces à fournir" pageCanonicalLink="https://www.natification.fr/constituer-dossier/preparer-dossier-naturalisation" pageDescription="Constitution du dossier de naturalisation : découvrez la liste des documents à fournir à la préfecture dans le cadre de votre demande." session={true} />)}   />
      <Route exact path="/constituer-dossier/deposer-dossier-naturalisation" render={(props) => (<DeposerDossierNaturalisation {...props} pageTitle="Demande de naturalisation | Préfectures de police" pageCanonicalLink="https://www.natification.fr/constituer-dossier/deposer-dossier-naturalisation" pageDescription="Constitution du dossier de naturalisation : découvrez la préfécture dont vous dépendez." session={true} />)}   />
      <Route exact path="/constituer-dossier/instruction-dossier-naturalisation" render={(props) => (<InstructionDossierNaturalisation {...props} pageTitle="Instruction du dossier de naturalisation" pageCanonicalLink="https://www.natification.fr/constituer-dossier/instruction-dossier-naturalisation" pageDescription="Vous avez déposé ou envoyé votre dossier auprès de votre préfecture de police ? Votre dossier est de ce fait étudié par le préfet et une enquête est effectuée." session={true} />)}   />
      <Route exact path="/constituer-dossier/decision-naturalisation" render={(props) => (<DecisionNaturalisation {...props} pageTitle="Décision ministerielle pour votre naturalisation " pageCanonicalLink="https://www.natification.fr/constituer-dossier/decision-naturalisation" pageDescription="Consultez cette fiche pratique pour comprendre les prochaines étapes si vous recevez un avis favorable, défavorable ou irrécevable." session={true} />)}   />

      <Route exact path="/preparer-entretien" render={(props) => (<PreparerEntretien {...props} pageTitle="Nationalité | Préparez votre entretien de naturalisation" pageCanonicalLink="https://www.natification.fr" pageDescription="Préparez-vous à votre rendez-vous grâce à nos fiches pratiques." session={true} />)}   />
      <Route exact path="/preparer-entretien/deroulement" render={(props) => (<Deroulement {...props} pageTitle="Nationalité | Déroulement de l'entretien de naturalisation" pageCanonicalLink="https://www.natification.fr/deroulement" pageDescription="Préparez-vous à votre rendez-vous en découvrant les étapes du déroulement de l'entretien de naturalisation." session={true} />)}   />

      <Route exact path="/preparer-entretien/culture-generale" render={(props) => (<LivretDuCitoyen {...props} pageTitle="Obtenir la nationalité Française | Culture générale" pageCanonicalLink="https://www.natification.fr/preparer-entretien/culture-generale" pageDescription="Découvrez nos fiches pour vous préparer aux questions de culture générale. Entrainez-vous pour votre entretien de naturalisation grâce à notre test en ligne." session={true}  />)}   />
      <Route exact path="/preparer-entretien/culture-generale-part1" render={(props) => (<LivretDuCitoyenSymboles {...props} pageTitle="Nationalité Française | Les symboles de la république" pageCanonicalLink="https://www.natification.fr/preparer-entretien/culture-generale-part1" pageDescription="Préparez l'entretien de naturalisation en découvrant les valeurs et symboles de la république. Entrainez-vous grâce à notre test en ligne." session={true} />)}   />
      <Route exact path="/preparer-entretien/culture-generale-part2" render={(props) => (<LivretDuCitoyenDevise {...props} pageTitle="Préparer l'entretien de naturalisation | La devise de la Francehttps://www.natification.fr/preparer-entretien/culture-generale-part2" pageCanonicalLink="https://www.natification.fr/preparer-entretien/culture-generale-part2" pageDescription="Découvrez en détail les principes de liberté, égalité, fraternité. Entrainez-vous grâce à notre test en ligne." session={true} />)}   />
      <Route exact path="/preparer-entretien/culture-generale-part3" render={(props) => (<LivretDuCitoyenDemocratie {...props} pageTitle="Entretien de naturalisation | Le système démocratique" pageCanonicalLink="https://www.natification.fr/preparer-entretien/culture-generale-part3" pageDescription="Démocratie, séparation des pouvoirs, droits et devoirs du citoyen et système éléctoral : entrainez-vous grâce à notre test sur Natification.fr" session={true} />)}   />
      <Route exact path="/preparer-entretien/culture-generale-part4" render={(props) => (<LivretDuCitoyenTerritoire {...props} pageTitle="Nationalité Française | Organisation du territoire" pageCanonicalLink="https://www.natification.fr/preparer-entretien/culture-generale-part4" pageDescription="Métropole et outre-mer : découvrez les régions, départements, communes et collectivités du territoire français. Préparez votre entretien sur Natification.fr" session={true} />)}   />
      <Route exact path="/preparer-entretien/culture-generale-part5" render={(props) => (<LivretDuCitoyenHistoire {...props} pageTitle="Entretien de naturalisation | Histoire de France" pageCanonicalLink="https://www.natification.fr/preparer-entretien/culture-generale-part5" pageDescription="Entrainez-vous aux questions sur l'Histoire de France pour l'entretien de naturalisation. Les événements les plus marquants sont sur Natification.fr" session={true} />)}   />
      <Route exact path="/preparer-entretien/culture-generale-part6" render={(props) => (<LivretDuCitoyenRayonnement {...props} pageTitle="Nationalité Française | Entretien | Les chiffres à retenir" pageCanonicalLink="https://www.natification.fr/preparer-entretien/culture-generale-part6" pageDescription="Géographie, dates clés, découvrez les chiffres et dates marquants de la France pour votre entretien de naturalisation." session={true} />)}   />
      <Route exact path="/preparer-entretien/culture-generale-part7" render={(props) => (<LivretDuCitoyenPersonnalites {...props} pageTitle="Nationalité |Entretien | Les personalités naturalisées" pageCanonicalLink="https://www.natification.fr/preparer-entretien/culture-generale-part7" pageDescription="Découvrez les personalités qui ont choisi de devenir françaises. Entraînez-vous à l'entretien de naturalisation grâce à notre quizz." session={true} />)}   />

      <Route exact path="/preparer-entretien/charte-des-droits-et-devoirs" render={(props) => (<CharteDroitsEtDevoirs {...props} pageTitle="Naturalisation | Charte des droits et devoirs du citoyen français" pageCanonicalLink="https://www.natification.fr/preparer-entretien/charte-des-droits-et-devoirs" pageDescription="Entretien de naturalisation : prenez connaissance de la charte des droits et des devoirs du citoyen et testez vos connaissances grâce à notre quizz." session={true} />)}   />
      <Route exact path="/preparer-entretien/questions-personnelles" render={(props) => (<QuestionsPersonnelles {...props} pageTitle="Entretien à la préfecture |Questions personnelles" pageCanonicalLink="https://www.natification.fr/preparer-entretien/questions-personnelles" pageDescription="Découvrez cette fiche pratique dédiée aux premières questions de l'entretien de naturalisation et testez vos connaissances grâce à notre test en ligne." session={true} />)}   />

      <Route exact path="/quizz" render={(props) => (<LandingQuizz {...props} pageTitle="Nationalité | Questions de l'entretien de naturalisation" pageCanonicalLink="https://www.natification.fr/quizz" pageDescription="Géographie, histoire, politique, culture générale : plus de 200 questions pour vous préparer à l'entretien de naturalisation." session={this.props.session} />)}   />
      {/*<Route exact path="/quizz/packages" render={(props) => (<QuizzPacks {...props} pageTitle="Nationalité | Questions de l'entretien de naturalisation" pageCanonicalLink="https://www.natification.fr/quizz/packages" pageDescription="Géographie, histoire, politique, culture générale : plus de 200 questions pour vous préparer à l'entretien de naturalisation." session={this.props.session} />)}   />
      <Route exact path="/quizz/packages/success" render={(props) => (<QuizzSuccess {...props} pageTitle="Nationalité | Questions de l'entretien de naturalisation" pageCanonicalLink="https://www.natification.fr/quizz/packages" pageDescription="Géographie, histoire, politique, culture générale : plus de 200 questions pour vous préparer à l'entretien de naturalisation." session={true} />)}   />
      <Route exact path="/quizz/packages/cancel" render={(props) => (<QuizzCancel {...props} pageTitle="Nationalité | Questions de l'entretien de naturalisation" pageCanonicalLink="https://www.natification.fr/quizz/packages" pageDescription="Géographie, histoire, politique, culture générale : plus de 200 questions pour vous préparer à l'entretien de naturalisation." session={true} />)}   />
      */}

      <Route exact path="/notification" render={(props) => (<Notification {...props} pageTitle="Nationalité | Décrets | Notification SMS ou EMAIL" pageCanonicalLink="https://www.natification.fr/notification" pageDescription="Inscrivez-vous pour être notifié par SMS ou mail lorsque votre nom apparaîtra sur le décret de naturalisation." session={true} />)}   />
      <Route exact path="/notification/subscribe/success" render={(props) => (<NotificationSuccess {...props} pageTitle="Nationalité | Décrets | Notification SMS ou EMAIL" pageCanonicalLink="https://www.natification.fr/notification" pageDescription="Inscrivez-vous pour être notifié par SMS ou mail lorsque votre nom apparaîtra sur le décret de naturalisation." session={true} />)}   />
      <Route exact path="/notification/subscribe/cancel" render={(props) => (<NotificationCancel {...props} pageTitle="Nationalité | Décrets | Notification SMS ou EMAIL" pageCanonicalLink="https://www.natification.fr/notification" pageDescription="Inscrivez-vous pour être notifié par SMS ou mail lorsque votre nom apparaîtra sur le décret de naturalisation." session={true} />)}   />

      {/*
        <Route exact path="/blog" render={(props) => (<BlogHome {...props} pageTitle="Natification | Blog" pageCanonicalLink="https://www.natification.fr/blog" pageDescription="Natification.fr, premier service dédié à l'aide pour la procédure de naturalisation. Tout savoir sur la naturalisation française" session={true} />)}   />
        */}


      <Route component={NoMatch}render={(props) => (<NotificationCancel {...props} pageTitle="Nationalité | Erreur 404" pageCanonicalLink="https://www.natification.fr/" pageDescription="Erreur 404 - Page non disponible" session={true} />)}  />
    </Switch>
  )}

}
