import React, { Component } from 'react';
import './App.css';

import Main from './Components/main';
import NavBar from './Components/NavBar/NavBar';
import {Footer} from './Components/Footer/Footer';

import Cookies from 'universal-cookie';

import axios from 'axios';

const cookies = new Cookies();

export default class App extends Component {
  constructor(props){
    super(props);
    this.state = {
      validToken : false,
    }
    this.checkToken();
  }

  static getDerivedStateFromProps(props, state){
    return true;
  }



  async checkToken(){
    let c_jwt  = cookies.get("jwt");
    if(typeof c_jwt === 'undefined'){
      return 0;
    }
    var c_jw = c_jwt.split("=")[0];
    var self = this;
    axios({
              method: 'POST',
              url : "https://www.natification.fr/serv/api/client/validate_token.php",
              headers: {
                'Content-Type': 'application/json'
              },
              data: {
                'jwt': c_jw,
              }
            })
      .then(function (response) {
        self.setState({
          validToken : true,
          email : response.data.data.email,
        });
      })
      .catch(function (error) {
        console.log(error);
      });
    }

  render() {
    return (
      <div className="App">
        <NavBar session={this.state.validToken}/>
        <Main session={this.state.validToken}/>
        <Footer/>
      </div>
    );
  }
}
