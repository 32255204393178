import React,{Component} from 'react';
import './chapitres-menu.scss';


export default class LivretChapitresMenu extends Component{
  constructor(props){
    super(props);
    this.state = {
      toggled : false,
    };
    this.showMenu = this.showMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
    document.body.className = "";
  }

  showMenu(){
    this.setState({
      toggled : true
    });
    document.body.className += "open";
  }

  hideMenu(){
    this.setState({
      toggled : false
    });
    document.body.className = " ";
  }

  render(){
    return(
      <div className="chapitres-liste">
        <button className={this.state.toggled?"chapitre-menu-toggle hidden":"chapitre-menu-toggle"} onClick={this.showMenu}> Afficher les chapitres </button>
        <nav>
          <button className="chapitre-menu-close" onClick={this.hideMenu}> x </button>
          <ul className="menu">
            <a href="/preparer-entretien/culture-generale-part1">
            <li data-text="Chapitre 1 - Les valeurs de la République">
              Chapitre 1 - Les valeurs de la République
            </li>
            </a>

            <a href="/preparer-entretien/culture-generale-part2">
            <li data-text="Chapitre 2 - La devise">
              Chapitre 2 - La devise
            </li>
            </a>

            <a href="/preparer-entretien/culture-generale-part3">
            <li data-text="Chapitre 3 - Le système démocratique">
              Chapitre 3 - Le système démocratique
            </li>
            </a>

            <a href="/preparer-entretien/culture-generale-part4">
            <li data-text="Chapitre 4 - Organisation du territoire">
              Chapitre 4 - Organisation du territoire
            </li>
            </a>

            <a href="/preparer-entretien/culture-generale-part5">
            <li data-text="Chapitre 5 - Évenements et histoire de France">
              Chapitre 5 - Évenements et histoire de France
            </li>
            </a>

            <a href="/preparer-entretien/culture-generale-part6">
            <li data-text="Chapitre 6 - La France en chiffre">
              Chapitre 6 - La France en chiffre
            </li>
            </a>

            <a href="/preparer-entretien/culture-generale-part7">
            <li data-text="Chapitre 7 - Eux aussi ont été naturalisés">
              Chapitre 7 - Eux aussi ont été naturalisés
            </li>
            </a>

          </ul>
        </nav>

      </div>
  )}
}
