import React, {Component} from 'react';
import { Button } from 'reactstrap';
import CheckoutForm from './CheckoutForm';
import {Elements, StripeProvider} from 'react-stripe-elements';
import axios from 'axios';
import ReactGA from 'react-ga';

import Cookies from 'universal-cookie';
const cookies = new Cookies();

export default class PaymentForm extends Component{

  constructor(props){
    super(props);
    this.showContactInfo = this.showContactInfo.bind(this);
    this.showMailOption = this.showMailOption.bind(this);
    this.showPhoneOption = this.showPhoneOption.bind(this);

    if (this.props.fieldValues.email != null && this.props.fieldValues.phone != null){
      this.state = {
             package: 3,
         };
    }
    else if (this.props.fieldValues.email != null){
      this.state = {
             package: 1,
         };
    }
    else if (this.props.fieldValues.phone != null){
      this.state = {
             package: 2,
         };
    }
    else{
      this.state = {
             package: 0,
         };
    }

    this.pushData();
  }

  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/notification/subscribe/payement');

  }

  async pushData(){
    var self = this;
    axios({
              method: 'POST',
              url : "https://www.natification.fr/serv/api/customer/add_notif_customer.php",
              headers: {
                'Content-Type': 'application/json'
              },
              data: {
                'fname' : this.props.fieldValues.fname,
                'lname' : this.props.fieldValues.lname,
                'dof' : this.props.fieldValues.dof,
                'reze' : this.props.fieldValues.reze,
                'email': this.props.fieldValues.email,
                'phone': this.props.fieldValues.phone,
                'prdct' : this.state.package
              }
            })
      .then(function (response) {
        cookies.set("_cust", response.data.data.r[0].did,{ path: '/' });
        cookies.set("_notif_pkg", self.state.package,{ path: '/' });
        console.log(response);
      })
      .catch(function (error) {
        self.setState({
          error : true,
        });
      });
  }

  showContactInfo(){
    if(this.props.fieldValues.reze != null){
      return(
        <p> Votre n° REZE : {this.props.fieldValues.reze} </p>
      );
    }
    else{
      return(
        <p>{this.props.fieldValues.fname} {this.props.fieldValues.lname}
        <br/> Né(e) le {this.props.fieldValues.dof}</p>
      );
    }
  }

  showMailOption(){
    if (this.props.fieldValues.email != null ){
      return(<span>- e-mail : {this.props.fieldValues.email} </span>);
    }
  }

  showPhoneOption(){
    if (this.props.fieldValues.phone != null ){
      return(<span>- SMS : {this.props.fieldValues.phone} </span>);
    }
  }

  render(){
    return(
      <div className="hp-form">
          <p> Vérifiez vos informations : </p>
          <hr/>
          {this.showContactInfo()}
          <p> Notifié(e) par : </p>
          <p>
          {this.showMailOption()}<br/>
          {this.showPhoneOption()}
          </p>

          <hr/>
          <StripeProvider apiKey="pk_live_vCYLaM5Pa8G7QvaZKtiOnW1Q00O8sRbGG2">
            <div className="stripe-form">
              <Elements>
                <CheckoutForm package={this.state.package} next={this.props.next} email={this.props.fieldValues}/>
              </Elements>
            </div>
          </StripeProvider>


          <div id="error-message"></div>
          <div className="btn back">
            <Button className="send" onClick={this.props.back}>Retour</Button>
          </div>


      </div>
  )}
}
