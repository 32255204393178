import React, { Component } from 'react';

import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption
} from 'reactstrap';

const items = [
  {
    id: 1,
    altText: 'RetourXp 1',
    textPart2:"Grâce à NATIFICATION, j'ai pu m'entrainer sur le quizz aux questions de l'entretiende naturalisation. J'ai pu suivre ma progression et j'étais plus serein pendant mon entretien avec l'agent de la préfecture.",
    textPart1:"J'ai adoré m'entrainer sur NATIFICATION !",
    header: 'ISMAIL O.',
    caption: 'Naturalisé en 2019',
  },
  {
    id: 2,
    altText: 'RetourXp 2',
    textPart2:"Je me connectais deux fois par semaine sur le site des decrets pour savoir si j'étais naturalisée. Je me suis alors inscrite sur NATIFICATION. J'ai sauté dans tous les sens quand j'ai reçu leur SMS !",
    textPart1:"Simple et efficace. C'est tout.",
    header: 'ABOU BAKAR B.',
    caption: 'Naturalisée en 2019',

  },
  {
    id: 3,
    altText: 'RetourXp 3',
    textPart2:"J'étais très stressée quand j'ai commencé mon dossier : je ne savais jamais ce qu'il manquait ou pas. J'ai alors utilisé le service de composition de dossier de NATIFICATION et j'ai pu tout vérifier super facilement.",
    textPart1:"Je recommande à 100% l'outil.",
    header: 'RITA B.',
    caption: 'Naturalisée en 2019',

  }
];

export default class TestimonialsCarrousel extends Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === items.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous() {
    if (this.animating) return;
    const nextIndex = this.state.activeIndex === 0 ? items.length - 1 : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex(newIndex) {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { activeIndex } = this.state;

    const slides = items.map((item) => {
      return (
        <CarouselItem
          className="custom-tag"
          tag="div"
          key={item.id}
          onExiting={this.onExiting}
          onExited={this.onExited}
        >
          <div className="slideContent">

            <div className="slide-text">
              <div className="slide-text-part1">
                <h3>{item.textPart1}</h3>
                <hr/>
              </div>
              <div className="slide-text-part2">
                <p><i className="fas fa-quote-left"></i>{item.textPart2}</p>
              </div>
            </div>


          </div>
          <CarouselCaption className="text-danger" captionText={item.caption} captionHeader={item.header} />
        </CarouselItem>
      );
    });

    return (
      <div>
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
        >
          <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={this.goToIndex} />
          {slides}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
        </Carousel>
      </div>
    );
  }
}
