import React,{Component} from 'react';
import { Button, Form, FormGroup, Input,Alert,CustomInput } from 'reactstrap';

import FacebookLogin from 'react-facebook-login';

import './login.css';
import axios from 'axios';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default class LoginForm extends Component{
  constructor(props) {
    super(props);
    this.state={
      redirect: false,
      error: false,
      email: null,
      pwd: null,
      passwordState : true,
      notValidMail : false,
    }
    this.handleChange = this.handleChange.bind(this);
    this.login = this.login.bind(this);
    this.setCookie = this.setCookie.bind(this);
    this.switchPwdState = this.switchPwdState.bind(this);
    this.responseFacebook = this.responseFacebook.bind(this);

  }

  switchPwdState(){
    this.setState({
      passwordState : !this.state.passwordState
    })
  }

  setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    cookies.set(cname + "=" + cvalue , expires, { path: '/' });
  }

  handleChange = (e) => {
      this.setState({
            [e.target.name]: e.target.value
        });
    }

    onFormSubmit = e => {
        e.preventDefault();
        this.login();
    }


  redirectToHome(){
    window.open('/main',"_self");
  }

  login(){
    var self = this;
    axios({
              method: 'POST',
              url : "https://www.natification.fr/serv/api/client/valid_login.php",
              headers: {
                'Content-Type': 'application/json'
              },
              data: {
                'email': this.state.email,
                'password': this.state.pwd
              }
            })
      .then(function (response) {
        if(response.data.jwt === 0){
          self.setState({
            notValidMail : true,
          })
        }else{
          self.setCookie("jwt", response.data.jwt, 1);
          self.setState({
            error : false,
            redirect: true
          })
        }

      })
      .catch(function (error) {
        self.setState({
          error : true,
        });
      });
  }

  responseFacebook(response){
    var self = this;
    self.setState({
      email : response.email,
    })
    axios({
              method: 'POST',
              url : "https://www.natification.fr/serv/api/client/valid_login_fb.php",
              headers: {
                'Content-Type': 'application/json'
              },
              data: {
                'email': response.email,
                'userID': response.id
              }
            })
      .then(function (response) {
        if(response.data.jwt === 0){
          self.setState({
            notValidMail : true,
          })
        }else{
          self.setCookie("jwt", response.data.jwt, 1);
          self.setState({
            error : false,
            redirect: true
          })
        }

      })
      .catch(function (error) {
        self.setState({
          error : true,
        });
      });
  }

  render(){

    return(
      <div className="loginform">
       {
         this.state.redirect?
          this.redirectToHome():""
       }
        {
          this.state.error?
          <Alert color="danger"> Erreur de connexion. Vérifiez votre email ou mot de passe</Alert>
          :""
        }
        {
          this.state.notValidMail?
          <Alert color="warning"> Vous devez d'abord confirmer votre adresse en cliquant sur le lien envoyé sur votre adresse mail {this.state.email} <br/> (NB: Vérifiez dans vos spams) </Alert>
          :""
        }
        <div className="lg-form">
              <Form >
                  <FormGroup>
                      <FormGroup>
                        <Input type="text" autoComplete="username" className={this.state.error ? 'field-error' : ''} name="email" id="email" placeholder="Email" onChange={this.handleChange} required/>
                      </FormGroup>

                      <FormGroup>
                        <Input type={this.state.passwordState?"password":"text"} autoComplete="current-password" className={this.state.error ? 'field-error' : ''} name="pwd" id="pwd" placeholder="Password" onChange={this.handleChange} required/>
                      </FormGroup>

                      <FormGroup>
                      <CustomInput type="switch" id="exampleCustomSwitch" name="customSwitch" label="Afficher le mot de passe" onClick={this.switchPwdState} />

                      </FormGroup>

                    <Button className="roundblue" onClick={this.login}>Se connecter</Button>
                  </FormGroup>
                </Form>
                <hr/>
                  <div className="fb-login">
                    <FacebookLogin
                      appId="372998533574014" //APP ID NOT CREATED YET
                      fields="name,email,picture"
                      callback={this.responseFacebook}
                      icon="fa-facebook"
                    />
                  </div>
        </div>

      </div>
    )}
}
