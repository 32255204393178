import React, {Component} from 'react';
import './constituerDossier.css';
import ReactGA from 'react-ga';
import { Collapse, Button, CardBody, Card } from 'reactstrap';
import {Helmet} from "react-helmet";


export default class DecisionNaturalisation extends Component{
  constructor(props){
    super(props);
    this.state= {
      session : this.props.session,
    }
   this.toggle = this.toggle.bind(this);
   this.state =
      {
        collapse1: false,
        collapse2: false,
        collapse3: false,
        collapse4: false,
        collapse5: false,
      };
  }

  toggle(e) {
    e.preventDefault();
    switch (e.target.id) {
      case "1":
        this.setState(state => ({ collapse1: !state.collapse1 }));
        break;
      case "2":
        this.setState(state => ({ collapse2: !state.collapse2 }));
        break;
      case "3":
        this.setState(state => ({ collapse3: !state.collapse3 }));
        break;
      case "4":
        this.setState(state => ({ collapse4: !state.collapse4 }));
        break;
      case "5":
        this.setState(state => ({ collapse5: !state.collapse5 }));
        break;
      default:
        break;
    }

 }

  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/constituer-dossier/decision-naturalisation');
  }


  render(){
    return(
      <div className="constituer-main-container decision">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <link rel="canonical" href={this.props.pageCanonicalLink} />
          <meta name="description" content={this.props.pageDescription} />
      </Helmet>
        <div className="top-image decision">
            <div className="top-image-title">
              <h1> Décision ministerielle pour votre naturalisation </h1>
            </div>
        </div>

      <div className="constituer-content decision">

        <div className="constituer-content-header decision">

          <p> Si le préfet de police donne un avis favorable après l’instruction de votre dossier, ce dernier est envoyé au ministère en charge des naturalisations </p>
          <br/>
          <p> C’est le ministère de l’intérieur qui décide de vous octroyer, ou pas,  la nationalité française. </p>
          <p> Pour savoir si vous êtes naturalisé(e), il faudra vérifier plusieurs jours par semaine si votre nom apparaît dans le dernier décret. Votre nom peut apparaître sur le court ou moyen terme. Cette étape peut durer plusieurs mois ! Ne perdez plus votre temps et votre énergie :<br/> </p>
          <h6> <a href="/notification"> Avec Natification, soyez notifiez automatiquement dès l’apparition de votre nom sur le décret et c'est par ici <i className="fas fa-angle-right"></i> </a> </h6>
        </div>


        <div className="constituer-section decision section-1 left">
          <div className="constituer-section decision section-1-container">
            <h2> Quelles sont les décisions possibles ? </h2>
              <h3> Suite à une demande d’acquisition de la nationalité par déclaration </h3>
                <div>
                  <Button color="success" id="1" onClick={this.toggle} style={{ marginBottom: '1rem' }}><h5> Décision favorable </h5> <br/> <i className="fas fa-chevron-down"></i> </Button>
                  <Collapse isOpen={this.state.collapse1}>
                    <Card>
                      <CardBody>
                        <p> Vous remplissez toutes les conditions prévues par la loi et le gouvernement valide votre dossier.
                        Vous êtes de ce fait citoyen français.
                        Votre préfecture de police vous procure un exemplaire de votre déclaration, avec la mention de son enregistrement. C’est grâce à ce document que vous pourrez par la suite demander votre passeport, votre carte nationale d'identité ou un certificat de nationalité française</p>
                        <p>A NOTER :
                        <br/>
                        - Vous pouvez perdre la nationalité française de manière volontaire ou par déchéance, retrait ou annulation.
                        </p>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>

                <div>
                  <Button color="danger" id="2" onClick={this.toggle} style={{ marginBottom: '1rem' }}><h5> Refus d'enregistrement </h5><br/> <i className="fas fa-chevron-down"></i> </Button>
                  <Collapse isOpen={this.state.collapse2}>
                    <Card>
                      <CardBody>
                        <p> Vous ne remplissez pas toutes les conditions prévues par la loi ou le gouvernement ne valide votre dossier. Vous allez être informé(e) par le préfet ou par courrier recommandé avec accusé de réception, des raisons pour lesquelles vous n’êtes pas naturalisé(e). Tout refus est justifié. </p>
                        <p> En cas de refus, vous disposez d’un délai de 6 mois pour contester cette décision auprès du Tribunal de grande instance </p>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>



                <h3> Suite à une demande d’acquisition de la nationalité par décret </h3>

                <div>
                  <Button color="success" id="3" onClick={this.toggle} style={{ marginBottom: '1rem' }}><h5> Décision favorable </h5><br/> <i className="fas fa-chevron-down"></i> </Button>
                  <Collapse isOpen={this.state.collapse3}>
                    <Card>
                      <CardBody>
                        <p> Au bout d’une période indéterminée votre nom apparaît dans un décret publié dans le journal officiel. Vous êtes de ce fait citoyen français.</p>
                        <p> Vous allez recevoir par la suite 2 courriers.<br/>
                        Le premier courrier vous informe que votre nom apparaît dans un décret. Le second courrier vous informe de votre convocation à la cérémonie de remise de votre dossier de naturalisation.
                        Le dossier de naturalisation est composé d’un extrait du décret de naturalisation et une copie des actes de l'état civil français
                        </p>
                        <p>
                        Pas besoin d’attendre ces courriers pour demander votre passeport ou votre carte nationale d'identité. Vous pouvez imprimer la première page du décret et la page mentionnant votre nom.<a href="/notification"> Gagnez du temps en étant informé dès que votre nom apparaît dans un décret </a></p>
                        <p> A NOTER :
                        <br/>  - Vous pouvez perdre la nationalité française de manière volontaire ou par déchéance, retrait ou annulation.
                        </p>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>

                <div>
                  <Button color="warning" id="4" onClick={this.toggle} style={{ marginBottom: '1rem' }}><h5> Décision défavorable en irrecevabilité </h5><br/> <i className="fas fa-chevron-down"></i> </Button>
                  <Collapse isOpen={this.state.collapse4}>
                    <Card>
                      <CardBody>
                        <p> Une décision défavorable en irrecevabilité signifie que les conditions légales ne sont pas remplies. </p>
                        <p> Vous allez être informé(e) par le préfet ou par courrier recommandé avec accusé de réception, des raisons pour lesquelles vous n’êtes pas naturalisé(e). Tout refus est justifié. </p>
                        <p> En cas de refus, vous disposez d’un délai de 2 mois pour effectuer un recours administratif auprès du ministère en charge. Si à la suite de ce recours la décision est toujours défavorable, vous pouvez contester cette décision auprès du Tribunal administratif</p>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>

                <div>
                  <Button color="danger" id="5" onClick={this.toggle} style={{ marginBottom: '1rem' }}><h5> Décision défavorable en opportunité </h5><br/> <i className="fas fa-chevron-down"></i> </Button>
                  <Collapse isOpen={this.state.collapse5}>
                    <Card>
                      <CardBody>
                        <p> Une décision défavorable en opportunité signifie que même si les conditions légales sont remplies votre demande de naturalisation est rejetée ou ajournée.<br/>
                        Vous allez être informé(e) par le préfet ou par courrier recommandé avec accusé de réception, des raisons pour lesquelles vous n’êtes pas naturalisé(e) ou pour lesquelles votre demande est ajournée. Tout refus ou ajournement est justifié. </p>
                        <p> En cas d’ajournement, un délai imposé est mis en place afin de vous donner la possibilité de renforcer votre dossier. L’objectif d’un ajournement est de solidifier votre candidature (ex : améliorer son insertion professionnelle). A la fin de ce délai imposé, vous pouvez faire une nouvelle demande de naturalisation.</p>
                        <p> En cas de refus, vous disposez d’un délai de 2 mois pour effectuer un recours administratif auprès du ministère en charge. Si à la suite de ce recours la décision est toujours défavorable, vous pouvez contester cette décision auprès du Tribunal administratif. </p>
                      </CardBody>
                    </Card>
                  </Collapse>
                </div>




          </div>
        </div>
      </div>
    </div>

    )}
}
