import React, {Component} from 'react';
import './livret.css';
import HistoryTL from './History/HistoryTL';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import LivretChapitresMenu from './MenuChapitres/Chapitres';
import {Helmet} from "react-helmet";

export default class LivertDuCitoyenHistoire extends Component{
  constructor(props){
    super(props);
    this.state={
      datatext : this.props.data,
      title : this.props.title,
      imgsrc : this.props.img,
      content : this.props.content
    }
  }


  render(){
    return(
      <div className="livret-main-container">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <link rel="canonical" href={this.props.pageCanonicalLink} />
          <meta name="description" content={this.props.pageDescription} />
      </Helmet>
          <Breadcrumb>
            <BreadcrumbItem><a href="/preparer-entretien">Préparer mon entretien</a></BreadcrumbItem>
            <BreadcrumbItem><a href="/preparer-entretien/culture-generale">Culture générale</a></BreadcrumbItem>
            <BreadcrumbItem active>L'Histoire de France</BreadcrumbItem>
          </Breadcrumb>

          <div className="top-image livret">
            <div className="top-image-title">
              <h2> Préparer mon entretien : <span>L'Histoire de France</span></h2>
            </div>
          </div>

          {
            this.props.session?
            <div className="livret-content">
            <LivretChapitresMenu/>
            <div className="livret-section section-end">

            </div>

            <div className="history-header">
              <h1>La France dans l'histoire</h1>
              <p> Nous allons parcourir ici quelques événements de l'histoire de France </p>
            </div>

            <div className="historytimeline">
              <HistoryTL />
            </div>

            <div className="livret-section section-end">
                <span> Chapitre précédent :</span>
                <span> </span>
                <span> Chapitre suivant : </span>
               <div className="chap-prev"> <a href="/preparer-entretien/culture-generale-part4"><i className="fas fa-arrow-left"></i> Organisation du territoire </a></div>
               <br/>
               <div className="chap-next"> <a href="/preparer-entretien/culture-generale-part6"> La france en chiffres <i className="fas fa-arrow-right"></i> </a></div>
            </div>
          </div>
          :
          <div className="offline-content livret">
            <h2> Veuillez vous connecter </h2>
            <p> Cette section n'est disponible que pour les membres du site </p>
            <a href="/login"> Me connecter </a> ou <a href="/register"> Créer un compte </a>
          </div>
        }
    </div>
  )}
}


/*
.timeline
  .timeline-item(data-text='FATHER OF THE TURKS')
    .timeline__content
      img.timeline__img(src='http://gazetemanifesto.com/wp-content/uploads/2015/11/mustafa-kemal.jpg')
      h2.timeline__content-title 1893
      p.timeline__content-desc
        | First enrolled in a traditionalreligious school, he soon switched to a modern school. In 1893, he entered a military highschool where his mathematics teacher gave him the second name Kemal (meaning perfection)in recognition of young Mustafa's superior achievement.
  .timeline-item(data-text='FATHER OF THE TURKS')
    .timeline__content
      img.timeline__img(src='http://www.volpeypir.com/upload/3732.jpg')
      h2.timeline__content-title 1905
      p.timeline__content-desc
        | In 1905, Mustafa Kemal graduated from the War Academy in Istanbul with the rank of Staff Captain. Posted in Damascus, he started with several colleagues, a clandestinesociety called "Homeland and Freedom" to fight against the Sultan'sdespotism.
  .timeline-item(data-text='FATHER OF THE TURKS')
    .timeline__content
      img.timeline__img(src='http://cdn.yemek.com/uploads/2014/11/ataturk-10-kasim.jpg')
      h2.timeline__content-title 1908
      p.timeline__content-desc
        | In 1908 he helped the group of officers who toppled the Sultan. Mustafa Kemal'scareer flourished as he won his heroism in the far corners of the Ottoman Empire,including Albania and Tripoli. He also briefly served as a staff officer in Salonica andIstanbul and as a military attache in Sofia.
  .timeline-item(data-text='FATHER OF THE TURKS')
    .timeline__content
      img.timeline__img(src='http://ataturk.istanbul.gov.tr/GalleryLibrary/12.jpg')
      h2.timeline__content-title 1915
      p.timeline__content-desc
        | In 1915, when Dardanelles campaign was launched, Colonel Mustafa Kemal became anational hero by winning successive victories and finally repelling the invaders.
  .timeline-item(data-text='FATHER OF THE TURKS')
    .timeline__content
      img.timeline__img(src='http://blog.istanbul1881.com/wp-content/uploads/2016/08/atat%C3%BCrk-%C3%BCn-inan%C4%B1lmaz-karizmatik-fotograf%C4%B1_861050.jpg')
      h2.timeline__content-title 1916
      p.timeline__content-desc
        | Promotedto general in 1916, at age 35, he liberated two major provinces in eastern Turkey thatyear. In the next two years, he served as commander of several Ottoman armies inPalestine, Aleppo, and elsewhere, achieving another major victory by stopping the enemyadvance at Aleppo.
  .timeline-item(data-text='FATHER OF THE TURKS')
    .timeline__content
      img.timeline__img(src='http://manisanokta.com/wp-content/uploads/2014/07/ataturk-20.jpg')
      h2.timeline__content-title 1919
      p.timeline__content-desc
        | On May 19, 1919, Mustafa Kemal Pasha landed in the Black Sea port of Samsun to startthe War of Independence. In defiance of the Sultan's government, he rallied a liberationarmy in Anatolia and convened the Congress of Erzurum and Sivas which established thebasis for the new national effort under his leadership.
  .timeline-item(data-text='FATHER OF THE TURKS')
    .timeline__content
      img.timeline__img(src='http://www.volpeypir.com/upload/3732.jpg')
      h2.timeline__content-title 1920
      p.timeline__content-desc
        | On April 23, 1920, the GrandNational Assembly was inaugurated. Mustafa Kemal Pasha was elected to its Presidency.
        | Fighting on many fronts, he led his forces to victory against rebels and invadingarmies. Following the Turkish triumph at the two major battles at Inonu in Western Turkey,the Grand National Assembly conferred on Mustafa Kemal Pasha the title ofCommander-in-Chief with the rank of Marshal.
  .timeline-item(data-text='FATHER OF THE TURKS')
    .timeline__content
      img.timeline__img(src='http://sanatkaravani.com/wp-content/uploads/2015/11/ataturk-4.jpg')
      h2.timeline__content-title 1922
      p.timeline__content-desc
        | At the end of August 1922, the Turkish armieswon their ultimate victory. Within a few weeks, the Turkish mainland was completelyliberated, the armistice signed, and the rule of the Ottoman dynasty abolished
  .timeline-item(data-text='FATHER OF THE TURKS')
    .timeline__content
      img.timeline__img(src='https://turkcetarih.com/wp-content/uploads/2015/05/Atat%C3%BCrkveCumhuriyet.jpg')
      h2.timeline__content-title 1923
      p.timeline__content-desc
        | In July 1923, the national government signed the Lausanne Treaty with Great Britain,France, Greece, Italy, and others. In mid-October, Ankara became the capital of the new Turkish State. On October 29, the Republic was proclaimed and Mustafa Kemal Pasha wasunanimously elected President of the Republic.
  .timeline-item(data-text='FATHER OF THE TURKS')
    .timeline__content
      img.timeline__img(src='https://pbs.twimg.com/media/Cw69H8pXUAEaSqa.jpg')
      h2.timeline__content-title 1934
      p.timeline__content-desc
        | The account of Atatürk's fifteen year Presidency is a saga of dramatic modernization.With indefatigable determination, he created a new political and legal system, abolished the Caliphate and made both government and education secular, gave equal rights to women,changed the alphabet and the attire, and advanced the arts and the sciences, agricultureand industry.
        | In 1934, when the surname law was adopted, the national parliament gave him the name
        i
          strong "Atatürk" (Father of the Turks)
  .timeline-item(data-text='FATHER OF THE TURKS')
    .timeline__content
      img.timeline__img(src='http://www.bik.gov.tr/wp-content/uploads/2016/11/20161110_2_20037273_15856882.jpg?fit=1024%2C863')
      h2.timeline__content-title 1938
      p.timeline__content-desc
        | On November 10, 1938, following an illness of a few months, the national liberator and the Father of modern Turkey died. But his legacy to his people and to the world endures.
.demo-footer
a(href='http://www.turkishnews.com/Ataturk/life.htm', target='_blank') Source/Kaynak
*/
