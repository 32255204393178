import React,{Component} from 'react';
import './legals.css';
import {Helmet} from 'react-helmet';

export default class SellTerms extends Component{
  render(){
    return(
      <div className="legal-page-content">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <link rel="canonical" href={this.props.pageCanonicalLink} />
          <meta name="description" content={this.props.pageDescription} />
      </Helmet>
        <div className="legal-content">
          <h1> Conditions Générales de Vente (CGV)</h1>
          <br/>
          <p><em> En vigueur au 18/05/2019 </em></p>
          <br/>
          <h2>ARTICLE 1 - CHAMP D'APPLICATION  </h2>
          <p> Les présentes Conditions Générales de Vente (dites « CGV ») s'appliquent, sans restriction ni réserve à tout achat des services de suivants : </p>
            <lu>
              <li> Service de notification (envoi d'un SMS et/ou d'un e-mail) à l'acquisition de la naturalisation française actée par la parution au journal officiel. </li>
            </lu>
          <p>tels que proposés par le Prestataire aux clients non professionnels (« Les Clients ou le Client ») sur le site www.natification.fr .</p>
          <p> Les caractéristiques principales des Services sont présentées sur le site internet www.natification.fr . Le Client est tenu d'en prendre connaissance avant toute passation de commande. Le choix et l'achat d'un Service est de la seule responsabilité du Client. </p>
          <p> Le Client déclare avoir pris connaissance des présentes CGV et les avoir acceptées en validant le formulaire de synthèse avant la mise en œuvre de la procédure de commande en ligne du site www.natification.fr . </p>
          <p> Sauf preuve contraire, les données enregistrées dans le système informatique du Prestataire constituent la preuve de l'ensemble des transactions conclues avec le Client. </p>
          <p> Les coordonnées du Prestataire sont les suivantes : </p>
          <p> ENAGE , SAS Capital social de 1000 euros<br/>Immatriculé au RCS de Paris , sous le numéro 841959042 <br/> 324 rue Lecourbe 75015 PARIS
          <br/> mail : contact@natification.fr  <br/> téléphone : +33786167023 </p>
          <p>Des droits de douane ou autres taxes locales ou droits d'importation ou taxes d'état sont susceptibles d'être exigibles. Ils seront à la charge et relèvent de la seule responsabilité du Client. </p>


          <h2>ARTICLE 2 - PRIX  </h2>
          <p> Les Services sont fournis aux tarifs en vigueur figurant sur le site www.natification.fr, lors de l'enregistrement de la commande par le Prestataire. </p>
          <p> Les prix sont exprimés en Euros, HT et TTC. </p>
          <p> Les tarifs tiennent compte d'éventuelles réductions qui seraient consenties par le Prestataire sur le site www.natification.fr . </p>
          <p> Ces tarifs sont fermes et non révisables pendant leur période de validité mais le Prestataire se réserve le droit, hors période de validité, d'en modifier les prix à tout moment. </p>
          <p> Les prix ne comprennent pas les frais de traitement, d'expédition, de transport et de livraison, qui sont facturés en supplément, dans les conditions indiquées sur le site et calculés préalablement à la passation de la commande. Le paiement demandé au Client correspond au montant total de l'achat, y compris ces frais. Une facture est établie par le Prestataire et remise au Client lors de la fourniture des Services commandés. </p>


          <h2> ARTICLE 3 - COMMANDES </h2>
          <p> Il appartient au Client de sélectionner sur le site www.natification.fr les Services qu'il désire commander, selon les modalités suivantes :</p>
          <ul> <li>Le Client choisi le service de notification auquel il souhaite souscrire. Il renseigne les informations permettant de l'identifier. Une fois l'ensemble des informations recueillies, le Client peut visualiser et vérifier une synthèse des informations. Il peut les modifier avant de valider et ainsi accepter les présentes conditions générales de vente. Après validation des informations, la commande sera considérée comme définitive et exigera paiement de la part du Client selon les modalités prévues.</li> </ul>
          <p> La vente ne sera considérée comme valide qu'après paiement intégral du prix. Il appartient au Client de vérifier l'exactitude de la commande et de signaler immédiatement toute erreur. </p>
          <p> Toute commande passée sur le site www.natification.fr constitue la formation d'un contrat conclu à distance entre le Client et le Prestataire. </p>
          <p> Le Prestataire se réserve le droit d'annuler ou de refuser toute commande d'un Client avec lequel il existerait un litige relatif au paiement d'une commande antérieure. Le Client pourra suivre l'évolution de sa commande sur le site. </p>

          <h2> ARTICLE 4 - CONDITIONS DE PAIEMENT </h2>
          <p> Le prix est payé par voie de paiement sécurisé, selon les modalités suivantes : </p>
          <ul>
            <li> paiement par carte bancaire </li>
            <li> paiement par le biais de prestataires (Paypal, Stripe, ...) </li>
          </ul>
          <p>Le prix est payable comptant par le Client, en totalité au jour de la passation de la commande.  </p>
          <p>Les données de paiement sont échangées en mode crypté grâce au protocole défini par le prestataire de paiement agréé intervenant pour les transactions bancaires réalisées sur le site www.natification.fr . </p>
          <p>Les paiements effectués par le Client ne seront considérés comme définitifs qu'après encaissement effectif des sommes dues, par le Prestataire. </p>
          <p>Le Prestataire ne sera pas tenu de procéder à la fourniture des Services commandés par le Client si celui-ci ne lui en paye pas le prix en totalité dans les conditions ci-dessus indiquées.</p>


          <h2> ARTICLE 5 - FOURNITURE DES PRESTATIONS </h2>
          <p> Les Services commandés par le Client seront fournis selon les modalités suivantes : </p>
          <ul> <li>Service de notification (par SMS ou par email) à l'acquisition de la nationalité française par décret de naturalisation. </li> </ul>
          <p>Lesdits services seront dans un délai maximum de 24h après la parution du décret au Journal Officiel. L’enregistrement de la commande est quant à lui effectué dans les 2 heures à compter de la validation définitive de la commande du Client, dans les conditions prévues aux présentes CGV à l'adresse indiquée par le Client lors de sa commande sur le site www.natification.fr .  </p>
          <p>Le Prestataire s'engage à faire ses meilleurs efforts pour fournir les Services commandés par le Client, dans le cadre d'une obligation de moyen et dans les délais ci-dessus précisés. Toutefois, ces délais sont communiqués à titre indicatif.</p>
          <p>Si les Services commandés n'ont pas été fournis dans un délai de 5 jours après la date indicative de fourniture, pour toute autre cause que la force majeure ou le fait du Client, la vente des Services pourra être résolue à la demande écrite du Client dans les conditions prévues aux articles L 216-2, L 216-3 et L241-4 du Code de la consommation. Les sommes versées par le Client lui seront alors restituées au plus tard dans les quatorze jours qui suivent la date de dénonciation du contrat, à l'exclusion de toute indemnisation ou retenue.  </p>
          <p>En cas de demande particulière du Client concernant les conditions de fourniture des Services, dûment acceptées par écrit par le Prestataire, les coûts y étant liés feront l'objet d'une facturation spécifique complémentaire ultérieure.  </p>
          <p>A défaut de réserves ou réclamations expressément émises par le Client lors de la réception des Services, ceux-ci seront réputés conformes à la commande, en quantité et qualité.  </p>
          <p>Le Client disposera d'un délai de 1 mois à compter de la fourniture des Services pour émettre des réclamations par email (contact@natification.fr) , avec tous les justificatifs y afférents, auprès du Prestataire. </p>
          <p>Aucune réclamation ne pourra être valablement acceptée en cas de non respect de ces formalités et délais par le Client.  </p>
          <p>Le Prestataire remboursera ou rectifiera dans les plus brefs délais et à ses frais les Services dont le défaut de conformité aura été dûment prouvé par le Client.</p>

          <h2> ARTICLE 6 - DROIT DE RÉTRACTATION </h2>
          <p>Compte tenu de la nature des Services fournis, les commandes passées par le Client ne bénéficient pas du droit de rétractation. </p>


          <h2> ARTICLE 7 - RESPONSABILITÉ DU PRESTATAIRE - GARANTIES  </h2>
          <p>Le Prestataire garantit, conformément aux dispositions légales et sans paiement complémentaire, le Client, contre tout défaut de conformité ou vice caché, provenant d'un défaut de conception ou de réalisation des Services commandés dans les conditions et selon les modalités suivantes :  </p>
          <p><em>Dispositions relatives aux  garanties légales</em></p>
          <p>Article L217-4 du Code de la consommation </p>
          <p><em>« Le vendeur est tenu de livrer un bien conforme au contrat et répond des défauts de conformité existant lors de la délivrance. Il répond également des défauts de conformité résultant de l'emballage, des instructions de montage ou de l'installation lorsque celle-ci a été mise à sa charge par le contrat ou a été réalisée sous sa responsabilité. » </em></p>
          <p>Article L217-5 du Code de la consommation  </p>
          <p><em>« Le bien est conforme au contrat :
          <ol>
          <li> S'il est propre à l'usage habituellement attendu d'un bien semblable et, le cas échéant :
            <ul>
              <li>  s'il correspond à la description donnée par le vendeur et possède les qualités que celui-ci a présentées à l'acheteur sous forme d'échantillon ou de modèle ; </li>
              <li> s’il présente les qualités qu'un acheteur peut légitimement attendre eu égard aux déclarations publiques faites par le vendeur, par le producteur ou par son représentant, notamment dans la publicité ou l'étiquetage ; </li>
            </ul>
          </li>
          <li> Ou s'il présente les caractéristiques définies d'un commun accord par les parties ou est propre à tout usage spécial recherché par l'acheteur, porté à la connaissance du vendeur et que ce dernier a accepté. »</li>
          </ol>
          </em></p>
          <p>Article L217-12 du Code de la consommation </p>
          <p><em>« L'action résultant du défaut de conformité se prescrit par deux ans à compter de la délivrance du bien. »  </em></p>
          <p>Article L217-16 du Code de la consommation. </p>
          <p><em>« Lorsque l’acheteur demande au vendeur, pendant le cours de la garantie commerciale qui lui a été consentie lors de l'acquisition ou de la réparation d'un bien meuble, une remise en état couverte par la garantie, toute période d'immobilisation d'au moins sept jours vient s'ajouter à la durée de la garantie qui restait à courir. Cette période court à compter de la demande d'intervention de l'acheteur ou de la mise à disposition pour réparation du bien en cause, si cette mise à disposition est postérieure à la demande d'intervention. »</em></p>
          <p>Afin de faire valoir ses droits, le Client devra informer le Prestataire, par écrit (mail ou courrier), de l'existence des vices ou défauts de conformité. </p>
          <p>Le Prestataire remboursera ou rectifiera ou fera rectifier (dans la mesure du possible) les services jugés défectueux dans les meilleurs délais et au plus tard dans les 30 jours suivant la constatation par le Prestataire du défaut ou du vice. Ce remboursement pourra être fait par virement ou chèque bancaire. </p>
          <p>La garantie du Prestataire est limitée au remboursement des Services effectivement payés par le Client.  </p>
          <p>Le Prestataire ne pourra être considéré comme responsable ni défaillant pour tout retard ou inexécution consécutif à la survenance d'un cas de force majeure habituellement reconnu par la jurisprudence française.  </p>
          <p>Les Services fournis par l'intermédiaire du site   du Prestataire sont conformes à la réglementation en vigueur en France. La responsabilité du Prestataire ne saurait être engagée en cas de non respect de la législation du pays dans lequel les Services sont fournis, qu'il appartient au Client, qui est seul responsable du choix des Services demandés, de vérifier. </p>

          <h2> ARTICLE 8 - DONNÉES PERSONNELLES </h2>
          <p> Le Client est informé que la collecte de ses données à caractère personnel est nécessaire à la vente des Services et leur réalisation et leur délivrance, ainsi qu'à leur transmission à des tiers intervenant dans la réalisation des Services. Ces données à caractère personnel sont récoltées uniquement pour l'exécution du contrat de prestations de services. </p>

          <h4> 8.1 Collecte des données à caractère personnel </h4>
          <p> Les données à caractère personnel qui sont collectées sur le site www.natification.fr sont les suivantes : </p>
          <ul>
            <li> <em> Commande de Prestations </em>: nom, prénom, date de naissance, numéro REZE, adresse e-mail, numéro de téléphone</li>
            <li> <em> Paiement </em> : Dans le cadre du paiement des Prestations proposés sur le site www.natification.fr , le prestataire enregistre des données financières relatives au compte bancaire ou à la carte de crédit du Client / utilisateur. </li>
          </ul>

          <h4>8.2 Destinataires des données à caractère personnel </h4>
          <p>Les données à caractère personnel sont réservées à l'usage unique du Prestataire et de ses salariés. Le responsable de traitement des données est le Prestataire, au sens de la loi Informatique et libertés et à compter du 25 mai 2018 du Règlement 2016/679 sur la protection des données à caractère personnel. </p>

          <h4>8.3 Limitation du traitement </h4>
          <p> Sauf si le Client exprime son accord exprès, ses données à caractère personnelles ne sont pas utilisées à des fins publicitaires ou marketing. </p>

          <h4>8.4 Durée de conservation des données  </h4>
          <p>Le Prestataire conservera les données ainsi recueillies pendant un délai de 5 ans, couvrant le temps de la prescription de la responsabilité civile contractuelle applicable.  </p>

          <h4>8.5 Sécurité et confidentialité </h4>
          <p> Le Prestataire met en œuvre des mesures organisationnelles, techniques, logicielles et physiques en matière de sécurité du numérique pour protéger les données personnelles contre les altérations, destructions et accès non autorisés. Toutefois il est à signaler qu'Internet n'est pas un environnement complètement sécurisé et le Prestataire ne peut garantir la sécurité de la transmission ou du stockage des informations sur Internet.</p>

          <h4>8.6 Mise en œuvre des droits des Clients et utilisateurs </h4>
          <p>En application de la règlementation applicable aux données à caractère personnel, les Clients et utilisateurs du site www.natification.fr disposent des droits suivants : </p>
          <ul>
            <li> Ils peuvent mettre à jour ou supprimer les données qui les concernent de la manière suivante : En envoyant un e-mail à contact@natification.fr . </li>
            <li> Ils peuvent supprimer leur compte en écrivant à l'adresse électronique indiqué à l'article 9.3 « Responsable de traitement » </li>
            <li> Ils peuvent exercer leur droit d'accès pour connaître les données personnelles les concernant en écrivant à l'adresse indiqué à l'article 9.3 « Responsable de traitement » </li>
            <li> Si les données à caractère personnel détenues par le Prestataire sont inexactes, ils peuvent demander la mise à jour des informations des informations en écrivant à l'adresse indiqué à l'article 9.3 « Responsable de traitement » </li>
            <li> Ils peuvent demander la suppression de leurs données à caractère personnel, conformément aux lois applicables en matière de protection des données en écrivant à l'adresse indiqué à l'article 9.3 « Responsable de traitement » </li>
            <li> Ils peuvent également solliciter la portabilité des données détenues par le Prestataire vers un autre prestataire </li>
            <li> Enfin, ils peuvent s'opposer au traitement de leurs données par le Prestataire </li>
          </ul>
          <p>Ces droits, dès lors qu'ils ne s'opposent pas à la finalité du traitement, peuvent être exercé en adressant une demande par courrier ou par E-mail au Responsable de traitement dont les coordonnées sont indiquées ci-dessus. Le responsable de traitement doit apporter une réponse dans un délai maximum d'un mois. En cas de refus de faire droit à la demande du Client, celui-ci doit être motivé. </p>
          <p>Le Client est informé qu'en cas de refus, il peut introduire une réclamation auprès de la CNIL (3 place de Fontenoy, 75007 PARIS) ou saisir une autorité judiciaire.  </p>
          <p>Le Client peut être invité à cocher une case au titre de laquelle il accepte de recevoir des mails à caractère informatifs et publicitaires de la part du Prestataire. Il aura toujours la possibilité de retirer son accord à tout moment en contactant le Prestataire (coordonnées ci-dessus) ou en suivant le lien de désabonnement.  </p>

          <h2> ARTICLE 9 - PROPRIÉTÉ INTELLECTUELLE </h2>
          <p>Le contenu du site www.natification.fr est la propriété du Vendeur et de ses partenaires et est protégé par les lois françaises et internationales relatives à la propriété intellectuelle.  </p>
          <p>Toute reproduction totale ou partielle de ce contenu est strictement interdite et est susceptible de constituer un délit de contrefaçon.  </p>

          <h2> ARTICLE 10 - DROIT APPLICABLE - LANGUE </h2>
          <p>Les présentes CGV et les opérations qui en découlent sont régies et soumises au droit français.  </p>
          <p>Les présentes CGV sont rédigées en langue française. Dans le cas où elles seraient traduites en une ou plusieurs langues étrangères, seul le texte français ferait foi en cas de litige. </p>

          <h2> ARTICLE 11 - LITIGES </h2>
          <p> Pour toute réclamation merci de contacter le service clientèle à l'adresse postale ou mail du Prestataire indiquée à l'ARTICLE 1 des présentes CGV. </p>
          <p> Le Client est informé qu'il peut en tout état de cause recourir à une médiation conventionnelle, auprès des instances de médiation sectorielles existantes ou à tout mode alternatif de règlement des différends (conciliation, par exemple) en cas de contestation.  </p>
          <p> En l'espèce, le médiateur désigné est FEVAD 60 rue la Boétie, 75008 Paris (https://www.mediateurfevad.fr/) <br/> E-mail : mediateurduecommerce@fevad.com .</p>
          <p> Le Client est également informé qu'il peut, également recourir à la plateforme de Règlement en Ligne des Litige  (RLL) : https://webgate.ec.europa.eu/odr/main/index.cfm?event=main.home.show </p>
          <p> Tous les litiges auxquels les opérations d'achat et de vente conclues en application des présentes CGV et qui n'auraient pas fait l'objet d'un règlement amiable entre le vendeur ou par médiation, seront soumis aux tribunaux compétents dans les conditions de droit commun</p>

        </div>

      </div>
    )}
}
