import React, {Component} from 'react';
import { Button } from 'reactstrap';
import ReactGA from 'react-ga';

export default class ErrorForm extends Component{
  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/notification/subscribe/error');
  }

  render(){
    return(
      <div className="hp-form">
              <div className="content-center">
                <img alt="check" src={require("./img/x.svg")} />

                <hr/>
                <p>Votre demande n'a pas abouti</p><br/>
                <p> {this.props.message} </p>
                <div className="btn back">
                  <Button className="back" href="/">Retour</Button>
                </div>
              </div>
      </div>
  )}
}
