import React,{Component} from 'react';
import './infographie.scss';

export default class InfographieFrance extends Component{

  render(){
    return(
      <div className="statistics">
        <div class="swiper-container">

            <div class="swiper-wrapper">


              <h4> Le Territoire Français </h4>
              <div class="swiper-slide">
                <div class="container-general">
                  <div class="gallery-wrap wrap-effect-1">

                    <div class="item">
                      <div className="item-title">
                        <h3 className="item-text"><span>67</span><br/>millions d'habitants</h3>
                      </div>
                    </div>

                    <div class="item">
                      <div className="item-title">
                        <h3 className="item-text"><span>675</span><br/> milles km2 <br/>de territoires</h3>
                      </div>
                    </div>

                    <div class="item">
                      <div className="item-title">
                        <h3 className="item-text"><span>6</span><br/>fleuves principaux</h3>
                        <p>La Loire, la Seine, la Garonne, le Rhône, le Rhin </p>
                      </div>
                    </div>

                    <div class="item">
                      <div className="item-title">
                        <h3 className="item-text"><span>17</span><br/>agglomérations</h3>
                        <p>de plus de 500 000 habitants</p>
                      </div>
                    </div>

                    <div class="item">
                      <div className="item-title">
                        <h3 className="item-text"><span>10</span><br/>métropoles</h3>
                        <p>Paris, Lyon, Marseille, Toulouse, Lille, Bordeaux, Nice, Nantes, Strasbourg et Rennes</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <h4> La Langue Française </h4>
              <div class="swiper-slide">
                <div class="container-general">
                  <div class="gallery-wrap wrap-effect-2">

                    <div class="item">
                      <div className="item-title">
                        <h3 className="item-text"><span>220</span><br/>millions de personnes</h3>
                        <p> Parlent le Français </p>
                      </div>
                    </div>

                    <div class="item">
                      <div className="item-title">
                        <h3 className="item-text"><span>1%</span><br/>de la population mondiale</h3>
                        <p> est francophone </p>
                      </div>
                    </div>

                    <div class="item">
                      <div className="item-title">
                        <h3 className="item-text"><span>70</span><br/>pays </h3>
                        <p> où le Français est une des langues officielles </p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>


              <h4> La France et l'Europe </h4>
              <div class="swiper-slide">
                <div class="container-general">
                  <div class="gallery-wrap wrap-effect-3">

                    <div class="item">
                      <div className="item-title">
                        <h3 className="item-text"><span>1957</span><br/>Création de l'Union Européenne</h3>
                        <p>La France est l'un des 6 pays fondateurs </p>
                      </div>
                    </div>

                    <div class="item">
                      <div className="item-title">
                        <h3 className="item-text"><span>28</span><br/>États membres</h3>
                        <p>Et respectant les principes démocratiques ainsi que de la libre circulation, de travailler, d’étudier, de s’installer dans les autres pays de l’UE.</p>
                      </div>
                    </div>

                    <div class="item">
                      <div className="item-title">
                        <h3 className="item-text"><span>1992</span><br/></h3>
                        <p> Les français sont devenus des citoyens européens avec le droit de vote aux élections du Parlement européen</p>
                      </div>
                    </div>

                    <div class="item">
                      <div className="item-title">
                        <h3 className="item-text"><span>19</span><br/></h3>
                        <p>Nombre de pays de l’UE (dont la France) à avoir comme monnaie l’Euro</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <h4> La France dans le Monde </h4>
              <div class="swiper-slide">
                <div class="container-general wrap-effect-4">
                  <div class="gallery-wrap">

                  <div class="item">
                    <div className="item-title">
                      <h3 className="item-text"><span>#7</span><br/>Rang de la France</h3>
                      <p>en tant que puissance mondiale.</p>
                      <p>Elle est également membre permanent au Conseil de sécurité de l'ONU</p>
                    </div>
                  </div>

                  <div class="item">
                    <div className="item-title">
                      <h3 className="item-text"><span>2925</span><br/>milliards d'euros</h3>
                      <p>PIB de la France</p>
                    </div>
                  </div>

                  <div class="item">
                    <div className="item-title">
                      <h3 className="item-text"><span>+100</span><br/>grandes entreprises</h3>
                      <p>De renommée internationnale</p>
                      <p>Dont	Total, Air France-KLM, LVMH ou encore JCDecaux </p>
                    </div>
                  </div>

                  </div>
                </div>
              </div>

            </div>

        </div>
      </div>

    )}
}
