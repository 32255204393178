import React, {Component} from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import './livret.css';
import {QCMReminder} from '../QCMReminder/qcm-reminder';
import LivretChapitresMenu from './MenuChapitres/Chapitres';
import {Helmet} from "react-helmet";

export default class LivretDuCitoyenDevise extends Component{
  constructor(props){
    super(props);

    this.state= {
      session : this.props.session
    }
  }
  render(){
    return(
      <div className="livret-main-container">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <link rel="canonical" href={this.props.pageCanonicalLink} />
          <meta name="description" content={this.props.pageDescription} />
      </Helmet>
        <Breadcrumb>
          <BreadcrumbItem><a href="/preparer-entretien">Préparer mon entretien</a></BreadcrumbItem>
          <BreadcrumbItem><a href="/preparer-entretien/culture-generale">Culture générale</a></BreadcrumbItem>
          <BreadcrumbItem active>Organisation du territoire</BreadcrumbItem>
        </Breadcrumb>
        <div className="top-image livret">
          <div className="top-image-title">
            <h2> Préparer mon entretien : <span>Organisation du territoire</span></h2>
          </div>
        </div>

        {
          this.props.session?
        <div className="livret-content">
          <LivretChapitresMenu/>


          <div className="livret-content-header">
            <p>La France est divisée en trois collectivités</p>
            <p> Dans les départements et les régions de métropole et d’outre-mer, l’état est représenté par les préfets.</p>

          </div>

          <div className="livret-section section-1">
            <h3> Le découpage du territoire </h3>
            <br/>

            <div className="livret-section-maps">

              <div className="livret-section-map">
                  <h4> 13 RÉGIONS </h4>
                  <p> Elles sont gérées par les conseils régionaux</p><br/>
                  <p> <em>Responsabilités principales </em>: les transports publics, la formation professionnelle, la construction et l'entretien des lycées</p>
              </div>

              <div className="livret-section-map">
                  <h4>101 DÉPARTEMENTS</h4>
                  <p>Gérés par les conseils départementaux</p><br/>
                  <p> <em>Responsabilités principales</em> : les collèges, la protection de l'enfance et l'aide aux personnes âgées</p>
              </div>

              <div className="livret-section-map">
                  <h4> 36 000 COMMUNES </h4>
                  <p> Elles sont gérés par les maires et les conseils municipaux.</p><br/>
                  <p> <em>Responsabilités principales </em>: les maternelles, les écoles, les primaires, et l'état civil (enregistrement des naissance, décès et des mariages) </p>
              </div>

            </div>

          </div>

          <div className="livret-section section-2">
            <h2> L'Outre-Mer</h2>
            <p>Les collectivités d’outre-mer ont un statut qui tient compte de leurs intérêts propres au sein de la République.</p>

              <div className="livret-section-maps outremer">
                <div className="livret-section-map">
                    <h4> 5 DÉPARTEMENTS ET REGIONS </h4>
                    <p> Il existe ainsi cinq départements et régions d'outre-mer : </p>
                    <p> La Guadeloupe, La Guyane, La Martinique, Mayotte, La Réunion </p>
                </div>

                <div className="livret-section-map">
                    <h4> 5 COLLECTIVITÉS </h4>
                    <p> La Polynésie française, Saint-Barthélemy, Saint-Martin, Saint-Pierre-et-Miquelon, Wallis-et-Futuna </p>
                </div>
              </div>


          </div>

          <div className="qcm-reminder">
            <QCMReminder/>
          </div>

          <div className="livret-section section-end">
              <span> Chapitre précédent :</span>
              <span> </span>
              <span> Chapitre suivant : </span>
             <div className="chap-prev"> <a href="/preparer-entretien/culture-generale-part3"><i className="fas fa-arrow-left"></i> Le système démocratique </a></div>
             <br/>
             <div className="chap-next">  <a href="/preparer-entretien/culture-generale-part5"> Événements et histoire de France <i className="fas fa-arrow-right"></i> </a></div>
          </div>

        </div>
        :
        <div className="offline-content livret">
          <h2> Veuillez vous connecter </h2>
          <p> Cette section n'est disponible que pour les membres du site </p>
          <a href="/login"> Me connecter </a> ou <a href="/register"> Créer un compte </a>
        </div>
        }
      </div>


  )}
}
