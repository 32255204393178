import React from 'react';
import {Collapse,Navbar,NavbarToggler,NavbarBrand,Nav,NavItem,NavLink,DropdownToggle,DropdownMenu,DropdownItem,UncontrolledDropdown } from 'reactstrap';
import './navbar.css';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export default class NavBar extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      session: true,
    };
  }

  disconnetMe(){
    cookies.remove("jwt");
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }
  render() {
    return (
      <div className="nav-bar">
        <div className="experts-link">
          Nos experts vous répondent <span><a href="https://www.facebook.com/groups/Natification/" rel="noopener noreferrer" target="_blank"><i className="fab fa-facebook-square"></i></a> </span>
        </div>
        <Navbar color="light" light expand="md">
          <NavbarBrand href="/"><img src={require('./favicon.png')} alt="w-logo"/>NATIFICATION</NavbarBrand>
          <NavbarToggler onClick={this.toggle} /><br/>
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>


            { this.state.session?
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Constitution du dossier
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <NavLink href="/constituer-dossier">Toutes les étapes</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="/constituer-dossier/procedure-de-naturalisation">Quelle procédure ?</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="/constituer-dossier/conditions-naturalisation">Les conditions</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="/constituer-dossier/preparer-dossier-naturalisation">Préparer mon dossier</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="/constituer-dossier/deposer-dossier-naturalisation">Où déposer mon dossier ?</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="/constituer-dossier/instruction-dossier-naturalisation">Instruction du dossier</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="/constituer-dossier/decision-naturalisation">Décision ministerielle</NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              :
              ""
            }
            { this.state.session?
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  Préparer mon entretien
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <NavLink href="/preparer-entretien/deroulement">Déroulement</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="/preparer-entretien/culture-generale">Réviser ma Culture Générale</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="/preparer-entretien/charte-des-droits-et-devoirs">Droits et Devoirs du Citoyen</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="/preparer-entretien/questions-personnelles">Questions Personelles</NavLink>
                  </DropdownItem>
                  {/*}<DropdownItem>
                    <NavLink href="/preparer-entretien/fiches-synthese">Fiches synthèse</NavLink>
                  </DropdownItem>*/}
                  <DropdownItem>
                    <NavLink href="/quizz">Quizz Culture Générale</NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              :
              ""
            }

              <NavItem>
                <NavLink href="/notification"><span> <i className="fas fa-bell"></i> Recevoir ma notification </span></NavLink>
              </NavItem>

              <NavItem>
                <NavLink href="http://blog.natification.fr"> Blog </NavLink>
              </NavItem>



              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret className="mon-compte-header">
                  Mon compte
                </DropdownToggle>

                { this.props.session?
                  <DropdownMenu right>
                    <DropdownItem>
                      <NavLink href="/mon-compte">Mon profil</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink href="/" onClick={this.disconnetMe}>Déconnexion</NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                  :
                  <DropdownMenu right>
                    <DropdownItem>
                      <NavLink href="/login">Se connecter</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink href="/register">Créer un compte</NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                }

              </UncontrolledDropdown>

            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}

/*

<UncontrolledDropdown nav inNavbar>
  <DropdownToggle nav caret>
    Constitution du dossier
  </DropdownToggle>
  <DropdownMenu right>
    <DropdownItem>
      <NavLink href="/constituer-dossier">Définir ma liste de documents</NavLink>
    </DropdownItem>
    <DropdownItem>
      <NavLink href="/constituer-dossier">Déposer des documents en ligne</NavLink>
    </DropdownItem>
    <DropdownItem>
      <NavLink href="/constituer-dossier">Synthèse de mon dossier</NavLink>
    </DropdownItem>
  </DropdownMenu>
</UncontrolledDropdown>


{this.state.session?
  ""
  :
  <UncontrolledDropdown nav inNavbar>
    <DropdownToggle nav caret>
      Nos Services
    </DropdownToggle>
    <DropdownMenu right>
      <DropdownItem>
        <NavLink href="/constituer-dossier">Constitution</NavLink>
      </DropdownItem>
      <DropdownItem>
        <NavLink href="/preparer-entretien">Préparation</NavLink>
      </DropdownItem>
      <DropdownItem>
        <NavLink href="/notification">Notification</NavLink>
      </DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
  }

*/
