import React, {Component} from 'react';
import './constituerDossier.css';
import {QCMReminder} from '../PreparerEntretien/Revision/QCMReminder/qcm-reminder';
import ReactGA from 'react-ga';
import {Helmet} from "react-helmet";

export default class InstructionDossierNaturalisation extends Component{
  constructor(props){
    super(props);
    this.state= {
      session : this.props.session,
    }
  }

  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/constituer-dossier/instruction-dossier-naturalisation');
  }


  render(){
    return(
      <div className="constituer-main-container instruction">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <link rel="canonical" href={this.props.pageCanonicalLink} />
          <meta name="description" content={this.props.pageDescription} />
      </Helmet>
        <div className="top-image instruction">
            <div className="top-image-title">
              <h1> L'instruction du dossier de naturalisation </h1>
            </div>
        </div>

      <div className="constituer-content instruction">

        <div className="constituer-content-header instruction">

        </div>


        <div className="constituer-section instruction section-1">
          <div className="constituer-section instruction section-1-container">
            <p> Vous avez déposé ou envoyé votre dossier auprès de votre préfecture de police ?
            Votre dossier est de ce fait étudié par le préfet et une enquête est effectuée.
            Lorsque votre dossier est en cours d’instruction,  vous êtes convoqué(e) pour un entretien. En fonction de la procédure mise en place par la préfecture de police, l’entretien peut avoir lieu lors du dépôt du dossier ou ultérieurement.
            </p>

            <h3>Un entretien de naturalisation se prépare ! </h3>
            <p> L’objectif de cet entretien est de tester votre culture générale, votre intégration et votre niveau de français. A la suite de cet entretien et de l’enquête, la préfecture de police peut s’opposer à l’acquisition de la nationalité française.
            <br/>Mettez toutes les chances de votre côté en vous entraînant pour votre entretien ici. </p>
            <div className="qcm-reminder">
              <QCMReminder/>
            </div>
            <br/>
            <p> Si votre dossier est validé par la préfecture de police, ce dernier est envoyé au ministère de l’intérieur. Dans le cas où la préfecture de police ne valide pas votre dossier, vous êtes automatiquement informé(e).</p>
          <h3> Que faire en cas de changement de situation ?</h3>
          <p> Si vous avez le moindre changement de votre situation personnelle (ex : changement d’adresse), professionnelle (ex : perte ou changement d’emploi) ou familiale (ex : mariage, naissance, décès) après le dépôt de votre dossier, <span>vous êtes dans l’obligation de le signaler à votre préfecture.</span></p>
        </div>
        </div>

      </div>
    </div>

    )}
}
