import React, {Component} from 'react';
import './404.css';
import ReactGA from 'react-ga';
import {Helmet} from 'react-helmet';


export default class NoMatch extends Component{

  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/secret-page');
  }

  render(){
    return(
      <div className="main-404-container">
      <Helmet>
          <meta charSet="utf-8" />
          <title>{this.props.pageTitle}</title>
          <link rel="canonical" href={this.props.pageCanonicalLink} />
          <meta name="description" content={this.props.pageDescription} />
      </Helmet>
        <h1> Mauvaise nouvelle ! </h1>
        <p> La page recherchée n'existe pas </p>
        <img src={require("./sad.svg")} alt="404"/>
        <br/>
        <h2> Bonne nouvelle !</h2>
        <p> Retrouvez tout le contenu qu'il faut pour votre naturalisation en revenant à la page d'accueil
        <a href="/"> et c'est par ici </a></p>
      </div>
    )}
}
