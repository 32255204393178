import React, {Component} from 'react';
import './Account/myAccount.css';
import { Button, Label } from 'reactstrap';
import ReactGA from 'react-ga';


export default class SecretPage extends Component{

  componentDidMount(){
    ReactGA.initialize('UA-140427287-1');
    ReactGA.pageview('/secret-page');
  }

  render(){
    return(
      <div className="my-account-main-container">
      {
        this.props.session?
        <div className="my-account-content">
          <h2> Mon compte </h2>

          <div className="my-infos">
          <h4> Informations secretes </h4>
          <hr/>
            <div>
              <div className="my-info-values">
                <Label>Nom</Label>
                <p> Bond </p><br/>
                <Label>Prénom</Label>
                <p> James </p><br/>
                <Label>email</Label>
                <p> 007@mail.com </p>
              </div>
              <Button color="primary"> OK </Button>
            </div>

          </div>
        </div>
      :
      <div className="offline-content preparer">
        <h2> Veuillez vous connecter </h2>
        <p> Cette section n'est disponible que pour les membres du site </p>
        <a href="/login"> Me connecter </a> ou <a href="/register"> Créer un compte </a>
      </div>
      }
      </div>
    )}
}
