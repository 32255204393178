import React, {Component} from 'react';
import './checkListConditions.scss';

export default class CheckListConditions extends Component{
  constructor(props){
    super(props);
    this.state = {
      condition : this.props.conditions,
      nb : this.props.nb,
      listtype : this.props.listtype,
    }

  }

  render(){
    return(
      <div className="checklist-conditions">
        <div className="subtitle">
          <h4> {this.props.subtitle}  <i className="fas fa-print" onClick={this.print} ></i> </h4>
        </div>
        <div className="content-list">
          {this.props.conditions.map((condition) =>
            <div className={this.props.listtype}>
              {this.props.listtype==="cond-list"? <input type="checkbox"/> : ""}
              <span className="checkmark"></span>
              <p key={condition}> {condition} </p>
            </div>
            )}
        </div>

        {this.props.nb.length > 0?

          <div className="notabene">
            <div className="subtitle">
              <h4>{this.props.subtitle2} </h4>
            </div>

            <div className="content-list">
              {this.props.nb.map((notabene) =>
                <p>  {notabene}  </p>
              )}
            </div>
          </div>
        :
        ""
        }
      </div>
    )}
}
